// Helpers
import {requestCreator} from '@helpers/requsetCreator';
// Types
import {TabModel, TabCategoryModel} from './models/tabs';
import { CategoryModel } from './models/categoryModels';

export const getTabs = async (): Promise<TabModel[]> =>
	await requestCreator<undefined, TabModel[]>('GET', `/tab/tabs`);

export const addTabRequest = async (
	name: string,
	bodies: number[]
): Promise<TabModel> =>
	await requestCreator<number[], TabModel>(
		'PUT',
		`/tab?name=${name}`,
		bodies,
		undefined,
		'Zakładka została dodana!'
	);

export const editTabRequest = async (
	id: number,
	name: string,
	bodies: number[]
): Promise<TabModel> =>
	await requestCreator<number[], TabModel>(
		'POST',
		`/tab?id=${id}&name=${name}`,
		bodies,
		undefined,
		'Zakładka została dodana!'
	);

export const deleteTabRequest = async (id: number): Promise<string> =>
	await requestCreator<undefined, string>(
		'DELETE',
		`/tab?id=${id}`,
		undefined,
		undefined,
		'Zakładka została usunięta!'
	);

// Tab categories

export const getTabCategoriesRequest = async (tabId: number): Promise<TabCategoryModel[]> =>
	await requestCreator<undefined, TabCategoryModel[]>('GET', `/tab/category?tabId=${tabId}`);

export const addTabCategoriesRequest = async (tabId: number, categories: number[]): Promise<TabCategoryModel[]> =>
	await requestCreator<number[], TabCategoryModel[]>('PUT', `/tab/categories?tabId=${tabId}`, categories, undefined, 'Dodano cechy do zakłądki.');

export const deleteTabCategoryRequest = async (id: number): Promise<string> =>
	await requestCreator<undefined, string>('DELETE', `/tab/category?id=${id}`);

export const getTabAvailableCategoriesRequest = async (tabId: number): Promise<CategoryModel[]> =>
	await requestCreator<undefined, CategoryModel[]>(
		'GET',
		`/tab/categories-available?tabId=${tabId}`
	);
