import {useMemo} from 'react';
import {useSelector} from 'react-redux';
// Components
import Table from '@components/Table/Table';
import Container from '@components/Container/Container';
import {ReactComponent as Trash} from '@assets/icons/trash.svg';
// Api
import {getModelsRequest} from '@api/models';
// Redux
import {
	getModelsPageMaxPage,
	getModelsPageModels,
} from '@selectors/modelsPageSelectors';
// Types
import {Tag} from '@commonTypes/tags';
import Button, {ButtonTag, ButtonVariant} from '@components/Button/Button';
import {ModalType} from '@commonTypes/main';
import Pagination from '@components/Pagination/Pagination';

interface ModelsListProps {
	toggleModal: (isOpened: boolean, modalType: ModalType, id?: number) => void;
}

const ModelsList = (props: ModelsListProps) => {
	const {toggleModal} = props;

	const models = useSelector(getModelsPageModels);

	const tableElements = useMemo(
		() =>
			models?.map((item) => {
				return {
					id: item.id,
					brand: item.brandName,
					model: item.name,
					configure: (
						<Button
							type='button'
							as={ButtonTag.InternalLink}
							href={`/vehicles/add?brand=${item.brandId}&model=${item.id}`}
							variant={ButtonVariant.Outline}>
							Konfiguruj
						</Button>
					),
					edit: (
						<Button
							type='button'
							onClick={() => {
								toggleModal(true, ModalType.EDIT, item.id);
							}}
							variant={ButtonVariant.Outline}>
							Edytuj
						</Button>
					),
					delete: (
						<button
							type='button'
							className='button-delete'
							onClick={() => {
								toggleModal(true, ModalType.DELETE, item.id);
							}}>
							<Trash />
						</button>
					),
				};
			}),
		[models, toggleModal]
	);

	return (
		<Container as={Tag.Section}>
			<Table
				headers={[
					{text: 'ID', sortName: 'id'},
					{text: 'Marka', sortName: 'brand'},
					{text: 'Model', sortName: 'model'},
					{text: 'Skonfiguruj pojazd'},
					{text: 'Edytuj'},
					{text: 'Usuń'},
				]}
				elements={tableElements ?? []}
				groups={[
					{width: '10%'},
					{width: '20%'},
					{width: '45%', minWidth: 170},
					{width: '15%', minWidth: 162},
					{width: '15%'},
					{width: '5%'},
				]}
			/>

			<Pagination getMaxPages={getModelsPageMaxPage} request={getModelsRequest} />
		</Container>
	);
};

export default ModelsList;
