// Actions
import { AppState, AppStateActions } from '@actions/appActions';

const initialState: AppState = {
  loading: false,
}

export const appReducer = (
	state = initialState,
	action: {type: AppStateActions; payload?: boolean}
): AppState | undefined => {
	switch (action.type) {
		case AppStateActions.SET_LOADING:
			return {...state, loading: action?.payload as boolean};
		default:
			return state;
	}
};

