// Libraries
import {FormEvent, useEffect, useState} from 'react';
import {toast} from 'react-toastify';
// Componetns
import Input, {defaultInput} from '@components/_FormElements/Input/Input';
import FileInput from '@components/_FormElements/FileInput/FileInput';
import Button, {ButtonVariant} from '@components/Button/Button';
// Helpers
import {validateForm} from '@helpers/validateForm';
import {getFileFromUrl} from '@helpers/getFileFromUrl';
// Api
import {addCabinRequest, editCabinRequest} from '@api/cabins';
// Types
import {FormField, FormKeys} from '@commonTypes/main';
import {CabinModel} from '@api/models/cabinsModels';
// Styles
import styles from './AddForm.module.scss';

interface AddFormProps {
	cabinId?: number;
	cabins?: CabinModel[];
	bodyId?: number;
	modelId?: number;
	closeHandler: () => void;
	setCabins: React.Dispatch<React.SetStateAction<CabinModel[]>>;
}

const initialState = {
	name: {...defaultInput, required: true},
	file: {...defaultInput, value: undefined as undefined | File, required: true},
};

const AddForm = (props: AddFormProps) => {
	const {bodyId, modelId, cabinId, cabins, closeHandler, setCabins} = props;

	const [loading, setLoading] = useState<boolean>(false);
	const [form, setForm] = useState<FormKeys<typeof initialState>>(initialState);

	const updateFormHandler = (name: string, value: FormField) => {
		setForm({...form, [name]: value});
	};

	if (!!!cabinId && (!!!modelId || !!!bodyId))
		throw new Error('ModelId and BodyId are required!');

	const submitHandler = async (e: FormEvent) => {
		e.preventDefault();
		setLoading(true);

		const isError = validateForm(form, setForm);

		if (isError) return setLoading(false);

		if (bodyId !== undefined && modelId !== undefined) {
			await addCabinRequest({
				name: form.name.value,
				bodyTypeId: bodyId,
				modelId,
				image: form.file.value!,
			})
				.then((res) => {
					setCabins([...(cabins ?? []), res]);
					closeHandler();
				})
				.finally(() => {
					setLoading(false);
				});
		} else {
			if (form.file.value === undefined) return toast.warning('Image not found.');
			if (cabinId === undefined) return toast.warning('Cabin not found.');
			await editCabinRequest({
				id: cabinId,
				name: form.name.value,
				image: form.file.value,
			})
				.then((res) => {
					setCabins(
						cabins ? cabins?.map((item) => (item.id === res.id ? res : item)) : []
					);
					closeHandler();
				})
				.finally(() => {
					setLoading(false);
				});
		}
	};

	useEffect(() => {
		if (cabinId === undefined || cabins === undefined) return;

		const currentCabin = cabins?.find(({id}) => id === cabinId);

		if (!!currentCabin) {
			(async () => {
				const res = await getFileFromUrl(currentCabin.image, currentCabin.name);

				setForm({
					...form,
					name: {...form.name, value: currentCabin.name},
					file: {
						...form.file,
						value: res,
					},
				});
			})();
		} else {
			toast.warning('Cabin not found.');
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [cabinId, cabins]);

	return (
		<>
			<Input
				id='name'
				name='name'
				type='text'
				placeholder={'Wpisz nazwę'}
				label={'Nazwa kabiny'}
				required={form.name.required}
				value={form.name.value}
				errorMessage={form.name.errorMessage}
				valueChangeHandler={updateFormHandler}
			/>
			<FileInput
				id='file'
				name='file'
				placeholder={'+ Dodaj plik'}
				label={'Dodaj zdjęcie kabiny'}
				accept='image/*'
				required={form.file.required}
				value={form.file.value}
				errorMessage={form.file.errorMessage}
				valueChangeHandler={updateFormHandler}
			/>
			<div className={styles.buttons}>
				<Button
					type='button'
					variant={ButtonVariant.Outline}
					onClick={closeHandler}>
					Anuluj
				</Button>
				<Button
					type='button'
					onClick={submitHandler}
					variant={ButtonVariant.Primary}
					disabled={loading}>
					Zapisz
				</Button>
			</div>
		</>
	);
};

export default AddForm;
