// Libraries
import {useCallback} from 'react';
import {useDispatch} from 'react-redux';
// Componetns
import {Text} from '@components/_Typography';
import Button, {ButtonVariant} from '@components/Button/Button';
// Redux
import { deletePropertyVehicles } from '@actions/propertyVehiclesPageActions';
// Api
import {deletePropertyVehiclesRequest} from '@api/properties';
// Types
import {PropertyVehicleModel} from '@api/models/propertiesModels';
// Styles
import styles from './DeleteForm.module.scss';

interface DeleteFormProps {
	vehicle: PropertyVehicleModel;
	closeHandler: () => void;
}

const DeleteForm = (props: DeleteFormProps) => {
	const {vehicle, closeHandler} = props;

	const dispatch = useDispatch();

	const deleteHandler = useCallback(async () => {
		await deletePropertyVehiclesRequest(vehicle.id).then(() => {
			dispatch(deletePropertyVehicles(vehicle.id));
			closeHandler();
		});
	}, [closeHandler, dispatch, vehicle.id]);

	return (
		<div className={styles.wrapper}>
			<Text>
				Czy chcesz rozłączyć pojazd: <strong>#{vehicle?.id}</strong>?
			</Text>
			<div className={styles.buttons}>
				<Button
					type='button'
					variant={ButtonVariant.Outline}
					onClick={closeHandler}>
					Anuluj
				</Button>
				<Button
					type='button'
					variant={ButtonVariant.Danger}
					onClick={deleteHandler}>
					Rozłącz
				</Button>
			</div>
		</div>
	);
};

export default DeleteForm;
