import React, {useMemo} from 'react';
import styles from './TableElement.module.scss';
import Checkbox, {
	CheckboxProps,
} from '@components/_FormElements/Checkbox/Checkbox';

interface TableElementProps {
	columns: any;
	isRadio?: boolean;
	checkbox?: CheckboxProps;
}

const TableElement = (props: TableElementProps) => {
	const {columns, isRadio, checkbox} = props;

	const cols = useMemo(() => Object.keys(columns), [columns]);

	if(isRadio && !!!checkbox) throw new Error('TableElement with checkbox require checkbox prop.')

	return (
		<tr className={styles.wrapper}>
			{!!isRadio && !!checkbox && (
				<td>
					<Checkbox {...checkbox} />
				</td>
			)}
			{cols.map((key) => (
				<td key={key}>{columns[key]}</td>
			))}
		</tr>
	);
};

export default TableElement;
