// Components
import AppHeader from '@components/AppHeader/AppHeader';
import OrderedConfigsList from './components/OrderedConfigsList/OrderedConfigsList'
// Styles
import styles from './OrderedConfigs.module.scss';

const OrderedConfigs = () => {

	return (
		<>
			<AppHeader
				title='Lista wycen'
				text='Możesz przeglądać wygenerowane wyceny na konfiguracje pojazdów.'
				classes={styles.header}></AppHeader>
			<OrderedConfigsList />
		</>
	);
};

export default OrderedConfigs;
