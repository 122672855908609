// Components
import Navbar from './components/Navbar/Navbar';
import Topbar from './components/Topbar/Topbar';
import Loader from '@components/Loader/Loader';
// Types
import {Common} from '@commonTypes/main';
// Styles
import styles from './AppLayout.module.scss';
import {useSelector} from 'react-redux';
import {getAppLoading} from '@selectors/appSelectors';

const AppLayout = (props: Common) => {
	const {children} = props;

	const loadingApp = useSelector(getAppLoading);

	return (
		<>
			<Navbar />
			{loadingApp && (
				<div className={styles.loader}>
					<Loader />
				</div>
			)}
			<div className={styles.content}>
				<Topbar />
				{children}
			</div>
			{process.env.REACT_APP_ENV !== 'production' && (
				<div className={styles.test}>Środowisko testowe</div>
			)}
		</>
	);
};

export default AppLayout;
