// Libraries
import {useCallback} from 'react';
import {useDispatch} from 'react-redux';
// Componetns
import {Text} from '@components/_Typography';
import Button, {ButtonVariant} from '@components/Button/Button';
// Redux
import {deleteTabCategory} from '@actions/tabsConfigurePageActions';
// Api
import {deleteTabCategoryRequest} from '@api/tabs';
// Types
import {TabCategoryModel} from '@api/models/tabs';
// Styles
import styles from './DeleteForm.module.scss';

interface DeleteFormProps {
	tabProperty: TabCategoryModel;
	closeHandler: () => void;
}

const DeleteForm = (props: DeleteFormProps) => {
	const {tabProperty, closeHandler} = props;

	const dispatch = useDispatch();

	const deleteHandler = useCallback(async () => {
		await deleteTabCategoryRequest(tabProperty.id).then(() => {
			dispatch(deleteTabCategory(tabProperty.id));
			closeHandler();
		});
	}, [closeHandler, dispatch, tabProperty.id]);

	return (
		<div className={styles.wrapper}>
			<Text>
				Czy chcesz rozłączyć kategorię cech:{' '}
				<strong>{tabProperty?.categoryName}</strong>?
			</Text>
			<div className={styles.buttons}>
				<Button
					type='button'
					variant={ButtonVariant.Outline}
					onClick={closeHandler}>
					Anuluj
				</Button>
				<Button
					type='button'
					variant={ButtonVariant.Danger}
					onClick={deleteHandler}>
					Rozłącz
				</Button>
			</div>
		</div>
	);
};

export default DeleteForm;
