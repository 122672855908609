import {requestCreator} from '@helpers/requsetCreator';
import {ChassisModel, ChassisRequsetData} from './models/chassis';

export const getChassisRequset = async (
	data: ChassisRequsetData
): Promise<ChassisModel[]> =>
	await requestCreator<undefined, ChassisModel[]>(
		'GET',
		`/vechicle/chassisies?modelId=${data.modelId}&bodyTypeId=${data.bodyTypeId}${
			data.cabinTypeId !== null ? `&cabinTypeId=${data.cabinTypeId}` : ''
		}`
	);
