import {TabModel} from '@api/models/tabs';

export interface TabsPage {
	tabs: TabModel[] | null;
	currentPage: number;
	maxPage: number;
}

export enum TabsPageActions {
	SET_TABS = 'SET_TABS',
	ADD_TAB = 'ADD_TAB',
	EDIT_TAB = 'EDIT_TAB',
	DELETE_TAB = 'DELETE_TAB',
	SET_TABS_PAGE_CURRENT_PAGE = 'SET_TABS_PAGE_CURRENT_PAGE',
	SET_TAB_MAX_PAGE = 'SET_TAB_MAX_PAGE',
}

const setTabs = (tabs: TabModel[]) => ({
	type: TabsPageActions.SET_TABS,
	payload: tabs,
});

const addTab = (tabs: TabModel) => ({
	type: TabsPageActions.ADD_TAB,
	payload: tabs,
});

const editTab = (tab: TabModel) => ({
	type: TabsPageActions.EDIT_TAB,
	payload: tab,
});

const deleteTab = (id: number) => ({
	type: TabsPageActions.DELETE_TAB,
	payload: id,
});

const setTabsCurrentPage = (currentPage: number) => ({
	type: TabsPageActions.SET_TABS_PAGE_CURRENT_PAGE,
	payload: currentPage,
});

const setTabsMaxPage = (maxPage: number) => ({
	type: TabsPageActions.SET_TAB_MAX_PAGE,
	payload: maxPage,
});

export {
	setTabs,
	addTab,
	editTab,
	deleteTab,
	setTabsCurrentPage,
	setTabsMaxPage,
};
