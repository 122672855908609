import React from 'react';
// Components
import Button, {ButtonVariant} from '@components/Button/Button';
// Styles
import styles from './ConfirmForm.module.scss';
import {UserModel} from '@api/models/authModels';
import {Text} from '@components/_Typography';

interface ConfirmFormProps {
	user: UserModel;
	closeHandler: () => void;
	submitHandler: () => void;
}

const ConfirmForm = (props: ConfirmFormProps) => {
	const {user, closeHandler, submitHandler} = props;

	return (
		<>
			<Text>
				Konto użytkownika{' '}
				<strong>
					{user.firstName} {user.lastName}
				</strong>{' '}
				zostanie aktywowane. Otrzyma on możliwość zalogowania się do panelu
				klienta.
			</Text>
			<div className={styles.buttons}>
				<Button
					type='button'
					variant={ButtonVariant.OutlineWithBorder}
					onClick={closeHandler}>
					Anuluj
				</Button>
				<Button type='button' onClick={submitHandler}>
					Aktywuj konto
				</Button>
			</div>
		</>
	);
};

export default ConfirmForm;
