import {GetPropertiesResponse, PropertyModel} from '@api/models/propertiesModels';

export interface ProperitesPage {
	properties: PropertyModel[] | null;
	currentPage: number;
	maxPage: number;
}

export enum PropertiesPageActions {
	ADD_PROPERTY = 'ADD_PROPERTY',
	SET_PROPERTIES = 'SET_PROPERTIES',
	EDIT_PROPERTY = 'EDIT_PROPERTY',
	DELETE_PROPERTY = 'DELETE_PROPERTY',
	SET_PROPERTIES_CURRENT_PAGE = 'SET_PROPERTIES_CURRENT_PAGE',
	SET_PROPERTIES_MAX_PAGE = 'SET_PROPERTIES_MAX_PAGE',
}

const setProperties = (properties: GetPropertiesResponse) => ({
	type: PropertiesPageActions.SET_PROPERTIES,
	payload: properties,
});

const addProperty = (property: PropertyModel) => ({
	type: PropertiesPageActions.ADD_PROPERTY,
	payload: property,
});

const editProperty = (property: PropertyModel) => ({
	type: PropertiesPageActions.EDIT_PROPERTY,
	payload: property,
});

const deleteProperty = (id: number) => ({
	type: PropertiesPageActions.DELETE_PROPERTY,
	payload: id,
});

const setPropertiesCurrentPage = (currentPage: number) => ({
	type: PropertiesPageActions.SET_PROPERTIES_CURRENT_PAGE,
	payload: currentPage,
});

const setPropertiesMaxPage = (maxPage: number) => ({
	type: PropertiesPageActions.SET_PROPERTIES_MAX_PAGE,
	payload: maxPage,
});

export {
	addProperty,
	setProperties,
	editProperty,
	deleteProperty,
	setPropertiesCurrentPage,
	setPropertiesMaxPage,
};
