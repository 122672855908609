// Helpers
import {requestCreator} from '@helpers/requsetCreator';
// Types
import {
	AddPropertyData,
	AddPropertyResponse,
	EditPropertyData,
	PropertyVehicleModel,
	PropertyModel,
	AddPropertyVehicleRequest,
	ExcludedPropertyModel,
	EditPropertyVehicleRequest,
	PropertyAvailableVehicleModel,
	GetPropertiesResponse,
	RelationshipsRequestData,
} from './models/propertiesModels';
import {Dispatch} from 'react';
import {UnknownAction} from '@reduxjs/toolkit';
import {setProperties} from '@actions/propertiesPageActions';
import {getFilterParams} from '@helpers/getFilterParams';

export const getPropertiesRequest = async (
	dispatch: Dispatch<UnknownAction> | false,
	pageIndex: number,
	pageSize: number,
	filters?: {categoryId?: number; vechicleId?: number}
): Promise<GetPropertiesResponse> => {
	let url = `/attribute/attributes?pageIndex=${pageIndex}&pageSize=${pageSize}`;

	const res = await requestCreator<undefined, GetPropertiesResponse>(
		'GET',
		getFilterParams(filters, url)
	);

	if (dispatch) {
		dispatch(setProperties(res));
	}

	return res;
};

export const getPropertyRequest = async (id: number): Promise<PropertyModel> =>
	await requestCreator<undefined, PropertyModel>('GET', `/attribute?id=${id}`);

export const addPropertyRequest = async (
	data: AddPropertyData
): Promise<AddPropertyResponse> => {
	const formData = new FormData();

	const dataProps = Object.keys(data) as (keyof AddPropertyData)[];

	dataProps.forEach((name: keyof AddPropertyData) => {
		if (name === 'bodyWorksIds') {
			// If the property is an array, append each item individually
			(data[name] as number[]).forEach((id) => {
				formData.append(`${name}`, id.toString());
			});
		} else if (name === 'image') {
			// Append the file directly
			formData.append(name, data[name] as Blob);
		} else {
			// Append other fields
			formData.append(name, data[name].toString());
		}
	});

	return await requestCreator<FormData, AddPropertyResponse>(
		'PUT',
		`/attribute`,
		formData,
		{
			'Content-Type': 'multipart/form-data',
		},
		`Cecha '${data.name}' została dodana!`
	);
};

export const editPropertyRequest = async (
	id: number,
	data: EditPropertyData
): Promise<PropertyModel> => {
	const formData = new FormData();

	const dataProps = Object.keys(data) as (keyof EditPropertyData)[];

	dataProps.forEach((name: keyof EditPropertyData) => {
		name &&
			formData.append(
				name,
				name === 'image' ? data[name] as Blob : data[name].toString()
			);
	});

	return await requestCreator<FormData, PropertyModel>(
		'POST',
		`/attribute?id=${id}`,
		formData,
		{
			'Content-Type': 'multipart/form-data',
		},
		`Cecha '${data.name}' została zedytowana!`
	);
};

export const deletePropertyRequest = async (id: number): Promise<string> =>
	await requestCreator<undefined, string>(
		'DELETE',
		`/attribute?id=${id}`,
		undefined,
		undefined,
		`Cecha o id: ${id} została usunięta.`
	);

// Dependencies

export const getDependentPropertiesRequest = async (
	id: string
): Promise<ExcludedPropertyModel[]> =>
	await requestCreator<undefined, ExcludedPropertyModel[]>(
		'GET',
		`/attribute/dependent-attributes?attributeId=${id}`
	);

export const getExcludedPropertiesRequest = async (
	id: string
): Promise<ExcludedPropertyModel[]> =>
	await requestCreator<undefined, ExcludedPropertyModel[]>(
		'GET',
		`/attribute/excluded-attributes?attributeId=${id}`
	);

// Currently unused replaced by: setRelationshipsPropertiesRequest
export const setDependentPropertiesRequest = async (
	id: string,
	data: number[]
): Promise<PropertyModel[]> =>
	await requestCreator<number[], PropertyModel[]>(
		'POST',
		`/attribute/dependent?attributeId=${id}`,
		data
	);

// Currently unused replaced by: setRelationshipsPropertiesRequest
export const setExcludedPropertiesRequest = async (
	id: string,
	data: number[]
): Promise<PropertyModel[]> =>
	await requestCreator<number[], PropertyModel[]>(
		'POST',
		`/attribute/excluded?attributeId=${id}`,
		data
	);

export const setRelationshipsPropertiesRequest = async (
	data: RelationshipsRequestData
): Promise<string> =>
	await requestCreator<RelationshipsRequestData, string>(
		'POST',
		`/attribute/relationships`,
		data
	);

// Vehicles

export const getPropertyVehiclesRequest = async (
	id: number
): Promise<PropertyVehicleModel[]> =>
	await requestCreator<undefined, PropertyVehicleModel[]>(
		'GET',
		`/attribute/vechicle-attributes?attributeId=${id}`
	);

export const addPropertyVehiclesRequest = async (
	data: AddPropertyVehicleRequest
): Promise<any> => {
	return await requestCreator<number[], any>(
		'PUT',
		`/attribute/vechicle-attribute?attributeId=${data.attributeId}&price=${data.price}&weight=${data.weight}`,
		data.vehicleIds,
		undefined,
		`Dane cechy dla modelu zostały zaktualizowane.`
	);
};

export const editPropertyVehiclesRequest = async (
	data: EditPropertyVehicleRequest
): Promise<PropertyVehicleModel[]> => {
	const formData = new FormData();

	data.image && formData.append('image', data.image);

	return await requestCreator<FormData, any>(
		'POST',
		`/attribute/vechicle-attribute?id=${data.id}&price=${data.price}&weight=${data.weight}`,
		formData,
		{
			'Content-Type': 'multipart/form-data',
		},
		`Dane cechy dla modelu zostały zaktualizowane.`
	);
};

export const deletePropertyVehiclesRequest = async (
	id: number
): Promise<string> =>
	await requestCreator<undefined, string>(
		'DELETE',
		`/attribute/vechicle-attribute?id=${id}`
	);

export const getPropertyAvailavleVehiclesRequest = async (
	id: number
): Promise<PropertyAvailableVehicleModel[]> =>
	await requestCreator<undefined, PropertyAvailableVehicleModel[]>(
		'GET',
		`/attribute/vechicles-available?attributeId=${id}`
	);
