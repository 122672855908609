// Libraries
import {useEffect, useMemo, useState} from 'react';
import {useSelector} from 'react-redux';
// Components
import Table from '@components/Table/Table';
import Container from '@components/Container/Container';
import {ReactComponent as Trash} from '@assets/icons/trash.svg';
import Button, {ButtonTag, ButtonVariant} from '@components/Button/Button';
// API
import {getPropertiesRequest} from '@api/properties';
// Redux
import {
	getPropertiesPageMaxPage,
	getPropertiesPageProperties,
} from '@selectors/propertiesPageSelectors';
// Assets
import emptyImage from '@assets/images/empty-image.png';
// Types
import {ModalType} from '@commonTypes/main';
import {Tag} from '@commonTypes/tags';
import classNames from 'classnames';
import Pagination from '@components/Pagination/Pagination';
import Filters from '@components/Filters/Filters';
import {getCategoriesRequest} from '@api/categories';
import {CategoryModel} from '@api/models/categoryModels';
import {getVehiclesRequest} from '@api/vehicles';
import {VehicleDetailsModel} from '@api/models/vehiclesModels';

interface PropertiesListProps {
	toggleModal: (isOpened: boolean, modalType: ModalType, id?: number) => void;
}

const PropertiesList = (props: PropertiesListProps) => {
	const {toggleModal} = props;

	const [allCategories, setAllCategories] = useState<CategoryModel[]>([]);
	const [allVehicles, setAllVehicles] = useState<VehicleDetailsModel[]>([]);

	const properties = useSelector(getPropertiesPageProperties);

	const tableElements = useMemo(
		() =>
			properties
				?.filter((item) => !!item)
				.map((item) => {
					return (
						!!item && {
							id: item.id,
							name: item.name,
							category: item.categoryName,
							price: `${item.price} ${item.isEuroPrice ? '€' : 'zł'}`,
							weight: item.weight,
							image: (
								<img
									className={classNames({'empty-image': !!!item.imageUrl})}
									src={!!item.imageUrl ? item.imageUrl : emptyImage}
									alt={item.name}
								/>
							),
							config: (
								<Button
									type='button'
									href={`/properties/dependencies/${item.id}`}
									as={ButtonTag.InternalLink}
									variant={ButtonVariant.Outline}>
									Zależności
								</Button>
							),
							configVehicles: (
								<Button
									type='button'
									href={`/properties/vehicles-manage/${item.id}`}
									as={ButtonTag.InternalLink}
									variant={ButtonVariant.Outline}>
									Lista pojazdów
								</Button>
							),
							edit: (
								<Button
									type='button'
									onClick={() => {
										toggleModal(true, ModalType.EDIT, item.id);
									}}
									variant={ButtonVariant.Outline}>
									Edytuj
								</Button>
							),
							delete: (
								<button
									type='button'
									className='button-delete'
									onClick={() => {
										toggleModal(true, ModalType.DELETE, item.id);
									}}>
									<Trash />
								</button>
							),
						}
					);
				}),
		[properties, toggleModal]
	);

	useEffect(() => {
		(async () => {
			await getCategoriesRequest(undefined, 1, 999999999).then((res) =>
				setAllCategories(res.list)
			);
			await getVehiclesRequest(undefined, 1, 999999999).then((res) =>
				setAllVehicles(res.vechicles)
			);
		})();
	}, []);

	return (
		<Container as={Tag.Section}>
			<Filters
				filters={[
					{
						name: 'categoryIds',
						label: 'Kategorie',
						options: allCategories.map((cat) => ({
							value: `${cat.id}`,
							name: cat.name,
						})),
					},
					{
						name: 'vechicleIds',
						label: 'Pojazd',
						options: allVehicles.map((vehicle) => ({
							value: `${vehicle.id}`,
							name: `${vehicle.brand} ${vehicle.model} ${vehicle.bodyType} ${vehicle.cabinType ?? ''} ${vehicle.chassis}`,
						})),
					},
				]}
			/>
			<Table
				headers={[
					{text: 'ID', sortName: 'id'},
					{text: 'Nazwa', sortName: 'name'},
					{text: 'Kategoria', sortName: 'categoryName'},
					{text: 'Cena', sortName: 'price'},
					{text: 'Waga (kg)', sortName: 'weight'},
					{text: 'Zdjęcie'},
					{text: 'Zależności'},
					{text: 'Przypisane pojazdy'},
					{text: 'Edytuj'},
					{text: 'Usuń'},
				]}
				elements={tableElements ?? []}
				groups={[
					{width: '5%'},
					{width: '15%', minWidth: 200},
					{width: '15%', minWidth: 170},
					{width: '15%', minWidth: 100},
					{width: '15%', minWidth: 110},
					{width: '15%'},
					{width: '5%'},
					{width: '5%', minWidth: 175},
					{width: '5%'},
					{width: '5%'},
				]}
			/>
			<Pagination
				request={getPropertiesRequest}
				getMaxPages={getPropertiesPageMaxPage}
			/>
		</Container>
	);
};

export default PropertiesList;
