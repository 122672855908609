import axios from 'axios';
// Helpers
import { getToken } from '@helpers/authorization';

export const api = axios.create({
	baseURL: process.env.REACT_APP_API_URL,
	headers: {
		Authorization: `Bearer ${getToken()}`,
		Accept: '*/*',
		'Content-Type': 'application/json',
	},
});


