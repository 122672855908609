import {BodyModel} from '@api/models/bodyModels';

export interface BodiesPage {
	bodies: BodyModel[] | null;
	currentPage: number;
	maxPage: number;
}

export enum BodiesPageActions {
	SET_BODIES = 'SET_BODIES',
	ADD_BODY = 'ADD_BODY',
	EDIT_BODY = 'EDIT_BODY',
	DELETE_BODY = 'DELETE_BODY',
	SET_BODY_CURRENT_PAGE = 'SET_BODY_CURRENT_PAGE',
	SET_BODY_MAX_PAGE = 'SET_BODY_MAX_PAGE',
}

const setBodies = (bodies: BodyModel[]) => ({
	type: BodiesPageActions.SET_BODIES,
	payload: bodies,
});

const addBody = (body: BodyModel) => ({
	type: BodiesPageActions.ADD_BODY,
	payload: body,
});

const editBody = (body: BodyModel) => ({
	type: BodiesPageActions.EDIT_BODY,
	payload: body,
});

const deleteBody = (id: number) => ({
	type: BodiesPageActions.DELETE_BODY,
	payload: id,
});

const setBodiesCurrentPage = (currentPage: number) => ({
	type: BodiesPageActions.SET_BODY_CURRENT_PAGE,
	payload: currentPage,
});

const setBodiesMaxPage = (maxPage: number) => ({
	type: BodiesPageActions.SET_BODY_MAX_PAGE,
	payload: maxPage,
});

export {
	setBodies,
	addBody,
	editBody,
	deleteBody,
	setBodiesCurrentPage,
	setBodiesMaxPage,
};
