import AppHeader from '@components/AppHeader/AppHeader';
import {useMemo, useState} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import styles from './TabConfigure.module.scss';
import Button, {ButtonTag, ButtonVariant} from '@components/Button/Button';
import Modal from '@components/Modal/Modal';
import PropertiesList from './components/PropertiesList/PropertiesList';
//Types
import {ModalType} from '@commonTypes/main';
import DeleteForm from './components/DeleteForm/DeleteForm';
import AddForm from './components/AddForm/AddForm';
import {TabCategoryModel} from '@api/models/tabs';
import {Tag} from '@commonTypes/tags';
import Container from '@components/Container/Container';

const TabConfigure = () => {
	const {id} = useParams();

	const [modalOpened, setModalOpened] = useState<boolean>(false);
	const [modalType, setModalType] = useState<ModalType>(ModalType.ADD);
	const [tabPropert, setTabPropert] = useState<TabCategoryModel | undefined>(
		undefined
	);

	const toggleModal = (
		isOpened: boolean,
		modalType: ModalType,
		tabProperty?: TabCategoryModel
	) => {
		if (modalType !== ModalType.ADD && tabProperty?.id === undefined)
			throw new Error('You must provide tabPropertyId!');
		setModalOpened(isOpened);
		setModalType(modalType);
		setTabPropert(tabProperty);
	};

	const navigate = useNavigate();

	const modalView = useMemo(() => {
		switch (modalType) {
			case ModalType.ADD:
				return <AddForm closeHandler={() => setModalOpened(false)} />;
			case ModalType.DELETE:
				if (!tabPropert) throw new Error('Modal DELETE require tabPropertyId');
				return (
					<DeleteForm
						tabProperty={tabPropert}
						closeHandler={() => setModalOpened(false)}
					/>
				);
			default:
				throw new Error(`Unknown modal type: ${modalType}`);
		}
	}, [modalType, tabPropert]);

	const modalTitle = useMemo(() => {
		switch (modalType) {
			case ModalType.ADD:
				return 'Dodaj kategorię cech dla zakładki.';
			case ModalType.DELETE:
				return 'Rozłącz powiązanie kategorii cech z zakładką.';
			default:
				throw new Error(`Unknown modal type: ${modalType}`);
		}
	}, [modalType]);

	return (
		<>
			<AppHeader
				title={`Skonfiguruj zakładkę: #${id}`}
				text='W panelu klienckim dodane kategorie zostaną pogrupowane w zakładce.'
				classes={styles.header}>
				<Button
					classes={styles.headerButton}
					type='button'
					onClick={() => toggleModal(true, ModalType.ADD)}>
					+ Dodaj kategorię
				</Button>
			</AppHeader>
			<PropertiesList toggleModal={toggleModal} />
			<Container as={Tag.Div}>
				<Button
					style={{marginLeft: 'auto'}}
					as={ButtonTag.Button}
					variant={ButtonVariant.OutlineWithBorder}
					onClick={() => navigate(-1)}>
					Cofnij
				</Button>
			</Container>
			{modalOpened && (
				<Modal title={modalTitle} closeHandler={() => setModalOpened(false)}>
					{modalView}
				</Modal>
			)}
		</>
	);
};

export default TabConfigure;
