// Libraries
import {combineReducers} from 'redux';
// Reducers
import {authReducer} from './authReducer';
import {categoryPageReducer} from './categoryPageReducer';
import {brandsPageReducer} from './brandsPageReducer';
import {bodiesPageReducer} from './bodiesPageReducer';
import {commonReducer} from './commonReducer';
import {modelsPageReducer} from './modelsPageReducer';
import {vehiclesPageReducer} from './vehiclesPageReducer';
import {propertiesPageReducer} from './propertiesPageReducer';
import {tabsPageReducer} from './tabsPageReducer';
import {propertyVehiclesPageReducer} from './propertyVehiclesPageReducer';
import {tabsPropertiesPageReducer} from './tabsConfigurePageReducer';
import {usersPageReducer} from './usersPageReducer';
import {appReducer} from './appReducer';
import {orderedConfigsPageReducer} from './orderedConfigsPageReducer';
// Types
import {BrandsPage} from '@actions/brandsPageAction';
import {CategoryPage} from '@actions/categoryPageActions';
import {BodiesPage} from '@actions/bodyPageActions';
import {ModelsPage} from '@actions/modelsPageActions';
import {VehiclesPage} from '@actions/vehiclesPageActions';
import {ProperitesPage} from '@actions/propertiesPageActions';
import {TabsPage} from '@actions/tabsPageActions';
import {PropertyVehiclesPage} from '@actions/propertyVehiclesPageActions';
import {TabPropertiesPage} from '@actions/tabsConfigurePageActions';
import {Common} from '@actions/commonActions';
import {UsersPage} from '@actions/usersPageActions';
import {AppState} from '@actions/appActions';
import {UserDetailsModel} from '@api/models/authModels';
import {OrderedConfigsPage} from '@actions/orderedConfigsPageActions';
import { PreviewOfferPage } from '@actions/previewOfferActions';
import { previewOfferPageReducer } from './previewOfferReducer';

export interface Store {
	app: Partial<AppState>;
	auth: Partial<UserDetailsModel>;
	bodiesPage: Partial<BodiesPage>;
	brandsPage: Partial<BrandsPage>;
	categoryPage: Partial<CategoryPage>;
	common: Partial<Common>;
	modelsPage: Partial<ModelsPage>;
	orderedConfigsPage: Partial<OrderedConfigsPage>;
	vehiclesPage: Partial<VehiclesPage>;
	propertiesPage: Partial<ProperitesPage>;
	tabsPage: Partial<TabsPage>;
	tabPropertiesPage: Partial<TabPropertiesPage>;
	propertyVehiclesPage: Partial<PropertyVehiclesPage>;
	usersPage: Partial<UsersPage>;
	previewOfferPage: Partial<PreviewOfferPage>;
}

export default combineReducers({
	app: appReducer,
	auth: authReducer,
	bodiesPage: bodiesPageReducer,
	brandsPage: brandsPageReducer,
	categoryPage: categoryPageReducer,
	common: commonReducer,
	modelsPage: modelsPageReducer,
	orderedConfigsPage: orderedConfigsPageReducer,
	vehiclesPage: vehiclesPageReducer,
	propertiesPage: propertiesPageReducer,
	tabsPage: tabsPageReducer,
	tabPropertiesPage: tabsPropertiesPageReducer,
	propertyVehiclesPage: propertyVehiclesPageReducer,
	usersPage: usersPageReducer,
	previewOfferPage: previewOfferPageReducer
} as Store);
