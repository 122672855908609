// Helpers
import {requestCreator} from '@helpers/requsetCreator';
// Types
import {CategoryModel, GetCategoriesResponse} from './models/categoryModels';
import {Dispatch} from 'react';
import {UnknownAction} from '@reduxjs/toolkit';
import {setCategoriesPage} from '@actions/categoryPageActions';

export const addCategoryRequest = async (
	name: string,
	isRequiredAttributeFromCategory: boolean
): Promise<CategoryModel> =>
	await requestCreator<FormData, CategoryModel>(
		'PUT',
		`/dictionary/category-add?name=${name}&isRequiredAttributeFromCategory=${
			isRequiredAttributeFromCategory ? 'true' : 'false'
		}`,
		undefined,
		undefined,
		`kategoria '${name}' została dodana!`
	);

export const editCategoryRequest = async (
	data: CategoryModel
): Promise<CategoryModel> =>
	await requestCreator<FormData, CategoryModel>(
		'POST',
		`/dictionary/category-edit?name=${data.name}&id=${
			data.id
		}&isRequiredAttributeFromCategory=${
			data.isRequiredAttributeFromCategory ? 'true' : 'false'
		}`,
		undefined,
		undefined,
		`Kategoria ${data.name} została zedytowana!`
	);

export const deleteCategoryRequest = async (id: number): Promise<string> =>
	await requestCreator<FormData, string>(
		'DELETE',
		`/dictionary/category-delete?id=${id}`,
		undefined,
		undefined,
		'Usuwanie kategorii zakończone sukcesem!'
	);

export const getCategoriesRequest = async (
	dispatch: Dispatch<UnknownAction> | undefined,
	pageIndex: number,
	pageSize: number
): Promise<GetCategoriesResponse> => {
	const res = await requestCreator<undefined, GetCategoriesResponse>(
		'GET',
		`/dictionary/categories-get?pageIndex=${pageIndex}&pageSize=${pageSize}`
	);

	dispatch && dispatch(setCategoriesPage(res));

	return res;
};
