// Libraries
import {useCallback, useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {toast} from 'react-toastify';
// Componetns
import {Text} from '@components/_Typography';
import Button, {ButtonVariant} from '@components/Button/Button';
// Redux
import {getPropertiesPageProperties} from '@selectors/propertiesPageSelectors';
import {deleteProperty} from '@actions/propertiesPageActions';
// Api
import {deletePropertyRequest} from '@api/properties';
// Types
import {PropertyModel} from '@api/models/propertiesModels';
// Styles
import styles from './DeleteForm.module.scss';

interface DeleteFormProps {
	id: number;
	closeHandler: () => void;
}

const DeleteForm = (props: DeleteFormProps) => {
	const {id, closeHandler} = props;

	const dispatch = useDispatch();

	const [category, setProperty] = useState<PropertyModel | undefined>(undefined);

	const properties = useSelector(getPropertiesPageProperties);

	useEffect(() => {
		if (id === undefined || properties === undefined) return;

		const currentCategory = properties?.find((category) => category.id === id);

		if (!!currentCategory) {
			setProperty(currentCategory);
		} else {
			toast.warning('Property not found.');
			closeHandler();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [id]);

	const deleteHandler = useCallback(async () => {
		await deletePropertyRequest(id).then(() => {
			dispatch(deleteProperty(id));
			closeHandler();
		});
	}, [id, closeHandler, dispatch]);

	return (
		<div className={styles.wrapper}>
			<Text>
				Czy chcesz usunąć cechę: <strong>{category?.name}</strong>?
			</Text>
			<div className={styles.buttons}>
				<Button
					type='button'
					variant={ButtonVariant.Outline}
					onClick={closeHandler}>
					Anuluj
				</Button>
				<Button
					type='button'
					variant={ButtonVariant.Danger}
					onClick={deleteHandler}>
					Usuń
				</Button>
			</div>
		</div>
	);
};

export default DeleteForm;
