import {FormField, FormKeys} from '@commonTypes/main';
import {useEffect} from 'react';
import {DetailsFn, initialState} from '../VehicleAddForm/VehicleAddForm';
import styles from './VehicleAddFormSizes.module.scss';
import {getVehicleRequest} from '@api/vehicles';
import SizeRange from '@components/_FormElements/SizeRange/SizeRange';
import {Height, Size} from '@api/models/vehiclesModels';
import {v4} from 'uuid';
import {Title} from '@components/_Typography';
import {TitleTag, TitleVariant} from '@components/_Typography/Title/Title';
import Checkbox from '@components/_FormElements/Checkbox/Checkbox';
import Input from '@components/_FormElements/Input/Input';

interface VehicleAddFormSizesProps {
	chassis: string | undefined;
	form: FormKeys<typeof initialState>;
	updateDetailsHandler: DetailsFn;
	updateFormHandler: (name: string, value: FormField) => void;
	showSizes: boolean;
	toggleShowSizes: () => void;
}

const VehicleAddFormSizes = (props: VehicleAddFormSizesProps) => {
	const {
		updateFormHandler,
		form,
		updateDetailsHandler,
		showSizes,
		toggleShowSizes,
	} = props;

	useEffect(() => {
		(async () => {
			if (form.id.value !== undefined) {
				await getVehicleRequest(form.id.value).then((res) => {
					updateDetailsHandler([
						{
							name: 'lenghts',
							value: res[0].vechicleAvailableLenghts.map((item) => ({
								...item,
								id: v4(),
							})),
						},
						{
							name: 'widths',
							value: res[0].vechicleAvailableWidths.map((item) => ({
								...item,
								id: v4(),
							})),
						},
						{
							name: 'heights',
							value: res[0].vechicleAvailableHeights.map((item) => ({
								...item,
								id: v4(),
							})),
						},
					]);
				});
			} else {
				updateDetailsHandler([
					{
						name: 'lenghts',
						value: [{id: '0', min: '', max: '', price: '', weight: ''}],
					},
					{
						name: 'widths',
						value: [{id: '0', min: '', max: '', price: '', weight: ''}],
					},
					{
						name: 'heights',
						value: [
							{id: '0', min: '', max: '', price: '', weight: '', spoilerOptionId: 0},
						],
					},
				]);
			}
		})();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [form.id.value]);

	return (
		<div className={styles.wrapper}>
			<Title
				tag={TitleTag.h3}
				variant={TitleVariant.standard}
				classes={styles.inline}>
				Wymiary{' '}
				<Checkbox
					id='sizes'
					name='sizes'
					label=''
					required={false}
					value={showSizes}
					valueChangeHandler={() => {
						toggleShowSizes()
					}}
					toggler
				/>
			</Title>
			{showSizes ? (
				<>
					<SizeRange
						label='Długości'
						data={form.lenghts}
						name={'lenghts'}
						updateFormHandler={updateFormHandler}
						initialNewItem={{min: '', max: '', price: '', weight: ''} as Size}
					/>
					<SizeRange
						label='Szerokości'
						data={form.widths}
						name={'widths'}
						updateFormHandler={updateFormHandler}
						initialNewItem={{min: '', max: '', price: '', weight: ''} as Size}
					/>
					<SizeRange
						label='Wysokości'
						data={form.heights}
						name={'heights'}
						updateFormHandler={updateFormHandler}
						initialNewItem={
							{min: '', max: '', price: '', weight: '', spoilerOptionId: 0} as Height
						}
					/>
				</>
			) : (
				<div className={styles.inline}>
					<Input
						id='price'
						name='price'
						placeholder={'Cena (zł)'}
						value={form.price.value}
						required={form.price.required}
						errorMessage={form.price.errorMessage}
						valueChangeHandler={updateFormHandler}
						label={'Cena'}
					/>
					<Input
						id='weight'
						name='weight'
						placeholder={'Waga (kg)'}
						value={form.weight.value}
						required={form.weight.required}
						errorMessage={form.weight.errorMessage}
						valueChangeHandler={updateFormHandler}
						label={'Waga'}
					/>
				</div>
			)}
		</div>
	);
};

export default VehicleAddFormSizes;
