import Row from './Row/Row';
import {FormField} from '@commonTypes/main';
import {Height, Size} from '@api/models/vehiclesModels';
import {v4 as uuidv4} from 'uuid';
import styles from './SizeRange.module.scss';

export interface Item extends Size {
	id: string;
	spoilerOptionId?: number;
}

interface SizeRangeProps {
	label: string;
	name: string;
	data: FormField;
	updateFormHandler: (name: string, value: FormField) => void;
	initialNewItem: Height | Size;
}

const SizeRange = (props: SizeRangeProps) => {
	const {label, name, data, updateFormHandler, initialNewItem} = props;

	const updateRow = (rowId: string, newData: Item) => {
		let errorMessage = '';
		if (+newData.min > +newData.max)
			errorMessage = 'Wartość minimalna nie może być większa od maksymalnej.';
		if (!!!newData.price && +newData.price !== 0)
			errorMessage = 'Cena jest wartością wymaganą.';
		if (!!!newData.weight && +newData.weight !== 0)
			errorMessage = 'Waga jest wartością wymaganą.';

		!!!errorMessage &&
			data.value.some((rowData: Item) => {
				if (rowData.id === newData.id) return false;
				// Min jest w przedziale
				if (+rowData.min <= +newData.min && +rowData.max >= +newData.min) {
					errorMessage = 'Przedziały się nakładają.';
					return true;
				}
				// Min jest w przed przedziałem, a max jest za przedziałem lub w przedziale
				if (+rowData.min > +newData.min && +rowData.min <= +newData.max) {
					errorMessage = 'Przedziały się nakładają.';
					return true;
				}

				return false;
			});

		let updatedIndex: number;

		updateFormHandler(name, {
			...data,
			value: data.value.map((item: Item, index: number) => {
				if (item.id === rowId) updatedIndex = index;

				return item.id === rowId
					? newData
					: updatedIndex !== undefined && updatedIndex + 1 === index
					? {...item, min: newData.max + 1}
					: item;
			}),
			errorMessage,
		});
	};

	const removeRow = (rowId: string) => {
		updateFormHandler(name, {
			...data,
			value: data.value.filter(({id}: Item) => id !== rowId),
		});
	};

	const addRow = () => {
		const lastData = data.value[data.value.length - 1];

		if (
			!!data.errorMessage ||
			(!!!lastData.max && +lastData.max !== 0) ||
			(!!!lastData.min && +lastData.min !== 0) ||
			(!!!lastData.price && +lastData.price !== 0) ||
			(!!!lastData.weight && +lastData.weight !== 0)
		)
			return updateFormHandler(name, {
				...data,
				errorMessage: `Uzupełnij wszytkie dane w wierszach ${label}.`,
			});

		updateFormHandler(name, {
			...data,
			value: [
				...data.value,
				{...initialNewItem, min: lastData.max + 1, id: uuidv4()},
			],
		});
	};

	return (
		<div>
			<p className={styles.label}>{label}</p>
			{data.value.map((item: Item, index: number) => (
				<Row
					key={name + item.id}
					id={name}
					index={index}
					rowId={item.id}
					updateFormHandler={updateRow}
					addRow={index === data.value.length - 1 ? addRow : undefined}
					removeRow={index === data.value.length - 1 && index !== 0 ? removeRow : undefined}
					data={item}
					disableMin={index !== 0}
				/>
			))}
			<p className={styles.error}>{data.errorMessage}</p>
		</div>
	);
};

export default SizeRange;
