// Types
import {Store} from '@reducers/rootReducer';

export const getTabsPage = (state: Store) => state.tabsPage;

export const getTabsPageTabs = (state: Store) =>
	state.tabsPage.tabs;

export const getTabsPageMaxPage = (state: Store) => state.tabsPage.maxPage;

export const getTabsPageCurrentPage = (state: Store) =>
	state.tabsPage.currentPage;
