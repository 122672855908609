import {FormField, ModalType} from '@commonTypes/main';
import Button, {ButtonVariant} from '@components/Button/Button';
import Select from '@components/_FormElements/Select/Select';
import {Title, Text} from '@components/_Typography';
import {TitleTag, TitleVariant} from '@components/_Typography/Title/Title';
import {useMemo, useState} from 'react';
import Modal from '@components/Modal/Modal';
import AddForm from './components/AddForm/AddForm';
import styles from './VehicleAddFormChassis.module.scss';
import {ChassisModel} from '@api/models/chassis';

interface VehicleAddFormChassisProps {
	currentChassis: string;
	allChassis: ChassisModel[];
	updateFormHandler: (name: string, value: FormField) => void;
}

interface ChasisSelect {
	value: string;
	label: string;
}

const VehicleAddFormChassis = (props: VehicleAddFormChassisProps) => {
	const {updateFormHandler, currentChassis, allChassis} = props;

	const [modal, setModal] = useState(false);
	const [modalType, setModalType] = useState<ModalType>(ModalType.ADD);
	const [newChassis, setNewChassis] = useState<ChasisSelect>();

	const chassis = useMemo(
		() => allChassis.map(({name}) => ({label: name, value: name})),
		[allChassis]
	);

	const addChassisHandler = (name: string) =>
		setNewChassis({label: name, value: name});

	const toggleModal = (isOpened: boolean, modalType: ModalType) => {
		if (modalType !== ModalType.ADD) throw new Error('You must provide id!');
		setModal(isOpened);
		setModalType(modalType);
	};

	const modalView = useMemo(() => {
		switch (modalType) {
			case ModalType.ADD:
				return (
					<AddForm
						updateFormHandler={updateFormHandler}
						addChassisHandler={addChassisHandler}
						closeHandler={() => setModal(false)}
					/>
				);
			default:
				throw new Error(`Unknown modal type: ${modalType}`);
		}
	}, [modalType, updateFormHandler]);

	const modalTitle = useMemo(() => {
		switch (modalType) {
			case ModalType.ADD:
				return 'Dodaj podwozie';
			case ModalType.EDIT:
				return 'Edytuj podwozie';
			case ModalType.DELETE:
				return 'Usuń podwozie';
			default:
				throw new Error(`Unknown modal type: ${modalType}`);
		}
	}, [modalType]);

	return (
		<>
			<div>
				<Title tag={TitleTag.h3} variant={TitleVariant.standard}>
					5. Wybierz podwozie
				</Title>
				<Text>
					Podwozia wraz z wymiarami są przypisywane dla zbioru wartości wybranych w poprzednich krokach tj.{' '}
					<strong>marki, modelu, typu zabudowy i kabiny (opcjonalnie)</strong>. Aby nowo dodane podwozie zostało zapisane, musi zostać podsumowana cała konfiguracja.
				</Text>
				<div className={styles.row}>
					<Select<string | undefined>
						id='chassis'
						name='chassis'
						label='Wybierz podwozie'
						value={currentChassis}
						errorMessage={''}
						valueChangeHandler={updateFormHandler}
						defaultOption={{value: '', label: 'Wybierz podwozie'}}
						required
						options={newChassis ? [...(chassis ?? []), newChassis] : chassis}
					/>
					<Button
						type='button'
						variant={ButtonVariant.OutlineWithBorder}
						onClick={() => toggleModal(true, ModalType.ADD)}>
						+ Dodaj podwozie
					</Button>
				</div>
			</div>
			{modal && (
				<Modal title={modalTitle} closeHandler={() => setModal(false)}>
					{modalView}
				</Modal>
			)}
		</>
	);
};

export default VehicleAddFormChassis;
