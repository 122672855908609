// Assets
import defaultImage from '@assets/images/empty-image.png';
// Styles
import styles from './Element.module.scss';

export interface ElementProps {
	id: string | number;
	imageUrl: string;
	name: string;
}

interface ElementProperties extends ElementProps {
	startDraggingHandler: () => void;
	dropHandler: () => void;
}

const Element = (props: ElementProperties) => {
	const {id, imageUrl, name, startDraggingHandler, dropHandler} = props;

	return (
		<li
			id={`${id}`}
			className={styles.wrapper}
			draggable
			onDragStart={startDraggingHandler}
			onDragEnd={dropHandler}>
			<div className={styles.image}>
				<img src={!!!imageUrl ? defaultImage : imageUrl} alt={name} />
			</div>{' '}
			{name} #{id}
		</li>
	);
};

export default Element;
