// Actions
import {BrandModel} from '@api/models/brandsModels';
import {BrandsPageActions} from '@actions/brandsPageAction';
// Types
import {BrandsPage} from '@actions/brandsPageAction';

const initialState: BrandsPage = {
	brands: null,
	currentPage: 1,
	maxPage: 1,
};

export const brandsPageReducer = (
	state = initialState,
	action: {type: BrandsPageActions; payload: BrandModel[] | BrandModel | number}
): BrandsPage => {
	switch (action.type) {
		case BrandsPageActions.SET_BRANDS:
			return {...state, brands: action.payload as BrandModel[]};
		case BrandsPageActions.ADD_BRAND:
			const newBrand = action.payload as BrandModel;
			const currentBrands = state?.brands;
			return {
				...state,
				brands: !!currentBrands?.length ? [...currentBrands, newBrand] : [newBrand],
			};
		case BrandsPageActions.EDIT_BRAND:
			const editedBrand = action.payload as BrandModel;
			return {
				...state,
				brands: state.brands?.map((item) =>
					item.id === editedBrand.id ? {...item, ...editedBrand} : item
				) as BrandModel[],
			};
		case BrandsPageActions.DELETE_BRAND:
			return {
				...state,
				brands: state.brands?.filter(
					(item) => item.id !== action.payload
				) as BrandModel[],
			};
		case BrandsPageActions.SET_BRANDS_CURRENT_PAGE:
			return {...state, currentPage: action.payload as number};
		case BrandsPageActions.SET_BRANDS_MAX_PAGE:
			return {...state, maxPage: action.payload as number};
		default:
			return state;
	}
};
