import {View, StyleSheet, Text} from '@react-pdf/renderer';
import React from 'react';

interface TableItem {
	name: React.ReactNode;
	value: React.ReactNode;
	value2?: React.ReactNode;
}

interface TableProps {
	title?: string;
	items: TableItem[];
}

const Table = (props: TableProps) => {
	const {title, items} = props;

	const styles = StyleSheet.create({
		wrapper: {
			border: '1px solid #00BBEE',
			marginTop: 10,
			marginBottom: 20,
			fontSize: 12,
		},
		header: {
			backgroundColor: '#005179',
			color: '#fff',
			padding: '5px 10px',
		},
		row: {
			display: 'flex',
			flexDirection: 'row',
			flexWrap: 'nowrap',
		},
		rowWithBorder: {
			borderTop: '1px solid #00BBEE',
			borderBottom: '1px solid #00BBEE',
		},
		description: {
			width: '65%',
			borderRight: '1px solid #00BBEE',
			padding: '5px 10px',
		},
		value: {
			width: '35%',
			padding: '5px 10px',
			textAlign: 'right',
		},
		value2: {
			width: '17.5%%',
			padding: '5px 10px',
			textAlign: 'right',
		},
	});

	return (
		<View style={styles.wrapper}>
			{title && (
				<View style={styles.header}>
					<Text style={{fontWeight: 'bold'}}>{title}</Text>
				</View>
			)}
			{items.map((item, index) => {
				const isLast = index === items.length - 1;
				const threeCols = item.value2;
				return (
					<View
						style={isLast ? styles.row : {...styles.row, ...styles.rowWithBorder}}>
						<View style={styles.description}>
							<Text>{item.name}</Text>
						</View>
						<View style={threeCols ? styles.value2 : styles.value}>
							<Text>{item.value}</Text>
						</View>
						{threeCols && (
							<View style={{...styles.value2, borderLeft: '1px solid #00BBEE'}}>
								<Text>{item.value2}</Text>
							</View>
						)}
					</View>
				);
			})}
		</View>
	);
};

export default Table;
