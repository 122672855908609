// Libraries
import {useState} from 'react';
// Components
import AppHeader from '@components/AppHeader/AppHeader';
import Button, {ButtonTag} from '@components/Button/Button';
import Modal from '@components/Modal/Modal';
import DeleteForm from './components/DeleteForm/DeleteForm';
// Styles
import styles from './Vehicles.module.scss';
import VehiclesList from './components/VehiclesList/VehiclesList';

const Vehicles = () => {
	const [currentVehicle, setCurrentVehicle] = useState<number | undefined>(
		undefined
	);
	const [modalOpened, setModalOpened] = useState(false);

	const openModal = (id: number) => {
		setModalOpened(true);
		setCurrentVehicle(id);
	};

	const closeModal = () => {
		setModalOpened(false);
		setCurrentVehicle(undefined);
	};

	return (
		<>
			<AppHeader
				title='Zarządzanie wersjami pojazdów'
				text='Konfiguruj dowolne wersje pojazdów'
				classes={styles.header}>
				<Button
					classes={styles.headerButton}
					as={ButtonTag.InternalLink}
					href={'/vehicles/add'}>
					+ Dodaj wersję pojazdu
				</Button>
			</AppHeader>
			<VehiclesList openModal={openModal} />
			{modalOpened && !!currentVehicle && (
				<Modal title={'Usuń kategorię'} closeHandler={closeModal}>
					<DeleteForm id={currentVehicle} closeHandler={closeModal} />
				</Modal>
			)}
		</>
	);
};

export default Vehicles;
