// Libraries
import {useCallback, useState} from 'react';
import {toast} from 'react-toastify';
// Componetns
import Input, {defaultInput} from '@components/_FormElements/Input/Input';
import Button, {ButtonVariant} from '@components/Button/Button';
// Types
import {FormField, FormKeys} from '@commonTypes/main';
// Styles
import styles from './AddForm.module.scss';

interface AddFormProps {
	closeHandler: () => void;
	updateFormHandler: (name: string, value: FormField) => void;
	addChassisHandler: (name: string) => void;
}

const initialState = {
	name: {...defaultInput, required: true},
};

const AddForm = (props: AddFormProps) => {
	const {closeHandler, updateFormHandler: update, addChassisHandler} = props;

	const [form, setForm] = useState<FormKeys<typeof initialState>>(initialState);

	const updateFormHandler = (name: string, value: FormField) => {
		setForm({...form, [name]: value});
	};


	const submitHandler = useCallback(() => {
		if (!!!form.name.value) return toast.warning('Chassis name is required!');
		update('chassis', form.name);
		addChassisHandler(form.name.value);
		closeHandler();
	}, [addChassisHandler, closeHandler, form.name, update]);

	return (
		<>
			<Input
				id='name'
				name='name'
				type='text'
				placeholder={'Wpisz nazwę'}
				label={'Nazwa podwozia'}
				required={form.name.required}
				value={form.name.value}
				errorMessage={form.name.errorMessage}
				valueChangeHandler={updateFormHandler}
			/>
			<div className={styles.buttons}>
				<Button
					type='button'
					variant={ButtonVariant.Outline}
					onClick={closeHandler}>
					Anuluj
				</Button>
				<Button
					type='button'
					onClick={submitHandler}
					variant={ButtonVariant.Primary}>
					Zapisz
				</Button>
			</div>
		</>
	);
};

export default AddForm;
