// Libraries
import React, {useMemo} from 'react';
import {Link} from 'react-router-dom';
import classNames from 'classnames';
// Types
import {ButtonHTMLAttributes, AnchorHTMLAttributes} from 'react';
import {Common} from '@commonTypes/main';
// Styles
import styles from './Button.module.scss';

export enum ButtonTag {
	InternalLink = 'Link',
	ExternalLink = 'a',
	Button = 'button',
}

export enum ButtonVariant {
	Primary = 'buttonPrimary',
	Light = 'buttonLight',
	Dark = 'buttonDark',
	Outline = 'buttonOutline',
	Danger = 'buttonDanger',
	OutlineWithBorder = 'buttonOutlineWithBorder',
}

interface CommonProps extends Common {
	as?: ButtonTag;
	fullWidth?: boolean;
	href?: string;
	variant?: ButtonVariant;
	type?: 'submit' | 'reset' | 'button';
}
type ButtonProps = ButtonHTMLAttributes<HTMLButtonElement>;
type AnchorProps = AnchorHTMLAttributes<HTMLAnchorElement>;

const Button: React.FC<
	(CommonProps & ButtonProps) | (CommonProps & AnchorProps)
> = (props) => {
	const {
		fullWidth,
		classes,
		children,
		id,
		href,
		as = ButtonTag.Button,
		variant = ButtonVariant.Primary,
		type = 'button',
	} = props;

	const config = useMemo(() => {
		const omitedProps = Object.fromEntries(
			Object.entries(props).filter(
				(e) =>
					e[0] !== 'fullWidth' &&
					e[0] !== 'classes' &&
					e[0] !== 'href' &&
					e[0] !== 'children' &&
					e[0] !== 'variant' &&
					e[0] !== 'as'
			)
		);
		return {
			...omitedProps,
			className: classNames(
				'button',
				styles.button,
				styles[variant],
				{
					[styles.buttonFullWidth]: fullWidth,
				},
				classes
			),
		};
	}, [classes, fullWidth, props, variant]);

	// Returned JSX
	switch (as) {
		case ButtonTag.InternalLink:
			const linkConfig = config as any;
			return (
				<Link id={id} {...linkConfig} to={href ?? '/'}>
					{children}
				</Link>
			);
		case ButtonTag.ExternalLink:
			const anchorConfig = config as AnchorProps;
			return (
				<a id={id} {...anchorConfig} href={href ?? '/'}>
					{children}
				</a>
			);
		default:
			const buttonConfig = config as ButtonProps;
			return (
				<button type={type} id={id} {...buttonConfig}>
					{children}
				</button>
			);
	}
};

export default Button;
