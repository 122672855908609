import {BrandModel} from '@api/models/brandsModels';

export interface BrandsPage {
	brands: BrandModel[] | null;
	currentPage: number;
	maxPage: number;
}

export enum BrandsPageActions {
	SET_BRANDS = 'SET_BRANDS',
	ADD_BRAND = 'ADD_BRAND',
	EDIT_BRAND = 'EDIT_BRAND',
	DELETE_BRAND = 'DELETE_BRAND',
	SET_BRANDS_CURRENT_PAGE = 'SET_BRANDS_CURRENT_PAGE',
	SET_BRANDS_MAX_PAGE = 'SET_BRANDS_MAX_PAGE',
}

const setBrands = (brands: BrandModel[]) => ({
	type: BrandsPageActions.SET_BRANDS,
	payload: brands,
});

const addBrand = (brand: BrandModel) => ({
	type: BrandsPageActions.ADD_BRAND,
	payload: brand,
});

const editBrand = (brand: BrandModel) => ({
	type: BrandsPageActions.EDIT_BRAND,
	payload: brand,
});

const deleteBrand = (id: number) => ({
	type: BrandsPageActions.DELETE_BRAND,
	payload: id,
});

const setBrandsCurrentPage = (currentPage: number) => ({
	type: BrandsPageActions.SET_BRANDS_CURRENT_PAGE,
	payload: currentPage,
});

const setBrandsMaxPage = (maxPage: number) => ({
	type: BrandsPageActions.SET_BRANDS_MAX_PAGE,
	payload: maxPage,
});

export {
	setBrands,
	addBrand,
	editBrand,
	deleteBrand,
	setBrandsCurrentPage,
	setBrandsMaxPage,
};
