import React from 'react';
// Components
import TableHeader from './components/TableHeader/TableHeader';
import TableElement from './components/TableElement/TableElement';
// Types
import {TableHeaderProps} from './components/TableHeader/TableHeader';
// Styles
import styles from './Table.module.scss';

interface TableProps extends TableHeaderProps {
	elements: any[];
	groups: {width: string; minWidth?: number | string}[];
	isRadio?: boolean;
}

const Table = (props: TableProps) => {
	const {headers, elements, groups, isRadio} = props;

	return (
		<div className={styles.container}>
			<table className={styles.wrapper}>
				<colgroup>
					{!!isRadio && <col span={1} style={{width: '5%', maxWidth: 30}} />}
					{groups.map((style, index) => (
						<col key={index} span={1} style={style} />
					))}
				</colgroup>
				<TableHeader headers={headers} />
				<tbody>
					{elements.map((item) => {
						const {checkbox, ...element} = item;

						return (
							<TableElement
								key={element.id}
								columns={element}
								isRadio={isRadio}
								checkbox={checkbox}
							/>
						);
					})}
				</tbody>
			</table>
			{!!!elements.length && (
				<div className={styles.empty}>
					<p>Tablica nie posiada elementów!</p>
				</div>
			)}
		</div>
	);
};

export default Table;
