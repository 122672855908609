// Libraries
import {useEffect, useMemo} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useParams} from 'react-router-dom';
import {toast} from 'react-toastify';
// Components
import Table from '@components/Table/Table';
import Container from '@components/Container/Container';
import {ReactComponent as Trash} from '@assets/icons/trash.svg';
// API
import {getTabCategoriesRequest} from '@api/tabs';
// Redux
import {setTabCategories} from '@actions/tabsConfigurePageActions';
import {getTabPropertiesPageTabCategories} from '@selectors/tabsPropertiesPageSelectors copy';
// Types
import {ModalType} from '@commonTypes/main';
import {Tag} from '@commonTypes/tags';
import {TabCategoryModel} from '@api/models/tabs';

interface PropertiesListProps {
	toggleModal: (
		isOpened: boolean,
		modalType: ModalType,
		tabProperty: TabCategoryModel
	) => void;
}

const PropertiesList = (props: PropertiesListProps) => {
	const {toggleModal} = props;

	const {id} = useParams();

	const dispatch = useDispatch();

	const categories = useSelector(getTabPropertiesPageTabCategories);

	useEffect(() => {
		(async () => {
			if (!!!id) return toast.error('Tab id unknown.');
			await getTabCategoriesRequest(+id).then((res) =>
				dispatch(setTabCategories(res))
			);
		})();
	}, [dispatch, id]);

	const tableElements = useMemo(
		() =>
			categories?.map((item) => {
				return {
					id: item.categoryId,
					name: item.categoryName,
					image: item.vechicleNames.toString(),
					delete: (
						<button
							type='button'
							className='button-delete'
							onClick={() => {
								toggleModal(true, ModalType.DELETE, item);
							}}>
							<Trash />
						</button>
					),
				};
			}),
		[categories, toggleModal]
	);

	return (
		<Container as={Tag.Section}>
			<Table
				headers={[
					{text: 'ID', sortName: 'id'},
					{text: 'Nazwa', sortName: 'name'},
					{text: 'Dostępne dla pojazadów'},
					{text: 'Usuń'},
				]}
				elements={tableElements ?? []}
				groups={[{width: '5%'}, {width: '30%'}, {width: '60%'}, {width: '5%'}]}
			/>
		</Container>
	);
};

export default PropertiesList;
