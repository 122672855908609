import React from 'react';
import {ReactComponent as LoaderSvg} from '@assets/icons/loader.svg';
import styles from './Loader.module.scss';

interface LoaderProps {
	title?: string;
}

const Loader = (props: LoaderProps) => {
	const {title} = props;

	return (
		<div className={styles.wrapper}>
			{!!title && <p className={styles.title}>{title}</p>}
			<div className={styles.icon}><LoaderSvg /></div>
		</div>
	);
};

export default Loader;
