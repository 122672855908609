// Types
import {Store} from '@reducers/rootReducer';

export const getBrandsPage = (state: Store) => state.brandsPage;

export const getBrandsPageBrands = (state: Store) => state.brandsPage.brands;

export const getBrandsPageMaxPage = (state: Store) => state.brandsPage.maxPage;

export const getBrandsPageCurrentPage = (state: Store) =>
	state.brandsPage.currentPage;
