// Actions
import {BodyModel} from '@api/models/bodyModels';
import { BodiesPageActions } from '@actions/bodyPageActions';
// Types
import { BodiesPage } from '@actions/bodyPageActions';

const initialState: BodiesPage = {
	bodies: null,
	currentPage: 1,
	maxPage: 1,
};

export const bodiesPageReducer = (
	state = initialState,
	action: {type: BodiesPageActions; payload: BodyModel[] | BodyModel | number}
): BodiesPage => {
	switch (action.type) {
		case BodiesPageActions.SET_BODIES:
			return {...state, bodies: action.payload as BodyModel[]};
		case BodiesPageActions.ADD_BODY:
			const newBody = action.payload as BodyModel;
			const currentBodies = state?.bodies;
			return {
				...state,
				bodies: !!currentBodies?.length ? [...currentBodies, newBody] : [newBody],
			};
		case BodiesPageActions.EDIT_BODY:
			const editedBody = action.payload as BodyModel;
			return {
				...state,
				bodies: state.bodies?.map((item) =>
					item.id === editedBody.id ? {...item, ...editedBody} : item
				) as BodyModel[],
			};
		case BodiesPageActions.DELETE_BODY:
			return {
				...state,
				bodies: state.bodies?.filter(
					(item) => item.id !== action.payload
				) as BodyModel[],
			};
		case BodiesPageActions.SET_BODY_CURRENT_PAGE:
			return {...state, currentPage: action.payload as number};
		case BodiesPageActions.SET_BODY_MAX_PAGE:
			return {...state, maxPage: action.payload as number};
		default:
			return state;
	}
};
