// Libraries
import {useCallback, useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {toast} from 'react-toastify';
// Componetns
import {Text} from '@components/_Typography';
import Button, {ButtonVariant} from '@components/Button/Button';
// Redux
import {deleteModel} from '@actions/modelsPageActions';
import {getModelsPageModels} from '@selectors/modelsPageSelectors';
// Api
import {deleteModelRequest} from '@api/models';
// Types
import {ModelModel} from '@api/models/modelModels';
// Styles
import styles from './DeleteForm.module.scss';

interface DeleteFormProps {
	id: number;
	closeHandler: () => void;
}

const DeleteForm = (props: DeleteFormProps) => {
	const {id, closeHandler} = props;

	const dispatch = useDispatch();

	const [category, setCategory] = useState<ModelModel | undefined>(undefined);

	const models = useSelector(getModelsPageModels);

	useEffect(() => {
		if (id === undefined || models === undefined) return;

		const currentCategory = models?.find((model) => model.id === id);

		if (!!currentCategory) {
			setCategory(currentCategory);
		} else {
			toast.warning('Category not found.');
			closeHandler();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [id]);

	const deleteHandler = useCallback(async () => {
		await deleteModelRequest(id).then(() => {
			dispatch(deleteModel(id));
			closeHandler();
		});
	}, [id, closeHandler, dispatch]);

	return (
		<div className={styles.wrapper}>
			<Text>
				Usunięcie kategorii spowoduje usunięcie przypisanych do niej cech. Czy
				chcesz usunąć kategorię: <strong>{category?.name}</strong>?
			</Text>
			<div className={styles.buttons}>
				<Button
					type='button'
					variant={ButtonVariant.Outline}
					onClick={closeHandler}>
					Anuluj
				</Button>
				<Button
					type='button'
					variant={ButtonVariant.Danger}
					onClick={deleteHandler}>
					Usuń
				</Button>
			</div>
		</div>
	);
};

export default DeleteForm;
