// Helpers
import {requestCreator} from '@helpers/requsetCreator';
// Types
import {AddBodyData, EditBodyData, BodyModel} from './models/bodyModels';

export const addBodyRequest = async (data: AddBodyData): Promise<BodyModel> => {
	const formData = new FormData();

	formData.append('image', data.image);

	return await requestCreator<FormData, BodyModel>(
		'PUT',
		`/dictionary/body-add?name=${data.name}`,
		formData,
		{
			'Content-Type': 'multipart/form-data',
		},
		`Zabudowa '${data.name}' została dodana!`
	);
};

export const editBodyRequest = async (data: EditBodyData): Promise<BodyModel> => {
	const formData = new FormData();

	formData.append('image', data.image);

	return await requestCreator<FormData, BodyModel>(
		'POST',
		`/dictionary/body-edit?name=${data.name}&id=${data.id}`,
		formData,
		{
			'Content-Type': 'multipart/form-data',
		},
		`Zabudowa ${data.name} została zedytowana!`
	);
};

export const deleteBodyRequest = async (id: number): Promise<string> =>
	await requestCreator<FormData, string>(
		'DELETE',
		`/dictionary/body-delete?id=${id}`,
		undefined,
		undefined,
		'Usuwanie zabudowy zakończone sukcesem!'
	);

export const getBodiesRequset = async (): Promise<BodyModel[]> =>
	await requestCreator<undefined, BodyModel[]>('GET', `/dictionary/body-get`);
