import {useEffect, useMemo, useState} from 'react';
// Components
import {Title, Text} from '@components/_Typography';
import CardWithSettings from '@components/CardWithSettings/CardWithSettings';
import CardsList from '@components/CardsList/CardsList';
// Api
import {getCabinsRequest} from '@api/cabins';
// Types
import {TitleTag, TitleVariant} from '@components/_Typography/Title/Title';
import {FormField, ModalType} from '@commonTypes/main';
import {CabinModel} from '@api/models/cabinsModels';
import AddForm from './components/AddForm/AddForm';
import DeleteForm from './components/DeleteForm/DeleteForm';
import Modal from '@components/Modal/Modal';
import Button, {ButtonVariant} from '@components/Button/Button';
import {toast} from 'react-toastify';
import styles from './VehicleAddFormVabin.module.scss';
import Checkbox from '@components/_FormElements/Checkbox/Checkbox';

interface VehicleAddFormCabinProps {
	currentCabin: number | undefined;
	modelId: number | undefined;
	bodyId: number | undefined;
	updateFormHandler: (name: string, value: FormField) => void;
}

const VehicleAddFormCabin = (props: VehicleAddFormCabinProps) => {
	const {updateFormHandler, currentCabin, modelId, bodyId} = props;
	const [modalOpened, setModalOpened] = useState(false);
	const [modalType, setModalType] = useState<ModalType>(ModalType.ADD);
	const [cabins, setCabins] = useState<CabinModel[]>([]);
	const [cabinId, setCabinId] = useState<number | undefined>();

	const toggleModal = (
		isOpened: boolean,
		modalType: ModalType,
		cabinId?: number
	) => {
		if (modalType !== ModalType.ADD && cabinId === undefined)
			throw new Error('You must provide brandId!');
		setCabinId(cabinId);
		setModalOpened(isOpened);
		setModalType(modalType);
	};

	useEffect(() => {
		(async () => {
			if (modelId === undefined || bodyId === undefined)
				return toast.warning('ModelId and BodyTypeId can not to be undefined.');
			await getCabinsRequest({modelId, bodyTypeId: bodyId}).then((res) =>
				setCabins(res)
			);
		})();
	}, [modelId, bodyId]);

	const modalView = useMemo(() => {
		switch (modalType) {
			case ModalType.ADD:
				return (
					<AddForm
						bodyId={bodyId}
						modelId={modelId}
						cabins={cabins}
						setCabins={setCabins}
						closeHandler={() => setModalOpened(false)}
					/>
				);
			case ModalType.EDIT:
				if (!cabinId) throw new Error('Modal EDIT require cabinId');
				return (
					<AddForm
						cabinId={cabinId}
						cabins={cabins}
						setCabins={setCabins}
						closeHandler={() => setModalOpened(false)}
					/>
				);
			case ModalType.DELETE:
				if (!cabinId) throw new Error('Modal DELETE require cabinId');
				return (
					<DeleteForm
						cabinId={cabinId}
						cabins={cabins}
						setCabins={setCabins}
						closeHandler={() => setModalOpened(false)}
					/>
				);
			default:
				throw new Error(`Unknown modal type: ${modalType}`);
		}
	}, [modalType, bodyId, modelId, cabins, cabinId]);

	const modalTitle = useMemo(() => {
		switch (modalType) {
			case ModalType.ADD:
				return 'Dodaj kabinę';
			case ModalType.EDIT:
				return 'Edytuj kabinę';
			case ModalType.DELETE:
				return 'Usuń kabinę';
			default:
				throw new Error(`Unknown modal type: ${modalType}`);
		}
	}, [modalType]);

	return (
		<div>
			<Title
				tag={TitleTag.h3}
				variant={TitleVariant.standard}
				classes={styles.title}>
				4. Wybierz Kabinę{' '}
				<Checkbox
					id='cabin'
					name='cabin'
					label=''
					required={false}
					value={currentCabin !== null}
					valueChangeHandler={() => {
						updateFormHandler('cabin', {
							isValid: true,
							errorMessage: '',
							value: currentCabin !== null ? null : undefined,
							required: true,
						});
					}}
					toggler
				/>
			</Title>
			<Text classes={styles.text}>
				Wybór kabiny jest krokiem opcjonalnym. Kabiny przypisywane są dla zbioru
				wartości wybranych w poprzednich krokach tj.{' '}
				<strong>marki, modelu i typu zabudowy</strong>.
			</Text>
			{currentCabin !== null && (
				<>
					<CardsList>
						{!!cabins.length ? (
							cabins?.map(({name, id, image}) => (
								<CardWithSettings
									key={id}
									name={name}
									imageUrl={image}
									isSelected={currentCabin === id}
									options={[
										{
											text: 'Edytuj',
											onClick: () => toggleModal(true, ModalType.EDIT, id),
										},
										{
											text: 'Usuń',
											onClick: () => toggleModal(true, ModalType.DELETE, id),
										},
									]}
									selectHandler={() => {
										updateFormHandler('cabin', {
											isValid: true,
											errorMessage: '',
											value: id,
											required: true,
										});
									}}
								/>
							))
						) : (
							<></>
						)}
					</CardsList>
					<div className={styles.button}>
						<Button
							variant={ButtonVariant.OutlineWithBorder}
							onClick={() => toggleModal(true, ModalType.ADD)}
							type='button'>
							+ Dodaj kabinę
						</Button>
					</div>
				</>
			)}
			{modalOpened && (
				<Modal title={modalTitle} closeHandler={() => setModalOpened(false)}>
					{modalView}
				</Modal>
			)}
		</div>
	);
};

export default VehicleAddFormCabin;
