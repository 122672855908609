import {GetModelsRequestResponse, ModelModel} from '@api/models/modelModels';

export interface ModelsPage {
	models: ModelModel[] | null;
	currentPage: number;
	maxPage: number;
}

export enum ModelsPageActions {
	SET_MODELS = 'SET_MODELS',
	ADD_MODEL = 'ADD_MODEL',
	EDIT_MODEL = 'EDIT_MODEL',
	DELETE_MODEL = 'DELETE_MODEL',
	SET_MODELS_CURRENT_PAGE = 'SET_MODELS_CURRENT_PAGE',
	SET_MODELS_MAX_PAGE = 'SET_MODELS_MAX_PAGE',
}

const setModels = (models: GetModelsRequestResponse) => ({
	type: ModelsPageActions.SET_MODELS,
	payload: models,
});

const addModel = (models: ModelModel) => ({
	type: ModelsPageActions.ADD_MODEL,
	payload: models,
});

const editModel = (brand: ModelModel) => ({
	type: ModelsPageActions.EDIT_MODEL,
	payload: brand,
});

const deleteModel = (id: number) => ({
	type: ModelsPageActions.DELETE_MODEL,
	payload: id,
});

const setModelsCurrentPage = (currentPage: number) => ({
	type: ModelsPageActions.SET_MODELS_CURRENT_PAGE,
	payload: currentPage,
});

const setModelsMaxPage = (maxPage: number) => ({
	type: ModelsPageActions.SET_MODELS_MAX_PAGE,
	payload: maxPage,
});

export {
	setModels,
	addModel,
	editModel,
	deleteModel,
	setModelsCurrentPage,
	setModelsMaxPage,
};
