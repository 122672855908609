// Actions
import {GetModelsRequestResponse, ModelModel} from '@api/models/modelModels';
import {ModelsPageActions} from '@actions/modelsPageActions';
// Types
import {ModelsPage} from '@actions/modelsPageActions';

const initialState: ModelsPage = {
	models: null,
	currentPage: 1,
	maxPage: 1,
};

export const modelsPageReducer = (
	state = initialState,
	action: {
		type: ModelsPageActions;
		payload: GetModelsRequestResponse | ModelModel[] | ModelModel | number;
	}
): ModelsPage => {
	switch (action.type) {
		case ModelsPageActions.SET_MODELS:
			const data = action.payload as GetModelsRequestResponse;

			return {
				...state,
				models: data.models,
				maxPage: data.totalPages,
				currentPage: data.pageIndex,
			};
		case ModelsPageActions.ADD_MODEL:
			return {
				...state,
				models: [...(state.models ?? []), action.payload as ModelModel],
			};
		case ModelsPageActions.EDIT_MODEL:
			const editedModel = action.payload as ModelModel;
			return {
				...state,
				models: state.models?.map((item) =>
					item.id === editedModel.id ? {...item, ...editedModel} : item
				) as ModelModel[],
			};
		case ModelsPageActions.DELETE_MODEL:
			return {
				...state,
				models: state.models?.filter(
					(item) => item.id !== action.payload
				) as ModelModel[],
			};
		case ModelsPageActions.SET_MODELS_CURRENT_PAGE:
			return {...state, currentPage: action.payload as number};
		case ModelsPageActions.SET_MODELS_MAX_PAGE:
			return {...state, maxPage: action.payload as number};
		default:
			return state;
	}
};
