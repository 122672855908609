import {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
// Components
import {Title, Text} from '@components/_Typography';
import Table from '@components/Table/Table';
// Api
import {getModelsRequest} from '@api/models';
// Redux
import {getModelsPageModels} from '@selectors/modelsPageSelectors';
// Types
import {TitleTag, TitleVariant} from '@components/_Typography/Title/Title';
import {FormField} from '@commonTypes/main';

interface VehicleAddFormModelProps {
	brandId: number;
	currentModel: number | undefined;
	updateFormHandler: (name: string, value: FormField) => void;
}
const VehicleAddFormModel = (props: VehicleAddFormModelProps) => {
	const {currentModel, updateFormHandler, brandId} = props;

	const dispatch = useDispatch();

	const models = useSelector(getModelsPageModels);

	useEffect(() => {
		window.scrollTo(0, document.body.scrollHeight);

		(async () => {
			await getModelsRequest(dispatch, 1, 99999999);
		})();
	}, [dispatch]);

	const update = (name: string, value: any) => {
		updateFormHandler('model', {
			isValid: true,
			errorMessage: '',
			value: +name,
			required: true,
		});
	};

	return (
		<div>
			<Title tag={TitleTag.h3} variant={TitleVariant.standard}>
				2. Wybierz model
			</Title>
			<Text>Lista dostępnych modeli dla marki wybranej w kroku 1.</Text>
			<Table
				headers={[
					{text: ''},
					{text: 'ID', sortName: 'id'},
					{text: 'Model', sortName: 'model'},
				]}
				elements={
					models
						?.filter((model) => model.brandId === brandId)
						.map((model) => ({
							id: model.id,
							model: model.name,
							checkbox: {
								value: currentModel === model.id,
								name: `${model.id}`,
								id: `${model.id}`,
								label: '',
								valueChangeHandler: update,
								required: false,
							},
						})) ?? []
				}
				isRadio={true}
				groups={[{width: '20%'}, {width: '45%', minWidth: 170}]}
			/>
		</div>
	);
};

export default VehicleAddFormModel;
