import {useEffect, useMemo} from 'react';
import {useDispatch, useSelector} from 'react-redux';
// Components
import Table from '@components/Table/Table';
import Container from '@components/Container/Container';
import Button, {ButtonTag, ButtonVariant} from '@components/Button/Button';
// Assets
import {ReactComponent as Trash} from '@assets/icons/trash.svg';
// Api
import {getTabs} from '@api/tabs';
// Redux
import {setTabs} from '@actions/tabsPageActions';
import {getTabsPageTabs} from '@selectors/tabsPageSelectors';
// Types
import {Tag} from '@commonTypes/tags';
import {ModalType} from '@commonTypes/main';

interface TabsListProps {
	toggleModal: (isOpened: boolean, modalType: ModalType, id?: number) => void;
}

const TabsList = (props: TabsListProps) => {
	const {toggleModal} = props;

	const dispatch = useDispatch();

	const tabs = useSelector(getTabsPageTabs);

	useEffect(() => {
		(async () => {
			await getTabs().then((res) => dispatch(setTabs(res)));
		})();
	}, [dispatch]);

	const tableElements = useMemo(
		() =>
			tabs?.map((item) => {
				return {
					id: item.id,
					name: item.name,
					model: !!item.categories.length ? item.categories.toString() : 'Brak',
					bodies: item.carBodyTypes.map(({name}) => name).toString(),
					edit: (
						<Button
							type='button'
							variant={ButtonVariant.Outline}
							onClick={() => {
								toggleModal(true, ModalType.EDIT, item.id);
							}}>
							Edytuj
						</Button>
					),
					configure: (
						<Button
							type='button'
							as={ButtonTag.InternalLink}
							href={`/tabs/configure/${item.id}`}
							variant={ButtonVariant.Outline}>
							Przypisz kategorie
						</Button>
					),
					delete: (
						<button
							type='button'
							className='button-delete'
							onClick={() => {
								toggleModal(true, ModalType.DELETE, item.id);
							}}>
							<Trash />
						</button>
					),
				};
			}),
		[tabs, toggleModal]
	);

	return (
		<Container as={Tag.Section}>
			<Table
				headers={[
					{text: 'ID', sortName: 'id'},
					{text: 'Zakładka', sortName: 'tab'},
					{text: 'Kategorie cech', sortName: 'categories'},
					{text: 'Zabudowy', sortName: 'bodies'},
					{text: 'Edytuj'},
					{text: 'Przypisz kategorie'},
					{text: 'Usuń'},
				]}
				elements={tableElements ?? []}
				groups={[
					{width: '5%'},
					{width: '15%'},
					{width: '35%', minWidth: 170},
					{width: '35%', minWidth: 162},
					{width: '5%'},
					{width: '5%', minWidth: 180},
					{width: '5%'},
				]}
			/>
		</Container>
	);
};

export default TabsList;
