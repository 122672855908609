import React, {
	useState,
	useEffect,
	useRef,
	useCallback,
	MouseEventHandler,
} from 'react';
// Icons
// Styles
import styles from './Options.module.scss';
import classNames from 'classnames';

export interface Option {
	icon?: React.ReactNode;
	text: string;
	onClick: (...props: any) => void;
}

interface OptionsProps {
	options: Option[];
}

const Options = (props: OptionsProps) => {
	const {options} = props;
	const [visible, setVisible] = useState(false);
	const optionsRef = useRef<HTMLDivElement>(null);

	useEffect(() => {
		const handleClickOutside = (event: MouseEvent) => {
			if (
				optionsRef.current &&
				!optionsRef.current.contains(event.target as Node)
			) {
				setVisible(false);
			}
		};

		if (visible) {
			document.addEventListener('mousedown', handleClickOutside);
		} else {
			document.removeEventListener('mousedown', handleClickOutside);
		}

		return () => {
			document.removeEventListener('mousedown', handleClickOutside);
		};
	}, [visible]);

	const expandHandler: MouseEventHandler<HTMLButtonElement> = useCallback(
		(e) => {
			e.stopPropagation();
			setVisible(!visible);
		},
		[visible]
	);

	return (
		<div className={classNames(styles.wrapper, 'options')} ref={optionsRef}>
			<button type='button' className={styles.button} onClick={expandHandler}>
				<span className={styles.buttonInner} />
				<span className={styles.buttonInner} />
				<span className={styles.buttonInner} />
			</button>
			{visible && (
				<div className={styles.options}>
					{options.map(({icon, text, onClick}) => (
						<button
							key={text}
							className={styles.optionsItem}
							onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
								e.stopPropagation();
								onClick();
								setVisible(false);
							}}>
							{icon}
							{text}
						</button>
					))}
				</div>
			)}
		</div>
	);
};

export default Options;
