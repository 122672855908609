// Actions
import {
	GetPropertiesResponse,
	PropertyModel,
} from '@api/models/propertiesModels';
import {
	ProperitesPage,
	PropertiesPageActions,
} from '@actions/propertiesPageActions';

const initialState: ProperitesPage = {
	properties: null,
	currentPage: 1,
	maxPage: 1,
};

export const propertiesPageReducer = (
	state = initialState,
	action: {
		type: PropertiesPageActions;
		payload: GetPropertiesResponse | PropertyModel[] | PropertyModel | number;
	}
): ProperitesPage => {
	switch (action.type) {
		case PropertiesPageActions.ADD_PROPERTY:
			return {
				...state,
				properties: [...(state.properties ?? []), action.payload as PropertyModel],
			};
		case PropertiesPageActions.SET_PROPERTIES:
			const data = action.payload as GetPropertiesResponse

			return {...state, properties: data.attributes, maxPage: data.totalPages, currentPage: data.pageIndex};
		case PropertiesPageActions.EDIT_PROPERTY:
			const editedProperty = action.payload as PropertyModel;
			return {
				...state,
				properties: state.properties?.map((item) =>
					item.id === editedProperty.id ? {...item, ...editedProperty} : item
				) as PropertyModel[],
			};
		case PropertiesPageActions.DELETE_PROPERTY:
			return {
				...state,
				properties: state.properties?.filter(
					(item) => item.id !== action.payload
				) as PropertyModel[],
			};
		case PropertiesPageActions.SET_PROPERTIES_CURRENT_PAGE:
			return {...state, currentPage: action.payload as number};
		case PropertiesPageActions.SET_PROPERTIES_MAX_PAGE:
			return {...state, maxPage: action.payload as number};
		default:
			return state;
	}
};
