import {PropertyAvailableVehicleModel, PropertyVehicleModel} from '@api/models/propertiesModels';

export interface PropertyVehiclesPage {
	vehicles: PropertyVehicleModel[] | null;
	availableVehicles: PropertyAvailableVehicleModel[] | null;
	currentPage: number;
	maxPage: number;
}

export enum PropertyVehiclesPageActions {
	SET_PROPERTY_VEHICLES = 'SET_PROPERTY_VEHICLES',
	SET_PROPERTY_AVAILABLE_VEHICLES = 'SET_PROPERTY_AVAILABLE_VEHICLES',
	DELETE_PROPERTY_VEHICLES = 'DELETE_PROPERTY_VEHICLES',
	EDIT_PROPERTY_VEHICLES = 'EDIT_PROPERTY_VEHICLES',
}

export const setPropertyVehicles = (vehicles: PropertyVehicleModel[]) => ({
	type: PropertyVehiclesPageActions.SET_PROPERTY_VEHICLES,
	payload: vehicles,
});

export const setPropertyAvailableVehicles = (vehicles: PropertyAvailableVehicleModel[]) => ({
	type: PropertyVehiclesPageActions.SET_PROPERTY_AVAILABLE_VEHICLES,
	payload: vehicles,
});

export const editPropertyVehicle = (vehicle: PropertyVehicleModel) => ({
	type: PropertyVehiclesPageActions.EDIT_PROPERTY_VEHICLES,
	payload: vehicle,
});

export const deletePropertyVehicles = (id: number) => ({
	type: PropertyVehiclesPageActions.DELETE_PROPERTY_VEHICLES,
	payload: id,
});
