import {useEffect} from 'react';
import {useSelector, useDispatch} from 'react-redux';
// Components
import CardWithSettings from '@components/CardWithSettings/CardWithSettings';
import Container from '@components/Container/Container';
// Api
import {getBodiesRequset} from '@api/body';
// Redux
import {
	setBodies,
	setBodiesCurrentPage,
	setBodiesMaxPage,
} from '@actions/bodyPageActions';
import {getBodyPageBodies} from '@selectors/bodyPageSelectors';
// Styles
import styles from './BodiesList.module.scss';
// Types
import {Tag} from '@commonTypes/tags';
import {ModalType} from '@commonTypes/main';

interface BodiesListProps {
	toggleModal: (
		isOpened: boolean,
		modalType: ModalType,
		bodyId?: number
	) => void;
}

const BodiesList = (props: BodiesListProps) => {
	const {toggleModal} = props;

	const bodies = useSelector(getBodyPageBodies);

	const dispatch = useDispatch();

	useEffect(() => {
		(async () => {
			const res = await getBodiesRequset();

			dispatch(setBodies(res));
			dispatch(setBodiesCurrentPage(1));
			dispatch(setBodiesMaxPage(1));
		})();
	}, [dispatch]);

	return (
		<Container as={Tag.Section} classes={styles.list}>
			{bodies &&
				bodies.map(({name, image, id}) => (
					<CardWithSettings
						key={id}
						name={name}
						imageUrl={image}
						options={[
							{text: 'Edytuj', onClick: () => toggleModal(true, ModalType.EDIT, id)},
							{text: 'Usuń', onClick: () => toggleModal(true, ModalType.DELETE, id)},
						]}
					/>
				))}
		</Container>
	);
};

export default BodiesList;
