// Libraries
import {FormEvent, useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
// Componetns
import Input, {defaultInput} from '@components/_FormElements/Input/Input';
import Button, {ButtonVariant} from '@components/Button/Button';
import Select from '@components/_FormElements/Select/Select';
// Helpers
import {validateForm} from '@helpers/validateForm';
// Api

// Redux
// Types
import {FormField, FormKeys} from '@commonTypes/main';
// Styles
import styles from './AddForm.module.scss';
import {getCategoriesRequest} from '@api/categories';
import {
	addPropertyVehiclesRequest,
	getPropertyAvailavleVehiclesRequest,
	getPropertyVehiclesRequest,
} from '@api/properties';
import {addProperty} from '@actions/propertiesPageActions';
import {useParams} from 'react-router-dom';
import {
	setPropertyAvailableVehicles,
	setPropertyVehicles,
} from '@actions/propertyVehiclesPageActions';
import {toast} from 'react-toastify';
import {getPropertyAvailableVehiclesPageVehicles} from '@selectors/propertyVehiclesPageSelectors';

interface AddFormProps {
	closeHandler: () => void;
}

const initialState = {
	weight: {...defaultInput, required: false},
	price: {...defaultInput, required: false},
	vehicles: {
		...defaultInput,
		required: true,
		value: [] as number[],
	},
};

const AddForm = (props: AddFormProps) => {
	const {closeHandler} = props;

	const {id} = useParams();

	const [form, setForm] = useState<FormKeys<typeof initialState>>(initialState);
	const vehicles = useSelector(getPropertyAvailableVehiclesPageVehicles);

	const dispatch = useDispatch();

	const updateFormHandler = (name: string, value: FormField) => {
		setForm({...form, [name]: value});
	};

	const submitHandler = async (e: FormEvent) => {
		e.preventDefault();
		const isError = validateForm(form, setForm);

		if (isError || !!!id) return;

		await addPropertyVehiclesRequest({
			attributeId: +id,
			price: form.price.value,
			weight: form.weight.value,
			vehicleIds: form.vehicles.value,
		}).then((res) => {
			dispatch(addProperty(res.value));
			setForm(initialState);
			closeHandler();
		});

		await getPropertyVehiclesRequest(+id).then((res) => {
			dispatch(setPropertyVehicles(res));
		});
	};

	useEffect(() => {
		(async () => {
			await getCategoriesRequest(dispatch, 1, 999999)
			if (id) {
				await getPropertyAvailavleVehiclesRequest(+id).then((res) => {
					dispatch(setPropertyAvailableVehicles(res));
				});
			} else {
				toast.warning('Unknown property id');
			}
		})();
	}, [dispatch, id]);

	return (
		<form onSubmit={submitHandler}>
			<Select<number | undefined>
				id='vehicles'
				name='vehicles'
				label={'Wersje pojazdów'}
				value={form.vehicles.value}
				errorMessage={form.vehicles.errorMessage}
				valueChangeHandler={updateFormHandler}
				defaultOption={{value: 0, label: 'Przypisz wersje pojazdów'}}
				options={vehicles?.map((vehicle) => ({
					value: vehicle.id,
					label: `${vehicle.brand} ${vehicle.model} ${vehicle.bodyType} ${vehicle.cabin} ${vehicle.chassis}`,
				}))}
				multiselect
				required
			/>
			<div className={styles.metrics}>
				<Input
					id='price'
					name='price'
					type='text'
					placeholder={'Wpisz cenę'}
					label={'Podstawowa cena'}
					required={form.price.required}
					value={form.price.value}
					errorMessage={form.price.errorMessage}
					valueChangeHandler={updateFormHandler}
				/>
				<Input
					id='weight'
					name='weight'
					type='text'
					placeholder={'Wpisz wagę'}
					label={'Podstawowa waga'}
					required={form.weight.required}
					value={form.weight.value}
					errorMessage={form.weight.errorMessage}
					valueChangeHandler={updateFormHandler}
				/>
			</div>

			<div className={styles.buttons}>
				<Button
					type='button'
					variant={ButtonVariant.Outline}
					onClick={closeHandler}>
					Anuluj
				</Button>
				<Button type='submit' variant={ButtonVariant.Primary}>
					Zapisz
				</Button>
			</div>
		</form>
	);
};

export default AddForm;
