// Libraries
import {useCallback, useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {toast} from 'react-toastify';
// Componetns
import {Text} from '@components/_Typography';
import Button, {ButtonVariant} from '@components/Button/Button';
// Redux
import {deleteVehicle} from '@actions/vehiclesPageActions';
import {getVehiclesPageVehicles} from '@selectors/vehiclesPageSelectors';
// Api
import {deleteVehicleRequest} from '@api/vehicles';
// Types
import {VehicleDetailsModel} from '@api/models/vehiclesModels';
// Styles
import styles from './DeleteForm.module.scss';

interface DeleteFormProps {
	id: number;
	closeHandler: () => void;
}

const DeleteForm = (props: DeleteFormProps) => {
	const {id, closeHandler} = props;

	const dispatch = useDispatch();

	const [vehicle, setVehicle] = useState<VehicleDetailsModel | undefined>(undefined);

	const vehicles = useSelector(getVehiclesPageVehicles);

	useEffect(() => {
		if (id === undefined || vehicles === undefined) return;

		const currentVehicle = vehicles?.find((category) => category.id === id);

		if (!!currentVehicle) {
			setVehicle(currentVehicle);
		} else {
			toast.warning('Category not found.');
			closeHandler();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [id]);

	const deleteHandler = useCallback(async () => {
		await deleteVehicleRequest(id).then(() => {
			dispatch(deleteVehicle(id));
			closeHandler();
		});
	}, [id, closeHandler, dispatch]);

	return (
		<div className={styles.wrapper}>
			<Text>
				Czy chcesz usunąć pojazd o ID: <strong>{vehicle?.id}</strong>?
			</Text>
			<div className={styles.buttons}>
				<Button
					type='button'
					variant={ButtonVariant.Outline}
					onClick={closeHandler}>
					Anuluj
				</Button>
				<Button
					type='button'
					variant={ButtonVariant.Danger}
					onClick={deleteHandler}>
					Usuń
				</Button>
			</div>
		</div>
	);
};

export default DeleteForm;
