// Types
import {Store} from '@reducers/rootReducer';

export const getTabPropertiesPage = (state: Store) => state.tabPropertiesPage;

export const getTabPropertiesPageTabCategories = (state: Store) =>
	state.tabPropertiesPage.tabProperties;

export const getTabPropertiesPageMaxPage = (state: Store) =>
	state.tabPropertiesPage.maxPage;

export const getTabPropertiesPageCurrentPage = (state: Store) =>
	state.tabPropertiesPage.currentPage;
