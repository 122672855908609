// Libraries
import React, {useRef, useState} from 'react';
// Assets
import {ReactComponent as Image} from '@assets/icons/image.svg';
import {ReactComponent as Trash} from '@assets/icons/trash.svg';
import {ReactComponent as Settings} from '@assets/icons/settings.svg';
// Helpers
import validation from '@helpers/validation';
// Types
import {FormField} from '@commonTypes/main';
// Styles
import styles from './FileInput.module.scss';

interface FileInputProps {
	id: string;
	name: string;
	accept: string;
	label: string;
	valueChangeHandler: (name: string, value: FormField) => void;
	value: File | undefined;
	errorMessage?: string;
	placeholder?: string;
	required: boolean;
}

const FileInput = (props: FileInputProps) => {
	const {
		label,
		name,
		id,
		placeholder,
		errorMessage,
		accept,
		value,
		valueChangeHandler,
		required,
	} = props;

	const [touched, setTouched] = useState(false);
	const [dragged, setDragged] = useState(false);

	const inputRef = useRef<HTMLInputElement>(null);

	const updateFile = (file: File | undefined) => {
		const errorMessage = validation(touched, file, name, required);

		valueChangeHandler(name, {
			value: file,
			errorMessage,
			isValid: !!!errorMessage,
			required: required,
		});
	};

	const deleteFileHandler = () => updateFile(undefined);

	const fileEnterHandler = (e: any) => {
		e.preventDefault();
		e.stopPropagation();
		if (dragged) return;
		setDragged(true);
	};

	const fileLeaveHandler = (e: any) => {
		e.preventDefault();
		e.stopPropagation();
		setDragged(false);
	};

	const fileDropHandler = (e: any) => {
		e.preventDefault();
		e.stopPropagation();
		updateFile(e.dataTransfer.files[0]);
		setDragged(false);
	};

	const changeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
		updateFile(e.target.files?.[0]);
	};

	return (
		<div
			className={styles.input}
			data-status={!!errorMessage && 'error'}
			data-is-dragged={dragged}>
			<span className={styles.inputLabel}>{label}</span>
			<label className={styles.inputField} onDragEnter={fileEnterHandler}>
				{!!value && (
					<button
						type='button'
						className={styles.inputEdit}
						onMouseUp={() => {
							inputRef.current?.click();
						}}>
						<Settings />
					</button>
				)}
				{!!value && (
					<button
						type='button'
						className={styles.inputRemove}
						onMouseUp={deleteFileHandler}>
						<Trash />
					</button>
				)}
				<input
					ref={inputRef}
					type='file'
					name={name}
					id={id}
					value=''
					accept={accept}
					onChange={changeHandler}
					onDrop={fileDropHandler}
					onDragLeave={fileLeaveHandler}
					onClick={() => setTouched(true)}
				/>
				<span>
					{!!value ? (
						<img src={URL.createObjectURL(value)} alt={value.name} />
					) : (
						<>
							<Image />
							{placeholder}
						</>
					)}
				</span>
			</label>
			{!!errorMessage && (
				<span
					className={styles.inputError}
					dangerouslySetInnerHTML={{__html: errorMessage}}
				/>
			)}
		</div>
	);
};

export default FileInput;
