// Libraries
import {useCallback, useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {toast} from 'react-toastify';
// Componetns
import {Text} from '@components/_Typography';
import Button, {ButtonVariant} from '@components/Button/Button';
// Redux
import { deleteBody } from '@actions/bodyPageActions';
import { getBodyPageBodies } from '@selectors/bodyPageSelectors';
// Api
import { deleteBodyRequest } from '@api/body';
// Types
import { BodyModel } from '@api/models/bodyModels';
// Styles
import styles from './DeleteForm.module.scss';

interface DeleteFormProps {
	bodyId: number;
	closeHandler: () => void;
}

const DeleteForm = (props: DeleteFormProps) => {
	const {bodyId, closeHandler} = props;

	const dispatch = useDispatch();

	const [body, setBody] = useState<BodyModel | undefined>(undefined);

	const bodies = useSelector(getBodyPageBodies);

	useEffect(() => {
		if (bodyId === undefined || bodies === undefined) return;
		
		const currentBody = bodies?.find(({id}) => id === bodyId);

		if (!!currentBody) {
			setBody(currentBody);
		} else {
			toast.warning('Body not found.');
			closeHandler();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [bodyId]);
	
	const deleteHandler = useCallback(async () => {
		await deleteBodyRequest(bodyId).then(() => {
			dispatch(deleteBody(bodyId));
			closeHandler();
		});
	}, [bodyId, closeHandler, dispatch]);

	return (
		<div className={styles.wrapper}>
			<Text>
				Usunięcie zabudowy spowoduje usunięcie przypisanych do niej pojazdów. Czy
				chcesz usunąć zabudowę: <strong>{body?.name}</strong>?
			</Text>
			<img className={styles.image} src={body?.image} alt={body?.name} />
			<div className={styles.buttons}>
				<Button
					type='button'
					variant={ButtonVariant.Outline}
					onClick={closeHandler}>
					Anuluj
				</Button>
				<Button
					type='button'
					variant={ButtonVariant.Danger}
					onClick={deleteHandler}>
					Usuń
				</Button>
			</div>
		</div>
	);
};

export default DeleteForm;
