// Helpers
import {requestCreator} from '@helpers/requsetCreator';
// Types
import {AddBrandData, EditBrandData, BrandModel} from './models/brandsModels';

export const addBrandRequest = async (data: AddBrandData): Promise<BrandModel> => {
	const formData = new FormData();

	formData.append('image', data.image);

	return await requestCreator<FormData, BrandModel>(
		'PUT',
		`/dictionary/brand-add?mark=${data.mark}`,
		formData,
		{
			'Content-Type': 'multipart/form-data',
		},
		`Marka '${data.mark}' została dodana!`
	);
};

export const editBrandRequest = async (
	data: EditBrandData
): Promise<BrandModel> => {
	const formData = new FormData();

	formData.append('image', data.image);

	return await requestCreator<FormData, BrandModel>(
		'POST',
		`/dictionary/brand-edit?name=${data.name}&id=${data.id}`,
		formData,
		{
			'Content-Type': 'multipart/form-data',
		},
		`Marka ${data.name} została zedytowana!`
	);
};

export const deleteBrandRequest = async (id: number): Promise<string> =>
	await requestCreator<FormData, string>(
		'DELETE',
		`/dictionary/brand-delete?id=${id}`,
		undefined,
		undefined,
		'Usuwanie marki zakończone sukcesem!'
	);

export const getBrandsRequest = async (): Promise<BrandModel[]> =>
	await requestCreator<undefined, BrandModel[]>('GET', `/dictionary/brand-get`);
