import {useEffect} from 'react';
import {useSelector, useDispatch} from 'react-redux';
// Components
import CardWithSettings from '@components/CardWithSettings/CardWithSettings';
import Container from '@components/Container/Container';
// Api
import {getBrandsRequest} from '@api/brands';
// Redux
import {getBrandsPageBrands} from '@selectors/brandsPageSelectors';
import {
	setBrands,
	setBrandsCurrentPage,
	setBrandsMaxPage,
} from '@actions/brandsPageAction';
// Styles
import styles from './BrandsList.module.scss';
// Types
import {Tag} from '@commonTypes/tags';
import {ModalType} from '@commonTypes/main';

interface BrandsListProps {
	toggleModal: (
		isOpened: boolean,
		modalType: ModalType,
		brandId?: number
	) => void;
}

const BrandsList = (props: BrandsListProps) => {
	const {toggleModal} = props;

	const brands = useSelector(getBrandsPageBrands);

	const dispatch = useDispatch();

	useEffect(() => {
		(async () => {
			const res = await getBrandsRequest();

			dispatch(setBrands(res));
			dispatch(setBrandsCurrentPage(1));
			dispatch(setBrandsMaxPage(1));
		})();
	}, [dispatch]);

	return (
		<Container as={Tag.Section} classes={styles.list}>
			{brands &&
				brands?.map(({name, image, id}) => (
					<CardWithSettings
						key={id}
						name={name}
						imageUrl={image}
						link={`/models?filter=brandId&brandId=${id}`}
						options={[
							{text: 'Edytuj', onClick: () => toggleModal(true, ModalType.EDIT, id)},
							{text: 'Usuń', onClick: () => toggleModal(true, ModalType.DELETE, id)},
						]}
					/>
				))}
		</Container>
	);
};

export default BrandsList;
