export interface AppState {
	loading: boolean;
}

export enum AppStateActions {
	SET_LOADING = 'SET_LOADING',
}

const loadingAction = (loading: boolean) => ({
	type: AppStateActions.SET_LOADING,
	payload: loading,
});

export {loadingAction};
