export const getFilterParams = (
	filters: Record<string, string | number | boolean> | undefined,
	url: string
): string => {
	if (filters) {
		const filterParams = new URLSearchParams();
		Object.entries(filters).forEach(([key, value]) => {
			if (value !== undefined) {
				if (Array.isArray(value)) {
					value.forEach((val) => filterParams.append(key, val.toString()));
				} else {
					filterParams.append(key, value.toString());
				}
			}
		});

		return (url += `&${filterParams.toString()}`);
	}

	return url;
};
