// Types
import {Store} from '@reducers/rootReducer';

export const getModelsPage = (state: Store) => state.modelsPage;

export const getModelsPageModels = (state: Store) =>
	state.modelsPage.models;

export const getModelsPageMaxPage = (state: Store) => state.modelsPage.maxPage;

export const getModelsPageCurrentPage = (state: Store) =>
	state.modelsPage.currentPage;
