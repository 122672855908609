import React, {useEffect, useState} from 'react';
import {useDispatch} from 'react-redux';
import {Navigate, useParams} from 'react-router-dom';
// API
import {getOfferDetailsRequest} from '@api/offer';
// Redux
import {loadingAction} from '@actions/appActions';
import DocumentPdf from '@components/DocumentPdf/DocumentPdf';
import Container from '@components/Container/Container';
import {Tag} from '@commonTypes/tags';
import {clearPreviewOffer, setPreviewOffer} from '@actions/previewOfferActions';

const PreviewOffer = () => {
	const {id} = useParams();

	const [error, setError] = useState<boolean>(false);

	const dispatch = useDispatch();

	useEffect(() => {
		(async () => {
			if (!!!id) return;
			dispatch(loadingAction(true));
			await getOfferDetailsRequest(id)
				.then((res) => dispatch(setPreviewOffer(res)))
				.catch(() => setError(true));
			dispatch(loadingAction(false));
		})();

		return () => {
			dispatch(clearPreviewOffer());
		};
	}, [dispatch, id]);

	if (error) return <Navigate to='/404' replace />;

	return (
		<Container as={Tag.Section}>
			<DocumentPdf />
		</Container>
	);
};

export default PreviewOffer;
