import {TabCategoryModel} from '@api/models/tabs';

export interface TabPropertiesPage {
	tabProperties: TabCategoryModel[] | null;
	currentPage: number;
	maxPage: number;
}

export enum TabPropertiesPageActions {
	SET_TAB_PROPERTIES = 'SET_TAB_PROPERTIES',
	ADD_TAB_PROPERTY = 'ADD_TAB_PROPERTY',
	DELETE_TAB_PROPERTY = 'DELETE_TAB_PROPERTY',
	SET_TABS_CURRENT_PAGE = 'SET_TABS_CURRENT_PAGE',
	SET_TAB_CONFIGURE_MAX_PAGE = 'SET_TAB_CONFIGURE_MAX_PAGE',
}

export const setTabCategories = (properties: TabCategoryModel[]) => ({
	type: TabPropertiesPageActions.SET_TAB_PROPERTIES,
	payload: properties,
});

export const addTabProperties = (properties: TabCategoryModel[]) => ({
	type: TabPropertiesPageActions.ADD_TAB_PROPERTY,
	payload: properties,
});

export const deleteTabCategory = (id: number) => ({
	type: TabPropertiesPageActions.DELETE_TAB_PROPERTY,
	payload: id,
});

export const setTabPropertyCurrentPage = (currentPage: number) => ({
	type: TabPropertiesPageActions.SET_TABS_CURRENT_PAGE,
	payload: currentPage,
});

export const setTabPropertyMaxPage = (maxPage: number) => ({
	type: TabPropertiesPageActions.SET_TAB_CONFIGURE_MAX_PAGE,
	payload: maxPage,
});
