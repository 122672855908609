// Libraries
import {FormEvent, useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {toast} from 'react-toastify';
// Componetns
import Input, {defaultInput} from '@components/_FormElements/Input/Input';
import FileInput from '@components/_FormElements/FileInput/FileInput';
import Button, {ButtonVariant} from '@components/Button/Button';
// Helpers
import {validateForm} from '@helpers/validateForm';
import {getFileFromUrl} from '@helpers/getFileFromUrl';
// Api
import { addBodyRequest, editBodyRequest } from '@api/body';
// Redux
import { getBodyPageBodies } from '@selectors/bodyPageSelectors';
import { addBody, editBody } from '@actions/bodyPageActions';
// Types
import {FormField, FormKeys} from '@commonTypes/main';
// Styles
import styles from './AddForm.module.scss';

interface AddFormProps {
	bodyId?: number;
	closeHandler: () => void;
}

const initialState = {
	name: {...defaultInput, required: true},
	file: {...defaultInput, value: undefined as undefined | File, required: true},
};

const AddForm = (props: AddFormProps) => {
	const {bodyId, closeHandler} = props;

	const dispatch = useDispatch();

	const [loading, setLoading] = useState<boolean>(false);
	const [form, setForm] = useState<FormKeys<typeof initialState>>(initialState);
	const brands = useSelector(getBodyPageBodies);

	const updateFormHandler = (name: string, value: FormField) => {
		setForm({...form, [name]: value});
	};

	const submitHandler = async (e: FormEvent) => {
		e.preventDefault();
		setLoading(true);

		const isError = validateForm(form, setForm);

		if (isError) return setLoading(false);

		if (bodyId === undefined) {
			await addBodyRequest({name: form.name.value, image: form.file.value!}).then(
				(res) => {
					dispatch(addBody(res));
					closeHandler();
				}
			);
		} else {
			if (form.file.value === undefined) return toast.warning('Image not found.');
			await editBodyRequest({
				id: bodyId,
				name: form.name.value,
				image: form.file.value,
			}).then((res) => {
				dispatch(editBody(res));
				closeHandler();
			});
		}

		setLoading(false);
	};

	useEffect(() => {
		if (bodyId === undefined || brands === undefined) return;

		const currentBrand = brands?.find(({id}) => id === bodyId);

		if (!!currentBrand) {
			(async () => {
				const res = await getFileFromUrl(currentBrand.image, currentBrand.name);

				setForm({
					...form,
					name: {...form.name, value: currentBrand.name},
					file: {
						...form.file,
						value: res,
					},
				});
			})();
		} else {
			toast.warning('Brand not found.');
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [bodyId, brands]);

	return (
		<form onSubmit={submitHandler}>
			<Input
				id='name'
				name='name'
				type='text'
				placeholder={'Wpisz nazwę'}
				label={'Nazwa zabudowy'}
				required={form.name.required}
				value={form.name.value}
				errorMessage={form.name.errorMessage}
				valueChangeHandler={updateFormHandler}
			/>
			<FileInput
				id='file'
				name='file'
				placeholder={'+ Dodaj plik'}
				label={'Dodaj zdjęcie zabudowy'}
				accept='image/*'
				required={form.file.required}
				value={form.file.value}
				errorMessage={form.file.errorMessage}
				valueChangeHandler={updateFormHandler}
			/>
			<div className={styles.buttons}>
				<Button
					type='button'
					variant={ButtonVariant.Outline}
					onClick={closeHandler}>
					Anuluj
				</Button>
				<Button type='submit' variant={ButtonVariant.Primary} disabled={loading}>
					Zapisz
				</Button>
			</div>
		</form>
	);
};

export default AddForm;
