import React from 'react'
import {ReactComponent as ErrorSvg} from '@assets/icons/error.svg';
import styles from '../ContactForm.module.scss';

const ErrorStep = () => {
  return (
    <div className={styles.done}>
      <ErrorSvg/>
      <p className={styles.title}>Coś poszło nie tak!</p>
    </div>
  )
}

export default ErrorStep