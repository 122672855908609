import {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
// Components
import {Title, Text} from '@components/_Typography';
import CardWithSettings from '@components/CardWithSettings/CardWithSettings';
import CardsList from '@components/CardsList/CardsList';
// Api
import {getBrandsRequest} from '@api/brands';
// Redux
import {setCommonBrands} from '@actions/commonActions';
import {getCommonBrands} from '@selectors/commonSelectors';
// Types
import {TitleTag, TitleVariant} from '@components/_Typography/Title/Title';
import {FormField} from '@commonTypes/main';
import {Link} from 'react-router-dom';
// Styles
import styles from './VehicleAddFormBrand.module.scss';
import { RouterPaths } from '@commonTypes/routerPaths';

interface VehicleAddFormBrandProps {
	currentBrand: number | undefined;
	updateFormHandler: (name: string, value: FormField) => void;
}

const VehicleAddFormBrand = (props: VehicleAddFormBrandProps) => {
	const {updateFormHandler, currentBrand} = props;

	const brands = useSelector(getCommonBrands);

	const dispatch = useDispatch();

	useEffect(() => {
		(async () => {
			await getBrandsRequest().then((res) => dispatch(setCommonBrands(res)));
		})();
	}, [dispatch]);

	return (
		<div>
			<Title tag={TitleTag.h3} variant={TitleVariant.standard}>
				1. Wybierz markę
			</Title>
			<Text classes={styles.text}>
				Dostępne opcje są globalne. Jeżeli chcesz dodać nową markę przejdź do{' '}
				<Link to={RouterPaths.Brands}>zakładki marki</Link>.
			</Text>
			<CardsList>
				{brands?.map(({name, id, image}) => (
					<CardWithSettings
						key={id}
						name={name}
						imageUrl={image}
						isSelected={currentBrand === id}
						selectHandler={() => {
							updateFormHandler('brand', {
								isValid: true,
								errorMessage: '',
								value: id,
								required: true,
							});
						}}
					/>
				))}
			</CardsList>
		</div>
	);
};

export default VehicleAddFormBrand;
