// Actions
import {OrderedConfigsPageActions} from '@actions/orderedConfigsPageActions';
// Types
import { GetOfferResponse } from "@api/models/offer";
import {OrderedConfigsPage} from '@actions/orderedConfigsPageActions';

const initialState: OrderedConfigsPage = {
	configs: null,
	currentPage: 1,
	maxPage: 1,
};

export const orderedConfigsPageReducer = (
	state = initialState,
	action: {
		type: OrderedConfigsPageActions;
		payload: GetOfferResponse | string | number;
	}
): OrderedConfigsPage => {
	switch (action.type) {
		case OrderedConfigsPageActions.SET_ORDERED_CONFIGS:
			const configsRes = action.payload as GetOfferResponse;
			return {
				...state,
				configs: configsRes.offerList,
				maxPage: configsRes.totalPages,
				currentPage: configsRes.pageIndex,
			};
		case OrderedConfigsPageActions.SET_CURRENT_PAGE:
			return {...state, currentPage: action.payload as number};
		case OrderedConfigsPageActions.SET_MAX_PAGE:
			return {...state, maxPage: action.payload as number};
		default:
			return state;
	}
};
