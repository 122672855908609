// Types
import {Store} from '@reducers/rootReducer';

export const getPropertyVehiclesPage = (state: Store) =>
	state.propertyVehiclesPage;

export const getPropertyVehiclesPageVehicles = (state: Store) =>
	state.propertyVehiclesPage.vehicles;

export const getPropertyAvailableVehiclesPageVehicles = (state: Store) =>
	state.propertyVehiclesPage.availableVehicles;
