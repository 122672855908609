// Types
import {Store} from '@reducers/rootReducer';

export const getVehiclesPage = (state: Store) => state.vehiclesPage;

export const getVehiclesPageVehicles = (state: Store) =>
	state.vehiclesPage.vehicles;

export const getVehiclesPageMaxPage = (state: Store) => state.vehiclesPage.maxPage;

export const getVehiclesPageCurrentPage = (state: Store) =>
	state.vehiclesPage.currentPage;
