import {Tag} from '@commonTypes/tags';
import AppHeader from '@components/AppHeader/AppHeader';
import Container from '@components/Container/Container';
import React, {useMemo, useState} from 'react';
import VehiclesList from './components/VehiclesList/VehiclesList';
import {useNavigate, useParams} from 'react-router-dom';
import styles from './PropertyVehicles.module.scss';
import Button, {ButtonTag, ButtonVariant} from '@components/Button/Button';
import Modal from '@components/Modal/Modal';
import EditForm from './components/EditForm/EditForm';
//Types
import {ModalType} from '@commonTypes/main';
import {PropertyVehicleModel} from '@api/models/propertiesModels';
import DeleteForm from './components/DeleteForm/DeleteForm';
import AddForm from './components/AddForm/AddForm';
import {useSelector} from 'react-redux';
import {getPropertyVehiclesPageVehicles} from '@selectors/propertyVehiclesPageSelectors';

const PropertyVehicles = () => {
	const {id} = useParams()

	const [modalOpened, setModalOpened] = useState<boolean>(false);
	const [modalType, setModalType] = useState<ModalType>(ModalType.ADD);
	const [currentVehicle, setCurrentVehicle] = useState<
		PropertyVehicleModel | undefined
	>(undefined);

	const navigation = useNavigate();
	const vehicles = useSelector(getPropertyVehiclesPageVehicles);

	const toggleModal = (
		isOpened: boolean,
		modalType: ModalType,
		vehicle?: PropertyVehicleModel
	) => {
		if (modalType !== ModalType.ADD && vehicle?.id === undefined)
			throw new Error('You must provide vehicleId!');
		setModalOpened(isOpened);
		setModalType(modalType);
		setCurrentVehicle(vehicle);
	};

	const modalView = useMemo(() => {
		switch (modalType) {
			case ModalType.ADD:
				return <AddForm closeHandler={() => setModalOpened(false)} />;
			case ModalType.EDIT:
				if (!currentVehicle) throw new Error('Modal EDIT require vehicleId');
				return (
					<EditForm
						vehicle={currentVehicle}
						closeHandler={() => setModalOpened(false)}
					/>
				);
			case ModalType.DELETE:
				if (!currentVehicle) throw new Error('Modal DELETE require vehicleId');
				return (
					<DeleteForm
						vehicle={currentVehicle}
						closeHandler={() => setModalOpened(false)}
					/>
				);
			default:
				throw new Error(`Unknown modal type: ${modalType}`);
		}
	}, [modalType, currentVehicle]);

	const modalTitle = useMemo(() => {
		switch (modalType) {
			case ModalType.ADD:
				return 'Dodaj Pojazd dla cechy';
			case ModalType.EDIT:
				return `Edytuj cechę dla pojazdu: #${currentVehicle?.id}`;
			case ModalType.DELETE:
				return 'Rozłącz powiązanie pojazdu z cechą.';
			default:
				throw new Error(`Unknown modal type: ${modalType}`);
		}
	}, [currentVehicle, modalType]);

	return (
		<>
			<AppHeader
				title={`Wersje pojazdów przypisane do cechy: ${vehicles?.[0]?.attributeName ?? `#${id}`}`}
				text='Dodaj, usuń lub dostosuj indywidualne wartości.'
				classes={styles.header}>
				<Button
					classes={styles.headerButton}
					type='button'
					onClick={() => toggleModal(true, ModalType.ADD)}>
					+ Dodaj pojazd
				</Button>
			</AppHeader>
			<Container as={Tag.Section}>
				<VehiclesList toggleModal={toggleModal} />
				<Button
					classes={styles.btn}
					as={ButtonTag.Button}
					variant={ButtonVariant.OutlineWithBorder}
					onClick={() => navigation(-1)}>
					Cofnij
				</Button>
			</Container>
			{modalOpened && (
				<Modal title={modalTitle} closeHandler={() => setModalOpened(false)}>
					{modalView}
				</Modal>
			)}
		</>
	);
};

export default PropertyVehicles;
