// Libraries
import {useCallback, useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {toast} from 'react-toastify';
// Componetns
import {Text} from '@components/_Typography';
import Button, {ButtonVariant} from '@components/Button/Button';
// Redux
import {getBrandsPageBrands} from '@selectors/brandsPageSelectors';
import {deleteBrand} from '@actions/brandsPageAction';
// Api
import {deleteBrandRequest} from '@api/brands';
// Types
import {BrandModel} from '@api/models/brandsModels';
// Styles
import styles from './DeleteForm.module.scss';

interface DeleteFormProps {
	brandId: number;
	closeHandler: () => void;
}

const DeleteForm = (props: DeleteFormProps) => {
	const {brandId, closeHandler} = props;

	const dispatch = useDispatch();

	const [brand, setBrand] = useState<BrandModel | undefined>(undefined);

	const brands = useSelector(getBrandsPageBrands);

	useEffect(() => {
		if (brandId === undefined || brands === undefined) return;
		
		const currentBrand = brands?.find(({id}) => id === brandId);

		if (!!currentBrand) {
			setBrand(currentBrand);
		} else {
			toast.warning('Brand not found.');
			closeHandler();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [brandId]);
	
	const deleteHandler = useCallback(async () => {
		await deleteBrandRequest(brandId).then(() => {
			dispatch(deleteBrand(brandId));
			closeHandler();
		});
	}, [brandId, closeHandler, dispatch]);

	return (
		<div className={styles.wrapper}>
			<Text>
				Usunięcie marki spowoduje usunięcie przypisanych do niej pojazdów. Czy
				chcesz usunąć markę: <strong>{brand?.name}</strong>?
			</Text>
			<img className={styles.image} src={brand?.image} alt={brand?.name} />
			<div className={styles.buttons}>
				<Button
					type='button'
					variant={ButtonVariant.Outline}
					onClick={closeHandler}>
					Anuluj
				</Button>
				<Button
					type='button'
					variant={ButtonVariant.Danger}
					onClick={deleteHandler}>
					Usuń
				</Button>
			</div>
		</div>
	);
};

export default DeleteForm;
