// Components
import Button, {ButtonTag} from '@components/Button/Button';
// Styles
import styles from './Page404.module.scss';
import { RouterPaths } from '@commonTypes/routerPaths';

const Page404 = () => {
	return (
		<main className={styles.wrapper}>
			<h1 className={styles.title}>404</h1>
			<p className={styles.text}>
				Wygląda na to, że strona której szukasz nie istnieje lub została usunięta.
			</p>
			<Button as={ButtonTag.InternalLink} href={RouterPaths.Brands}>
				Strona główna
			</Button>
		</main>
	);
};

export default Page404;
