// Pages
import Login from '@pages/LoginPage/Login';
import Brands from '@pages/BrandsPage/Brands';
import CategoriesPage from '@pages/CategoriesPage/Categories';
import Models from '@pages/ModelsPage/Models';
import Bodies from '@pages/BodiesPage/Bodies';
import Vehicles from '@pages/VehiclesPage/Vehicles';
import VehicleAdd from '@pages/VehicleManagePage/VehicleManage';
import Properties from '@pages/PropertiesPage/Properties';
import PropertyDependencies from '@pages/PropertyDependenciesPage/PropertyDependencies';
import PropertyVehicles from '@pages/PropertyVehiclesPage/PropertyVehicles';
import Page404 from '@pages/404Page/Page404';
import Help from '@pages/HelpPage/Help';
import Tabs from '@pages/TabsPage/Tabs';
import TabConfigure from '@pages/TabConfigurePage/TabConfigure';
import Users from '@pages/UsersPage/Users';
import OrderedConfigs from '@pages/OrderedConfigsPage/OrderedConfigs';
// Types
import {Route} from '@commonTypes/main';
import {RouterPaths} from '@commonTypes/routerPaths';
import PreviewOffer from '@pages/PreviewOfferPage/PreviewOffer';

const routes: Route[] = [
	{
		id: 'loginRoute',
		path: RouterPaths.Login,
		element: <Login />,
		hasLayout: false,
	},
	{
		id: 'brandsRoute',
		path: RouterPaths.Brands,
		element: <Brands />,
		hasLayout: true,
		isProtected: true,
	},
	{
		id: 'categoriesRoute',
		path: RouterPaths.Categories,
		element: <CategoriesPage />,
		hasLayout: true,
		isProtected: true,
	},
	{
		id: 'modelsRoute',
		path: RouterPaths.Models,
		element: <Models />,
		hasLayout: true,
		isProtected: true,
	},
	{
		id: 'bodiesRoute',
		path: RouterPaths.Bodies,
		element: <Bodies />,
		hasLayout: true,
		isProtected: true,
	},
	{
		id: 'vehiclesRoute',
		path: RouterPaths.Vehicles,
		element: <Vehicles />,
		hasLayout: true,
		isProtected: true,
	},
	{
		id: 'vehicleAddRoute',
		path: RouterPaths.VehiclesAdd,
		element: <VehicleAdd />,
		hasLayout: true,
		isProtected: true,
	},
	{
		id: 'propertiesRoute',
		path: RouterPaths.Properties,
		element: <Properties />,
		hasLayout: true,
		isProtected: true,
	},
	{
		id: 'propertyVehiclesRoute',
		path: RouterPaths.PropertiesVehicles,
		element: <PropertyVehicles />,
		hasLayout: true,
		isProtected: true,
	},
	{
		id: 'propertyDependenciesRoute',
		path: RouterPaths.PropertiesDependencies,
		element: <PropertyDependencies />,
		hasLayout: true,
		isProtected: true,
	},
	{
		id: 'helpRoute',
		path: RouterPaths.Help,
		element: <Help />,
		hasLayout: true,
		isProtected: true,
	},
	{
		id: 'tabsRoute',
		path: RouterPaths.Tabs,
		element: <Tabs />,
		hasLayout: true,
		isProtected: true,
	},
	{
		id: 'tabConfigureRoute',
		path: RouterPaths.TabsConfig,
		element: <TabConfigure />,
		hasLayout: true,
		isProtected: true,
	},
	{
		id: 'userRoute',
		path: RouterPaths.Users,
		element: <Users />,
		hasLayout: true,
		isProtected: true,
	},
	{
		id: 'configsRoute',
		path: RouterPaths.Configurations,
		element: <OrderedConfigs />,
		hasLayout: true,
		isProtected: true,
	},
	{
		id: 'pdfRoute',
		path: RouterPaths.ConfigurationsDetails,
		element: <PreviewOffer />,
		hasLayout: true,
		isProtected: true,
	},
	{
		id: '404Route',
		path: RouterPaths.Error,
		element: <Page404 />,
		hasLayout: false,
	},
];

export default routes;
