// Libraries
import {FormEvent, useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {toast} from 'react-toastify';
// Componetns
import Input, {defaultInput} from '@components/_FormElements/Input/Input';
import Button, {ButtonVariant} from '@components/Button/Button';
// Helpers
import {validateForm} from '@helpers/validateForm';
// Api
import {addCategoryRequest, editCategoryRequest} from '@api/categories';
import {getCategoryPageCategories} from '@selectors/categoryPageSelectors';
// Redux
import {addCategory, editCategory} from '@actions/categoryPageActions';
// Types
import {FormField, FormKeys} from '@commonTypes/main';
// Styles
import styles from './AddForm.module.scss';
import Checkbox from '@components/_FormElements/Checkbox/Checkbox';

interface AddFormProps {
	id?: number;
	closeHandler: () => void;
}

const initialState = {
	name: {...defaultInput, required: true},
	isRequiredAttributeFromCategory: {
		...defaultInput,
		required: false,
		value: false,
	},
};

const AddForm = (props: AddFormProps) => {
	const {id, closeHandler} = props;

	const dispatch = useDispatch();

	const [form, setForm] = useState<FormKeys<typeof initialState>>(initialState);
	const categories = useSelector(getCategoryPageCategories);

	const updateFormHandler = (name: string, value: FormField) => {
		setForm({...form, [name]: value});
	};

	const submitHandler = async (e: FormEvent) => {
		e.preventDefault();
		const isError = validateForm(form, setForm);

		if (isError) return;

		const currentCategory = categories?.find((category) => category.id === id);

		if (id === undefined) {
			await addCategoryRequest(
				form.name.value,
				form.isRequiredAttributeFromCategory.value
			).then((res) => {
				dispatch(addCategory(res));
				closeHandler();
			});
		} else {
			await editCategoryRequest(
				{
					id: id,
					name: form.name.value,
					isDeleted: currentCategory?.isDeleted ?? false,
					isRequiredAttributeFromCategory: form.isRequiredAttributeFromCategory.value
				},
			).then((res) => {
				dispatch(editCategory(res));
				closeHandler();
			});
		}
	};

	useEffect(() => {
		if (id === undefined || categories === undefined) return;

		const currentCategory = categories?.find((category) => category.id === id);

		if (!!currentCategory) {
			(async () => {
				setForm({
					...form,
					name: {...form.name, value: currentCategory.name},
					isRequiredAttributeFromCategory: {...form.name, value: currentCategory.isRequiredAttributeFromCategory},
				});
			})();
		} else {
			toast.warning('Brand not found.');
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [id, categories]);

	return (
		<form onSubmit={submitHandler}>
			<Input
				id='name'
				name='name'
				type='text'
				placeholder={'Wpisz nazwę'}
				label={'Nazwa kategorii'}
				required={form.name.required}
				value={form.name.value}
				errorMessage={form.name.errorMessage}
				valueChangeHandler={updateFormHandler}
			/>
			<Checkbox
				id='isRequiredAttributeFromCategory'
				name='isRequiredAttributeFromCategory'
				label='Kategoria zawiera cechę standardu'
				required={false}
				value={form.isRequiredAttributeFromCategory.value}
				valueChangeHandler={updateFormHandler}
				toggler
			/>
			<div className={styles.buttons}>
				<Button
					type='button'
					variant={ButtonVariant.Outline}
					onClick={closeHandler}>
					Anuluj
				</Button>
				<Button type='submit' variant={ButtonVariant.Primary}>
					Zapisz
				</Button>
			</div>
		</form>
	);
};

export default AddForm;
