import {CategoryModel, GetCategoriesResponse} from '@api/models/categoryModels';

export interface CategoryPage {
	categories: CategoryModel[] | null;
	currentPage: number;
	maxPage: number;
}

export enum CategoryPageActions {
	ADD_CATEGORY = 'ADD_CATEGORY',
	SET_CATEGORIES = 'SET_CATEGORIES',
	SET_CATEGORIES_LIST = 'SET_CATEGORIES_LIST',
	EDIT_CAREGORY = 'EDIT_CAREGORY',
	DELETE_CAREGORY = 'DELETE_CAREGORY',
	SET_CATEGORY_CURRENT_PAGE = 'SET_CATEGORY_CURRENT_PAGE',
	SET_CATEGORY_MAX_PAGE = 'SET_CATEGORY_MAX_PAGE',
}

const setCategoriesList = (categories: CategoryModel[]) => ({
	type: CategoryPageActions.SET_CATEGORIES_LIST,
	payload: categories,
});

const setCategoriesPage = (categories: GetCategoriesResponse) => ({
	type: CategoryPageActions.SET_CATEGORIES,
	payload: categories,
});

const addCategory = (categories: CategoryModel) => ({
	type: CategoryPageActions.ADD_CATEGORY,
	payload: categories,
});

const editCategory = (category: CategoryModel) => ({
	type: CategoryPageActions.EDIT_CAREGORY,
	payload: category,
});

const deleteCategory = (id: number) => ({
	type: CategoryPageActions.DELETE_CAREGORY,
	payload: id,
});

const setCategoriesCurrentPage = (currentPage: number) => ({
	type: CategoryPageActions.SET_CATEGORY_CURRENT_PAGE,
	payload: currentPage,
});

const setCategoriesMaxPage = (maxPage: number) => ({
	type: CategoryPageActions.SET_CATEGORY_MAX_PAGE,
	payload: maxPage,
});

export {
	addCategory,
	setCategoriesList,
	setCategoriesPage,
	editCategory,
	deleteCategory,
	setCategoriesCurrentPage,
	setCategoriesMaxPage,
};
