// Libraries
import {useCallback, useEffect, useState} from 'react';
import {toast} from 'react-toastify';
// Componetns
import {Text} from '@components/_Typography';
import Button, {ButtonVariant} from '@components/Button/Button';
// Api
import {deleteCabinRequest} from '@api/cabins';
// Types
import {CabinModel} from '@api/models/cabinsModels';
// Styles
import styles from './DeleteForm.module.scss';

interface DeleteFormProps {
	cabinId: number;
	cabins: CabinModel[] | undefined;
	setCabins: React.Dispatch<React.SetStateAction<CabinModel[]>>;
	closeHandler: () => void;
}

const DeleteForm = (props: DeleteFormProps) => {
	const {cabinId, cabins, closeHandler, setCabins} = props;

	const [cabin, setCabin] = useState<CabinModel | undefined>(undefined);

	useEffect(() => {
		if (cabinId === undefined || cabins === undefined) return;

		const currentCabin = cabins?.find(({id}) => id === cabinId);

		if (!!currentCabin) {
			setCabin(currentCabin);
		} else {
			toast.warning('Cabin not found.');
			closeHandler();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [cabinId]);

	const deleteHandler = useCallback(async () => {
		await deleteCabinRequest(cabinId).then(() => {
			if (!!!cabins) {
				toast.warning('Cabins not found.');
				return;
			}

			setCabins(cabins.filter((item) => cabin?.id !== item.id) as CabinModel[]);
			setCabin(undefined);
			closeHandler();
		});
	}, [cabin, cabinId, cabins, closeHandler, setCabins]);

	return (
		<div className={styles.wrapper}>
			<Text>
				Usunięcie kabiny spowoduje usunięcie przypisanych do niej pojazdów. Czy
				chcesz usunąć kabinę: <strong>{cabin?.name}</strong>?
			</Text>
			<img className={styles.image} src={cabin?.image} alt={cabin?.name} />
			<div className={styles.buttons}>
				<Button
					type='button'
					variant={ButtonVariant.Outline}
					onClick={closeHandler}>
					Anuluj
				</Button>
				<Button
					type='button'
					variant={ButtonVariant.Danger}
					onClick={deleteHandler}>
					Usuń
				</Button>
			</div>
		</div>
	);
};

export default DeleteForm;
