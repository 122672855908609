// Types
import {Store} from '@reducers/rootReducer';

export const getUsersPageUsers = (state: Store) => state.usersPage.users;

export const getUsersPageMaxPage = (state: Store) =>
	state.usersPage.maxPage;

export const getUsersPageCurrentPage = (state: Store) =>
	state.usersPage.currentPage;
