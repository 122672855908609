// Actions
import {TabPropertiesPageActions} from '@actions/tabsConfigurePageActions';
// Types
import {TabCategoryModel} from '@api/models/tabs';
import {TabPropertiesPage} from '@actions/tabsConfigurePageActions';

const initialState: TabPropertiesPage = {
	tabProperties: null,
	currentPage: 1,
	maxPage: 1,
};

export const tabsPropertiesPageReducer = (
	state = initialState,
	action: {
		type: TabPropertiesPageActions;
		payload: TabCategoryModel[] | TabCategoryModel | number;
	}
): TabPropertiesPage => {
	switch (action.type) {
		case TabPropertiesPageActions.SET_TAB_PROPERTIES:
			return {...state, tabProperties: action.payload as TabCategoryModel[]};
		case TabPropertiesPageActions.ADD_TAB_PROPERTY:
			return {
				...state,
				tabProperties: [...(state.tabProperties ?? []), ...(action.payload ?? []) as TabCategoryModel[]],
			};
		case TabPropertiesPageActions.DELETE_TAB_PROPERTY:
			return {
				...state,
				tabProperties: state.tabProperties?.filter(
					(item) => item.id !== action.payload
				) as TabCategoryModel[],
			};
		case TabPropertiesPageActions.SET_TABS_CURRENT_PAGE:
			return {...state, currentPage: action.payload as number};
		case TabPropertiesPageActions.SET_TAB_CONFIGURE_MAX_PAGE:
			return {...state, maxPage: action.payload as number};
		default:
			return state;
	}
};
