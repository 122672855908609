import React, {useCallback, useEffect, useState} from 'react';
import DragDropList from '@components/DragDropList/DragDropList';
import {ElementProps} from '@components/DragDropList/Element/Element';
import {toast} from 'react-toastify';
import {
	getDependentPropertiesRequest,
	getExcludedPropertiesRequest,
	getPropertiesRequest,
	setRelationshipsPropertiesRequest,
} from '@api/properties';
import {useNavigate, useParams} from 'react-router-dom';
import Button, {ButtonTag, ButtonVariant} from '@components/Button/Button';
import AppHeader from '@components/AppHeader/AppHeader';
import Container from '@components/Container/Container';
import {Tag} from '@commonTypes/tags';
import styles from './PropertyDependencies.module.scss';
import {ReactComponent as Conntect} from '@assets/icons/connect.svg';
import {ReactComponent as Disconntect} from '@assets/icons/disconnect.svg';

const PropertyDependencies = () => {
	const {id} = useParams();

	const navigate = useNavigate();

	const [currentListId, setCurrentListId] = useState<undefined | string>(
		undefined
	);
	const [newListId, setNewListId] = useState<undefined | string>(undefined);
	const [currentProperty, setCurrentProperty] = useState<ElementProps>();
	const [properties, setProperties] = useState<ElementProps[]>([]);
	const [connected, setConnected] = useState<ElementProps[]>([]);
	const [excluded, setExcluded] = useState<ElementProps[]>([]);

	const startDraggingHandler = (id: string) => setCurrentListId(id);
	const dragEndHandler = (id: string) => setNewListId(id);

	const dropHandler = useCallback(
		(item: ElementProps) => {
			if (newListId !== currentListId && newListId !== undefined) {
				switch (currentListId) {
					case 'properties':
						setProperties(properties.filter(({id}) => id !== item.id));
						break;
					case 'connected':
						setConnected(connected.filter(({id}) => id !== item.id));
						break;
					case 'excluded':
						setExcluded(excluded.filter(({id}) => id !== item.id));
						break;
					default:
						toast.warning('Unexpected behavior.');
						break;
				}
				switch (newListId) {
					case 'properties':
						setProperties([...properties, item]);
						break;
					case 'connected':
						setConnected([...connected, item]);
						break;
					case 'excluded':
						setExcluded([...excluded, item]);
						break;
					default:
						toast.warning('Unexpected behavior.');
						break;
				}
			}
			setCurrentListId(undefined);
			setNewListId(undefined);
		},
		[properties, connected, excluded, currentListId, newListId]
	);

	useEffect(() => {
		if (!!!id) {
			toast.error('Unknown ID.');
			return;
		}
		(async () => {
			const [props, dependentProps, excludedProps] = await Promise.all([
				getPropertiesRequest(false, 1, 99999),
				getDependentPropertiesRequest(id),
				getExcludedPropertiesRequest(id),
			]);
			setCurrentProperty(props.attributes.find(prop => prop.id === +id))
			setProperties(
				props.attributes
					.filter(
						(property) =>
							property.id !== +id &&
							!dependentProps.find(
								(item) => item.dependentAttributteId === property.id
							) &&
							!excludedProps.find((item) => item.dependentAttributteId === property.id)
					)
					?.map(({id, imageUrl, name}) => ({id, imageUrl, name}))
			);
			setConnected(
				dependentProps?.map(
					({dependentAttributteId, imageUrl, dependentAttributeName}) =>
						({
							id: dependentAttributteId,
							imageUrl,
							name: dependentAttributeName,
						} as ElementProps)
				)
			);
			setExcluded(
				excludedProps?.map(
					({dependentAttributteId, imageUrl, dependentAttributeName}) =>
						({
							id: dependentAttributteId,
							imageUrl,
							name: dependentAttributeName,
						} as ElementProps)
				)
			);
		})();
	}, [id]);

	const submitHandler = useCallback(async () => {
		if (!id) {
			return toast.error('Unknown property id.');
		}

		await setRelationshipsPropertiesRequest({
			attributeId: +id,
			dependentAttributeIds: connected.map(({id}) => +id as number),
			excludedAttributeIds: excluded.map(({id}) => +id as number),
		}).then(() => {
			toast.success('Zależności zostały dodane.');
			navigate('/properties');
		});
	}, [id, connected, excluded, navigate]);

	return (
		<>
			<AppHeader
				title={`Zarządzanie zależnościami cechy: ${currentProperty?.name}`}
				text='Utwórz zależności cech.'
			/>
			<Container as={Tag.Section}>
				<div className={styles.lists}>
					<DragDropList
						id='properties'
						items={properties}
						dragEndHandler={dragEndHandler}
						startDraggingHandler={startDraggingHandler}
						dropHandler={dropHandler}
					/>
					<DragDropList
						id='connected'
						title='Połączone'
						items={connected}
						icon={<Conntect />}
						headerBg='rgba(82, 177, 65, 0.2)'
						headerColor='rgba(82, 177, 65, 1)'
						dragEndHandler={dragEndHandler}
						startDraggingHandler={startDraggingHandler}
						dropHandler={dropHandler}
					/>
					<DragDropList
						id='excluded'
						title='Rozłączone'
						items={excluded}
						icon={<Disconntect />}
						headerBg='rgba(255, 92, 0, 0.1)'
						headerColor='rgba(255, 92, 0, 1)'
						dragEndHandler={dragEndHandler}
						startDraggingHandler={startDraggingHandler}
						dropHandler={dropHandler}
					/>
				</div>
				<div className={styles.buttons}>
					<Button
						type='button'
						variant={ButtonVariant.Outline}
						as={ButtonTag.InternalLink}
						href='/properties/'>
						Anuluj
					</Button>
					<Button type='button' onClick={submitHandler}>
						Zapisz
					</Button>
				</div>
			</Container>
		</>
	);
};

export default PropertyDependencies;
