// Liobraries
import {FormEvent, useState} from 'react';
// Components
import Container from '@components/Container/Container';
import {Title} from '@components/_Typography';
import Button from '@components/Button/Button';
import Logo from '@components/Logo/Logo';
import Input, {defaultInput} from '@components/_FormElements/Input/Input';
// Types
import {Tag} from '@commonTypes/tags';
import {TitleTag, TitleVariant} from '@components/_Typography/Title/Title';
// API
import {login} from '@api/auth';
// Types
import {FormField, FormKeys} from '@commonTypes/main';
// Styles
import styles from './Login.module.scss';
import {validateForm} from '@helpers/validateForm';
import {toast} from 'react-toastify';
import {useNavigate} from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { loadingAction } from '@actions/appActions';

const initialState = {
	email: {...defaultInput, required: true},
	userPassword: {...defaultInput, required: true},
};

const Login = () => {
	const [form, setForm] = useState<FormKeys<typeof initialState>>(initialState);

	const navigate = useNavigate();
	const dispatch = useDispatch();

	const updateFormHandler = (name: string, value: FormField) => {
		setForm({...form, [name]: value});
	};

	const submitHandler = (e: FormEvent) => {
		e.preventDefault();
		const isError = validateForm(form, setForm);

		if (isError) return toast.warning('Popraw formularz.');

		dispatch(loadingAction(true))
		
		login({email: form.email.value, password: form.userPassword.value}).then(
			(res) => {
				localStorage.setItem('jwttoken', res);
				setTimeout(() => {
					navigate('/');
				}, 500);
			}
		);
		dispatch(loadingAction(false))
	};

	return (
		<Container as={Tag.Main} classes={styles.wrapper}>
			<section className={styles.formWrapper}>
				<Logo />
				<Title
					tag={TitleTag.h1}
					variant={TitleVariant.section}
					classes={styles.title}>
					Zaloguj się
				</Title>
				<form onSubmit={submitHandler}>
					<Input
						id='email'
						name='email'
						type='email'
						placeholder={'Wpisz swój e-mail'}
						label={'E-mail'}
						required={true}
						value={form.email.value}
						errorMessage={form.email.errorMessage}
						valueChangeHandler={updateFormHandler}
					/>
					<Input
						id='userPassword'
						name='userPassword'
						type='password'
						label={'Hasło'}
						required={true}
						value={form.userPassword.value}
						valueChangeHandler={updateFormHandler}
					/>
					<Button classes={styles.submitBtn} fullWidth type='submit'>
						Zaloguj się
					</Button>
				</form>
			</section>
		</Container>
	);
};

export default Login;
