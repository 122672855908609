// Libraries
import {useSelector} from 'react-redux';
// Container
import Container from '@components/Container/Container';
// Types
import {Tag} from '@commonTypes/tags';
// Assets
import userProfile from '@assets/images/default-profile.png'
// Styles
import styles from './Topbar.module.scss';

const Topbar = () => {
	const email = useSelector((state: any) => state.auth?.user?.email);

	return (
		<Container as={Tag.Aside} classes={styles.wrapper}>
			<div className={styles.profile}>
				<p className={styles.profileText}><span>Witaj, <strong>Admin</strong></span></p>
				<div className={styles.profileImage}>
					<img src={userProfile} alt={email} />
				</div>
				<span>{email}</span>
			</div>
		</Container>
	);
};

export default Topbar;
