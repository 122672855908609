// Libraries
import {useEffect, useMemo, useRef, useState} from 'react';
// Assets
import {ReactComponent as Arrow} from '@assets/icons/arrow.svg';
// Types
import {FormField} from '@commonTypes/main';
// Styles
import styles from './Select.module.scss';
import classNames from 'classnames';

type Value = any;

interface Option<T> {
	image?: string;
	label: string;
	value: T;
}

interface SelectProps<T extends Value> {
	classes?: string;
	defaultOption: Option<T>;
	errorMessage?: string;
	id: string;
	isValid?: boolean;
	label: string;
	name: string;
	options?: Option<T>[];
	required: boolean;
	value: T | T[];
	valueChangeHandler: (name: string, value: FormField) => void;
	multiselect?: boolean;
	zIndex?: number
}

const Select = <T extends Value>(props: SelectProps<T>) => {
	const {
		options,
		value,
		defaultOption,
		valueChangeHandler,
		name,
		required,
		errorMessage,
		label,
		multiselect,
		zIndex
	} = props;

	const [isOpen, setIsOpen] = useState(false);

	const selectRef = useRef<HTMLDivElement>(null);

	const currentOption = useMemo(
		() =>
			multiselect
				? defaultOption
				: options?.find((option) => value === option.value) ?? defaultOption,
		[multiselect, defaultOption, options, value]
	);

	const onSelect = (selectedValue: T) => {
		if (multiselect && Array.isArray(value)) {
			const isSelected = !!value.find((selected) => selected === selectedValue);
			isSelected
				? valueChangeHandler(name, {
						value: value.filter((option) => option !== selectedValue),
						errorMessage: '',
						isValid: true,
						required,
				  })
				: valueChangeHandler(name, {
						value: [...value, selectedValue],
						errorMessage: '',
						isValid: true,
						required,
				  });
		} else {
			valueChangeHandler(name, {
				value: selectedValue,
				errorMessage: '',
				isValid: true,
				required,
			});
		}
	};

	useEffect(() => {
		const handleOutsideClick = (event: MouseEvent) => {
			if (selectRef.current && !selectRef.current.contains(event.target as Node)) {
				setIsOpen(false);
			}
		};

		document.body.addEventListener('click', handleOutsideClick);

		return () => {
			document.body.removeEventListener('click', handleOutsideClick);
		};
	}, []);

	return (
		<div className={classNames(styles.wrapper, {[styles.wrapperMulti]: multiselect})} data-error={!!errorMessage} ref={selectRef}>
			{!!label && (
				<span className={styles.label}>
					{label}
					{required && ' *'}
				</span>
			)}
			<div style={{zIndex}} className={styles.field}>
				<div
					className={styles.input}
					data-opend={isOpen}
					onClick={() => setIsOpen(!isOpen)}>
					<span className={styles.inputText}>{currentOption?.label}</span>
					<div>
						<Arrow />
					</div>
				</div>
				<div className={styles.optionsList}>
					<div className={styles.optionsListWrapper}>
						{defaultOption && (
							<div
								data-default='true'
								className={styles.option}
								key={`${defaultOption.value}`}>
								<span>{!!!options?.length ? 'Brak opcji' : defaultOption.label}</span>
							</div>
						)}
						{options?.map((option) => (
							<div
								data-current={
									multiselect && Array.isArray(value)
										? !!value?.find((selectedOption) => selectedOption === option.value)
										: currentOption.value === option.value
								}
								className={styles.option}
								key={`${option.value}`}
								onClick={() => {
									onSelect(option.value);
									!multiselect && setIsOpen(false);
								}}>
								<span>{option.label}</span>
							</div>
						))}
					</div>
				</div>
			</div>
			<span className={styles.error}>{errorMessage}</span>
		</div>
	);
};

export default Select;
