import { BrandModel } from "@api/models/brandsModels";

export interface Common {
	brands: BrandModel[] | null;
}

export enum CommonActions {
  SET_BRANDS = "SET_BRANDS",
}

export const setCommonBrands =  (brands: BrandModel[]) => ({
	type: CommonActions.SET_BRANDS,
	payload: brands,
});
