export const getFileFromUrl = async (
	url: string,
	name: string,
	defaultType: string | undefined = 'image/jpeg'
): Promise<File | undefined> => {
	if (!!!url) return undefined;
	const response = await fetch(url);
	const data = await response.blob();
	return new File([data], name, {
		type: data.type || defaultType,
	});
};
