import {useState, useMemo} from 'react';
// Components
import AppHeader from '@components/AppHeader/AppHeader';
import Button from '@components/Button/Button';
import Modal from '@components/Modal/Modal';
import AddForm from './components/AddForm/AddForm';
import DeleteForm from './components/DeleteForm/DeleteForm';
import BodiesList from './components/BodiesList/BodiesList';
// Styles
import styles from './Bodies.module.scss';
//Types
import {ModalType} from '@commonTypes/main';

const Bodies = () => {
	const [modalOpened, setModalOpened] = useState(false);
	const [modalType, setModalType] = useState<ModalType>(ModalType.ADD);
	const [currentBrand, setCurrentBrand] = useState<number | undefined>();

	const toggleModal = (
		isOpened: boolean,
		modalType: ModalType,
		brandId?: number
	) => {
		if (modalType !== ModalType.ADD && brandId === undefined)
			throw new Error('You must provide brandId!');
		setModalOpened(isOpened);
		setModalType(modalType);
		setCurrentBrand(brandId);
	};

	const modalView = useMemo(() => {
		switch (modalType) {
			case ModalType.ADD:
				return <AddForm closeHandler={() => setModalOpened(false)} />;
			case ModalType.EDIT:
				if (!currentBrand) throw new Error('Modal EDIT require bodyId');
				return (
					<AddForm
						bodyId={currentBrand}
						closeHandler={() => setModalOpened(false)}
					/>
				);
			case ModalType.DELETE:
				if (!currentBrand) throw new Error('Modal DELETE require bodyId');
				return (
					<DeleteForm
						bodyId={currentBrand}
						closeHandler={() => setModalOpened(false)}
					/>
				);
			default:
				throw new Error(`Unknown modal type: ${modalType}`);
		}
	}, [modalType, currentBrand]);

	const modalTitle = useMemo(() => {
		switch (modalType) {
			case ModalType.ADD:
				return 'Dodaj zabudowę';
			case ModalType.EDIT:
				return 'Edytuj zabudowę';
			case ModalType.DELETE:
				return 'Usuń zabudowę';
			default:
				throw new Error(`Unknown modal type: ${modalType}`);
		}
	}, [modalType]);

	return (
		<>
			<AppHeader
				title='Zarządzanie zabudowami'
				text='Konfiguruj dowolne zabudowy'
				classes={styles.header}>
				<Button
					classes={styles.headerButton}
					type='button'
					onClick={() => toggleModal(true, ModalType.ADD)}>
					+ Dodaj zabudowę
				</Button>
			</AppHeader>
			<BodiesList toggleModal={toggleModal} />
			{modalOpened && (
				<Modal title={modalTitle} closeHandler={() => setModalOpened(false)}>
					{modalView}
				</Modal>
			)}
		</>
	);
};

export default Bodies;
