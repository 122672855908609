// Components
import AppHeader from '@components/AppHeader/AppHeader';
import Container from '@components/Container/Container';
import Accordion from '@components/FAQ/Accordion';
import ContactForm from '@components/ContactForm/ContactForm';
// Types
import {Tag} from '@commonTypes/tags';
// Styles
import styles from './Help.module.scss';

const Help = () => {
	return (
		<>
			<AppHeader
				title='Potrzebujesz pomocy?'
				text='Skorzystaj z dostępnych treści lub skontaktuj się z administratorem za pomocą formularza.'
			/>
			<Container as={Tag.Section} classes={styles.wrapper}>
				<div className={styles.faq}>
					<Accordion
						elements={[
							{
								id: '1',
								title: 'Marki',
								description:
									'Strona zarządzania markami pojazdów służy do kompleksowego zarządzania markami pojazdów dostępnych w systemie. Dzięki niej administratorzy mają możliwość dodawania nowych marek, edytowania istniejących oraz usuwania tych, które nie są już potrzebne. W przypadku usnięcia marki nastąpi usunięcie wszystkich elementów przypisanych do danej marki.',
							},
							{
								id: '2',
								title: 'Modele',
								description:
									'Strona zarządzania modelami pojazdów w panelu administracyjnym służy do kompleksowego zarządzania modelami pojazdów dostępnych w systemie. Administratorzy mogą dodawać nowe modele, edytować istniejące oraz usuwać te, które nie są już potrzebne. W przypadku usnięcia modelu nastąpi usunięcie wszystkich elementów przypisanych do danego modelu.',
							},
							{
								id: '3',
								title: 'Standardy',
								description: (
									<>
										Aby zdefiniować standardy pojazdu należy utworzyć kategorię cech
										(zakładka 'Kategorie cech') z zaznaczoną opcją 'Kategoria zawiera
										cechę standardu'.
										<br />
										<br />
										Następnie należy utworzyć cechy i przypisać je do uprzednio utworzonej
										kategorii.
										<br />
										<br />
										Od tego momentu klient będzie zobligowany do dodania cechy z danej kategorii.
									</>
								),
							},
						]}
					/>
				</div>
				<ContactForm />
			</Container>
		</>
	);
};

export default Help;
