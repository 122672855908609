// Actions
import {TabsPageActions} from '@actions/tabsPageActions';
// Types
import {TabModel} from '@api/models/tabs';
import {TabsPage} from '@actions/tabsPageActions';

const initialState: TabsPage = {
	tabs: null,
	currentPage: 1,
	maxPage: 1,
};

export const tabsPageReducer = (
	state = initialState,
	action: {type: TabsPageActions; payload: TabModel[] | TabModel | number}
): TabsPage => {
	switch (action.type) {
		case TabsPageActions.SET_TABS:
			return {...state, tabs: action.payload as TabModel[]};
		case TabsPageActions.ADD_TAB:
			return {
				...state,
				tabs: [...(state.tabs ?? []), action.payload as TabModel],
			};
		case TabsPageActions.EDIT_TAB:
			const editedTab = action.payload as TabModel;
			return {
				...state,
				tabs: state.tabs?.map((item) =>
					item.id === editedTab.id ? {...item, ...editedTab} : item
				) as TabModel[],
			};
		case TabsPageActions.DELETE_TAB:
			return {
				...state,
				tabs: state.tabs?.filter(
					(item) => item.id !== action.payload
				) as TabModel[],
			};
		case TabsPageActions.SET_TABS_PAGE_CURRENT_PAGE:
			return {...state, currentPage: action.payload as number};
		case TabsPageActions.SET_TAB_MAX_PAGE:
			return {...state, maxPage: action.payload as number};
		default:
			return state;
	}
};
