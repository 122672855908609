// Libraries
import {useCallback, useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {toast} from 'react-toastify';
// Componetns
import {Text} from '@components/_Typography';
import Button, {ButtonVariant} from '@components/Button/Button';
// Redux
import { getCategoryPageCategories } from '@selectors/categoryPageSelectors';
import { deleteCategory } from '@actions/categoryPageActions';
// Api
import { deleteCategoryRequest } from '@api/categories';
// Types
import { CategoryModel } from '@api/models/categoryModels';
// Styles
import styles from './DeleteForm.module.scss';

interface DeleteFormProps {
	id: number;
	closeHandler: () => void;
}

const DeleteForm = (props: DeleteFormProps) => {
	const {id, closeHandler} = props;

	const dispatch = useDispatch();

	const [category, setCategory] = useState<CategoryModel | undefined>(undefined);

	const categories = useSelector(getCategoryPageCategories);

	useEffect(() => {
		if (id === undefined || categories === undefined) return;
		
		const currentCategory = categories?.find((category) => category.id === id);

		if (!!currentCategory) {
			setCategory(currentCategory);
		} else {
			toast.warning('Category not found.');
			closeHandler();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [id]);
	
	const deleteHandler = useCallback(async () => {
		await deleteCategoryRequest(id).then(() => {
			dispatch(deleteCategory(id));
			closeHandler();
		});
	}, [id, closeHandler, dispatch]);

	return (
		<div className={styles.wrapper}>
			<Text>
				Usunięcie kategorii spowoduje usunięcie przypisanych do niej cech. Czy
				chcesz usunąć kategorię: <strong>{category?.name}</strong>?
			</Text>
			<div className={styles.buttons}>
				<Button
					type='button'
					variant={ButtonVariant.Outline}
					onClick={closeHandler}>
					Anuluj
				</Button>
				<Button
					type='button'
					variant={ButtonVariant.Danger}
					onClick={deleteHandler}>
					Usuń
				</Button>
			</div>
		</div>
	);
};

export default DeleteForm;
