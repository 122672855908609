// Libraries
import {FormEvent, useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {toast} from 'react-toastify';
// Componetns
import Select from '@components/_FormElements/Select/Select';
import Input, {defaultInput} from '@components/_FormElements/Input/Input';
import Button, {ButtonVariant} from '@components/Button/Button';
// Helpers
import {validateForm} from '@helpers/validateForm';
// Api
import {addModelRequest, editModelRequest} from '@api/models';
// Redux
import {addModel, editModel} from '@actions/modelsPageActions';
import {getModelsPageModels} from '@selectors/modelsPageSelectors';
// Types
import {FormField, FormKeys} from '@commonTypes/main';
// Styles
import styles from './AddForm.module.scss';
import {getCommonBrands} from '@selectors/commonSelectors';
import {getBrandsRequest} from '@api/brands';
import {setCommonBrands} from '@actions/commonActions';

interface AddFormProps {
	id?: number;
	closeHandler: () => void;
}

const initialState = {
	name: {...defaultInput, required: true},
	brand: {
		...defaultInput,
		required: true,
		value: undefined as undefined | number,
	},
};

const AddForm = (props: AddFormProps) => {
	const {id, closeHandler} = props;

	const dispatch = useDispatch();

	const [form, setForm] = useState<FormKeys<typeof initialState>>(initialState);

	const models = useSelector(getModelsPageModels);
	const brands = useSelector(getCommonBrands);

	const updateFormHandler = (name: string, value: FormField) => {
		setForm({...form, [name]: value});
	};

	const submitHandler = async (e: FormEvent) => {
		e.preventDefault();
		const isError = validateForm(form, setForm);

		if (isError) return;

		const currentModel = models?.find((model) => model.id === id);

		if (id === undefined) {
			if (!!!form.brand.value) return toast.warning('Brand unknown.');

			await addModelRequest({
				name: form.name.value,
				brandId: form.brand.value,
			}).then((res) => {
				dispatch(addModel(res));
				closeHandler();
			});
		} else {
			if (!!!currentModel) return toast.warning('Model not found.');

			await editModelRequest({
				id: id,
				name: form.name.value,
				brandId: currentModel.brandId,
			}).then((res) => {
				dispatch(editModel(res));
				closeHandler();
			});
		}
	};

	useEffect(() => {
		if (!!!brands?.length) {
			(async () =>
				await getBrandsRequest().then((res) => dispatch(setCommonBrands(res))))();
		}
		if (id === undefined || models === undefined) return;

		const currentModel = models?.find((category) => category.id === id);

		if (!!currentModel) {
			(async () => {
				setForm({
					...form,
					name: {...form.name, value: currentModel.name},
					brand: {...form.brand, value: currentModel.brandId},
				});
			})();
		} else {
			toast.warning('Model not found.');
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [id, models]);

	return (
		<form onSubmit={submitHandler}>
			{id === undefined && (
				<Select<number | undefined>
					id='brand'
					name='brand'
					label='Marka'
					value={form.brand.value}
					errorMessage={form.brand.errorMessage}
					valueChangeHandler={updateFormHandler}
					defaultOption={{value: 0, label: 'Wybierz markę'}}
					options={brands?.map((brand) => ({
						value: brand.id,
						image: brand.image,
						label: brand.name,
					}))}
					required
				/>
			)}
			<Input
				id='name'
				name='name'
				type='text'
				placeholder={'Wpisz nazwę'}
				label={'Nazwa modelu'}
				required={form.name.required}
				value={form.name.value}
				errorMessage={form.name.errorMessage}
				valueChangeHandler={updateFormHandler}
			/>
			<div className={styles.buttons}>
				<Button
					type='button'
					variant={ButtonVariant.Outline}
					onClick={closeHandler}>
					Anuluj
				</Button>
				<Button type='submit' variant={ButtonVariant.Primary}>
					Zapisz
				</Button>
			</div>
		</form>
	);
};

export default AddForm;
