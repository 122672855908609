// Components
import Table from '@components/Table/Table';
import Container from '@components/Container/Container';
// Types
import {Tag} from '@commonTypes/tags';
import {useSelector} from 'react-redux';
import {useMemo} from 'react';
import {
	getOrderedConfigs,
	getOrderedConfigsMaxPage,
} from '@selectors/orderedConfigsPageSelectors';
import Button, {ButtonTag, ButtonVariant} from '@components/Button/Button';
import Pagination from '@components/Pagination/Pagination';
import {getOffersRequest} from '@api/offer';
import {RouterPaths} from '@commonTypes/routerPaths';

const OrderedConfigs = () => {
	const configs = useSelector(getOrderedConfigs);

	const tableElements = useMemo(
		() =>
			configs?.map((item) => {
				return {
					id: item.id,
					offerName: item.offerName,
					name: item.vechicle,
					date: new Date(item.createDate).toLocaleString(),
					edit: (
						<Button
							as={ButtonTag.InternalLink}
							variant={ButtonVariant.Outline}
							href={`${RouterPaths.Configurations}/${item.id}`}>
							PDF
						</Button>
					),
				};
			}),
		[configs]
	);

	return (
		<Container as={Tag.Section}>
			<Table
				headers={[
					{text: 'ID', sortName: 'id'},
					{text: 'Nazwa', sortName: 'offerName'},
					{text: 'Pojazd', sortName: 'name'},
					{text: 'Data', sortName: 'name'},
					{text: 'Otwórz'},
				]}
				elements={tableElements ?? []}
				groups={[
					{width: '10%'},
					{width: '25%', minWidth: 125},
					{width: '25%', minWidth: 225},
					{width: '30%', minWidth: 170},
					{width: '10%'},
				]}
			/>
			<Pagination
				request={getOffersRequest}
				getMaxPages={getOrderedConfigsMaxPage}
			/>
		</Container>
	);
};

export default OrderedConfigs;
