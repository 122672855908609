// Types
import {Store} from '@reducers/rootReducer';

export const getBodyPage = (state: Store) => state.bodiesPage;

export const getBodyPageBodies = (state: Store) => state.bodiesPage.bodies;

export const getBodyPageMaxPage = (state: Store) => state.bodiesPage.maxPage;

export const getBodyPageCurrentPage = (state: Store) =>
	state.bodiesPage.currentPage;
