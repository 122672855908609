// Types
import {Store} from '@reducers/rootReducer';

export const getCategoryPage = (state: Store) => state.categoryPage;

export const getCategoryPageCategories = (state: Store) => state.categoryPage.categories;

export const getCategoryPageMaxPage = (state: Store) => state.categoryPage.maxPage;

export const getCategoryPageCurrentPage = (state: Store) =>
	state.categoryPage.currentPage;
