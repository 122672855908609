import { UserDetailsModel } from "@api/models/authModels";


export enum AuthActions {
  LOG_IN = "LOG_IN",
  LOG_OUT = "LOG_OUT",
}

const logInAction = (userData: UserDetailsModel) => ({type: AuthActions.LOG_IN, payload: userData}) 

const logOutAction = (expiredToken?: boolean) => ({type: AuthActions.LOG_OUT, payload: expiredToken}) 

export {logInAction, logOutAction}