import {useEffect, useMemo} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useParams} from 'react-router-dom';
import {toast} from 'react-toastify';
import classNames from 'classnames';
// Components
import Table from '@components/Table/Table';
import Container from '@components/Container/Container';
import Button, {ButtonVariant} from '@components/Button/Button';
// Api
import {getPropertyVehiclesRequest} from '@api/properties';
// Redux
import {getPropertyVehiclesPageVehicles} from '@selectors/propertyVehiclesPageSelectors';
import {setPropertyVehicles} from '@actions/propertyVehiclesPageActions';
// Types
import {Tag} from '@commonTypes/tags';
// Assets
import {ModalType} from '@commonTypes/main';
import {PropertyVehicleModel} from '@api/models/propertiesModels';
interface VehiclesListProps {
	toggleModal: (
		isOpened: boolean,
		modalType: ModalType,
		vehicle?: PropertyVehicleModel
	) => void;
}

const VehiclesList = (props: VehiclesListProps) => {
	const {toggleModal} = props;

	const {id} = useParams();

	const dispatch = useDispatch();

	const vehicles = useSelector(getPropertyVehiclesPageVehicles);

	useEffect(() => {
		(async () => {
			if (!!!id) return toast.error('Unknown id.');
			await getPropertyVehiclesRequest(+id).then((res) =>
				dispatch(setPropertyVehicles(res))
			);
		})();
	}, [dispatch, id]);

	const tableElements = useMemo(
		() =>
			vehicles?.map((item) => {
				return {
					id: item.id,
					brand: item.mark,
					model: item.model,
					body: item.bodyType,
					cabin: item.cabinType,
					chassis: item.chassis,
					price: item.price
						? `${item.price} ${item.isEuroPrice ? '€' : 'zł'}`
						: 'Domyślna',
					weight: item.weight ?? 'Domyślna',
					image: !!item.imageUrl ? (
						<img
							className={classNames({'empty-image': !!!item.imageUrl})}
							src={item.imageUrl}
							alt={id}
						/>
					) : (
						'Domyślne'
					),
					edit: (
						<Button
							type='button'
							onClick={() => {
								toggleModal(true, ModalType.EDIT, item);
							}}
							variant={ButtonVariant.Outline}>
							Edytuj
						</Button>
					),
					disconnect: (
						<Button
							type='button'
							onClick={() => {
								toggleModal(true, ModalType.DELETE, item);
							}}
							variant={ButtonVariant.Outline}>
							Rozłącz
						</Button>
					),
				};
			}),
		[id, toggleModal, vehicles]
	);

	return (
		<Container as={Tag.Section}>
			<Table
				headers={[
					{text: 'ID'},
					{text: 'Marka'},
					{text: 'Model'},
					{text: 'Zabudowa'},
					{text: 'Kabina'},
					{text: 'Podwozie'},
					{text: 'Cena'},
					{text: 'Waga (kg)'},
					{text: 'Zdjęcie'},
					{text: 'Edytuj'},
					{text: 'Rozłącz'},
				]}
				elements={tableElements ?? []}
				groups={[
					{width: '5%'},
					{width: '10%'},
					{width: '10%'},
					{width: '10%', minWidth: 150},
					{width: '10%'},
					{width: '10%'},
					{width: '10%'},
					{width: '10%', minWidth: 110},
					{width: '10%'},
					{width: '10%'},
					{width: '5%'},
				]}
			/>
		</Container>
	);
};

export default VehiclesList;
