// Components
import Table from '@components/Table/Table';
import Container from '@components/Container/Container';
import {ReactComponent as Trash} from '@assets/icons/trash.svg';
// Types
import {Tag} from '@commonTypes/tags';
import { useSelector} from 'react-redux';
import { useMemo} from 'react';
import Button, {ButtonVariant} from '@components/Button/Button';
import {getUsersPageMaxPage, getUsersPageUsers} from '@selectors/usersPageSelectors';
import {getUsers} from '@api/auth';
import {ModalType} from '@commonTypes/main';
import {UserModel} from '@api/models/authModels';
import Pagination from '@components/Pagination/Pagination';

interface UsersListProps {
	toggleModal: (
		isOpened: boolean,
		modalType: ModalType,
		user?: UserModel
	) => void;
}

const UsersList = (props: UsersListProps) => {
	const {toggleModal} = props;

	const users = useSelector(getUsersPageUsers);

	const tableElements = useMemo(
		() =>
			users?.map((item) => {
				return {
					id: item.id,
					name: `${item.firstName} ${item.lastName}`,
					email: item.login,
					brand: item.brand,
					resetPassword: item.askForPasswordReset && (
						<Button
							type='button'
							onClick={() => {
								toggleModal(true, ModalType.EDIT, item);
							}}
							variant={ButtonVariant.Outline}>
							Zatwierdź
						</Button>
					),
					edit: !item.askForPasswordReset && (
						<Button
							type='button'
							onClick={() => {
								toggleModal(true, ModalType.ADD, item);
							}}
							variant={ButtonVariant.Outline}>
							Aktywuj
						</Button>
					),
					delete: !item.askForPasswordReset && (
						<button
							type='button'
							className='button-delete'
							onClick={() => {
								toggleModal(true, ModalType.DELETE, item);
							}}>
							<Trash />
						</button>
					),
				};
			}),
		[toggleModal, users]
	);

	return (
		<Container as={Tag.Section}>
			<Table
				headers={[
					{text: 'ID', sortName: 'id'},
					{text: 'Nazwa użytkownika', sortName: 'name'},
					{text: 'E-mail', sortName: 'email'},
					{text: 'Marka', sortName: 'brand'},
					{text: 'Reset hasła'},
					{text: 'Aktywuj'},
					{text: 'Odrzuć'},
				]}
				elements={tableElements ?? []}
				groups={[
					{width: '25%', minWidth: 320},
					{width: '30%', minWidth: 225},
					{width: '20%', minWidth: 150},
					{width: '10%', minWidth: 125},
					{width: '5%', minWidth: 120},
					{width: '5%'},
					{width: '5%'},
				]}
			/>
			<Pagination request={getUsers} getMaxPages={getUsersPageMaxPage}/>
		</Container>
	);
};

export default UsersList;
