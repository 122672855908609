// Helpers
import {FormKeys} from '@commonTypes/main';
import validation from './validation';

export const validateForm = (
	form: FormKeys<any>,
	updateForm: (value: FormKeys<any>) => void
): boolean => {
	const keys = Object.keys(form);

	let newForm = form;

	const errors = keys.map((key) => {
		const errorMessage = validation(
			true,
			form[key].value,
			key,
			form[key].required
		);

		newForm[key] = {...form[key], errorMessage};

		return !!errorMessage;
	});

	updateForm({...form, ...newForm});

	return errors.find((isError) => isError) ?? false;
};
