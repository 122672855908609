// Helpers
import {requestCreator} from '@helpers/requsetCreator';
// Types
import {
	ModelModel,
	AddModelRequsetData,
	EditModelRequsetData,
	GetModelsRequestResponse,
} from './models/modelModels';
import {Dispatch} from 'react';
import {UnknownAction} from '@reduxjs/toolkit';
import {setModels} from '@actions/modelsPageActions';

export const addModelRequest = async (
	data: AddModelRequsetData
): Promise<ModelModel> =>
	await requestCreator<FormData, ModelModel>(
		'PUT',
		`/vechicle/model-add?brandId=${data.brandId}&name=${data.name}`,
		undefined,
		undefined,
		`Model '${data.name}' został dodany!`
	);

export const editModelRequest = async (
	data: EditModelRequsetData
): Promise<ModelModel> =>
	await requestCreator<FormData, ModelModel>(
		'POST',
		`/vechicle/model-edit?name=${data.name}&modelId=${data.id}&brandId=${data.brandId}`,
		undefined,
		undefined,
		`Model ${data.name} został zedytowany!`
	);

export const deleteModelRequest = async (id: number): Promise<string> =>
	await requestCreator<FormData, string>(
		'DELETE',
		`/vechicle/model-delete?modelId=${id}`,
		undefined,
		undefined,
		'Usuwanie modelu zakończone sukcesem!'
	);

export const getModelsRequest = async (
	dispatch: Dispatch<UnknownAction>,
	pageIndex: number,
	pageSize: number,
	filters?: {brandId?: string}
): Promise<GetModelsRequestResponse> => {
	const res = await requestCreator<undefined, GetModelsRequestResponse>(
		'GET',
		`/vechicle/models?pageIndex=${pageIndex}&pageSize=${pageSize}${
			filters?.brandId ? `&brandId=${filters.brandId}` : ''
		}`
	);

	dispatch(setModels(res));

	return res;
};
