import React, {useEffect, useState} from 'react';
// Components
import Button from '@components/Button/Button';
// Types
import {Text} from '@components/_Typography';
import {UserModel} from '@api/models/authModels';
// Styles
import styles from './ResetPasswordForm.module.scss';
import {confirmResetPassword} from '@api/auth';

interface ResetPasswordFormProps {
	user: UserModel;
	closeHandler: () => void;
}

const ResetPasswordForm = (props: ResetPasswordFormProps) => {
	const {user, closeHandler} = props;

	const [link, setLink] = useState<string>('');

	useEffect(() => {
		(async () => {
			await confirmResetPassword(user.id).then((res) => {
				setLink(res);
			});
		})();
	}, [user.id]);

	return (
		<>
			<Text>
				Aby umożliwić użytkownikowi{' '}
				<strong>
					{user.firstName} {user.lastName}
				</strong>{' '}
				reset hasła, prześlij wiadomość na email <strong>{user.login}</strong> z
				treścią: <br />
				<br />
				Otrzymaliśmy Twoją prośbę o zresetowanie hasła. Możesz to zrobić korzystając
				z linku:{' '}
				<span className={styles.link}>
					https://
					{process.env.REACT_APP_ENV === 'production' ? 'client' : 'clientstage'}
					.gruau.pl/setup-new-password?id={link}
				</span>
			</Text>
			<div className={styles.buttons}>
				<Button type='button' onClick={closeHandler}>
					Zamknij
				</Button>
			</div>
		</>
	);
};

export default ResetPasswordForm;
