// Types
import {Store} from '@reducers/rootReducer';

export const getPropertiesPage = (state: Store) => state.propertiesPage;

export const getPropertiesPageProperties = (state: Store) =>
	state.propertiesPage.properties;

export const getPropertiesPageMaxPage = (state: Store) =>
	state.propertiesPage.maxPage;

export const getPropertiesPageCurrentPage = (state: Store) =>
	state.propertiesPage.currentPage;
