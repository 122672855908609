export interface Common {
	id?: string;
	classes?: string;
	children?: any;
}

export enum FontColors {
	light = 'light',
	dark = 'dark',
	yellow = 'yellow',
	green = 'green',
	danger = 'danger',
}

export interface Route {
	id: string;
	path: string;
	element: React.ReactNode;
	hasLayout: boolean
	isProtected?: boolean
}

export interface FormField {
	value: any
	errorMessage: string
	required: boolean
	isValid?: boolean
}

export type FormKeys<FormField extends Record<string, any>> = {
	[K in keyof FormField]: FormField[K];
};

export enum ModalType {
	ADD,
	EDIT,
	DELETE,
}