// Helpers
import {requestCreator} from '@helpers/requsetCreator';
// Types
import {LoginData, GetUsersResponse} from './models/authModels';
import { Dispatch } from 'react';
import { UnknownAction } from '@reduxjs/toolkit';
import { setUsers } from '@actions/usersPageActions';

export const login = async (data: LoginData): Promise<string> =>
	await requestCreator<LoginData, string>('POST', '/auth/login-admin', data);

export const getUsers = async (
	dispatch: Dispatch<UnknownAction>,
	pageIndex: number,
	pageSize: number
): Promise<GetUsersResponse> => {
	const res = await requestCreator<undefined, GetUsersResponse>(
		'GET',
		`/auth/useres-need-attention?pageIndex=${pageIndex}&pageSize=${pageSize}`
	);

	dispatch(setUsers(res))

	return res;
};

export const confirmUser = async (id: string): Promise<string> =>
	await requestCreator<undefined, string>(
		'POST',
		`/auth/confirm?id=${id}`,
		undefined,
		undefined,
		'Konto zostało aktywowane.'
	);

export const rejectUser = async (id: string): Promise<string> =>
	await requestCreator<undefined, string>(
		'POST',
		`/auth/reject?id=${id}`,
		undefined,
		undefined,
		'Konto zostało odrzucone.'
	);

export const confirmResetPassword = async (userId: string): Promise<string> =>
	await requestCreator<undefined, string>(
		'POST',
		`/auth/confirm-reset-password?userId=${userId}`
	);
