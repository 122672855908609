// Actions
import {CategoryModel, GetCategoriesResponse} from '@api/models/categoryModels';
import {CategoryPageActions} from '@actions/categoryPageActions';
// Types
import {CategoryPage} from '@actions/categoryPageActions';

const initialState: CategoryPage = {
	categories: null,
	currentPage: 1,
	maxPage: 1,
};

export const categoryPageReducer = (
	state = initialState,
	action: {
		type: CategoryPageActions;
		payload: GetCategoriesResponse | CategoryModel[] | CategoryModel | number;
	}
): CategoryPage => {
	switch (action.type) {
		case CategoryPageActions.ADD_CATEGORY:
			return {
				...state,
				categories: [...(state.categories ?? []), action.payload as CategoryModel],
			};
		case CategoryPageActions.SET_CATEGORIES_LIST:
			return {...state, categories: action.payload as CategoryModel[]};
		case CategoryPageActions.SET_CATEGORIES:
			const data = action.payload as GetCategoriesResponse
			return {...state, categories: data.list, maxPage: data.totalPages, currentPage: data.pageIndex};
		case CategoryPageActions.EDIT_CAREGORY:
			const editedBrand = action.payload as CategoryModel;
			return {
				...state,
				categories: state.categories?.map((item) =>
					item.id === editedBrand.id ? {...item, ...editedBrand} : item
				) as CategoryModel[],
			};
		case CategoryPageActions.DELETE_CAREGORY:
			return {
				...state,
				categories: state.categories?.filter(
					(item) => item.id !== action.payload
				) as CategoryModel[],
			};
		case CategoryPageActions.SET_CATEGORY_CURRENT_PAGE:
			return {...state, currentPage: action.payload as number};
		case CategoryPageActions.SET_CATEGORY_MAX_PAGE:
			return {...state, maxPage: action.payload as number};
		default:
			return state;
	}
};
