// Libraries
import {useCallback} from 'react';
// Components
import Options, {Option} from './Options/Options';
// Types
import {Common} from '@commonTypes/main';
// Styles
import styles from './CardWithSettings.module.scss';
import {useNavigate} from 'react-router-dom';
import classNames from 'classnames';

interface CardWithSettingsProps extends Common {
	name: string;
	link?: string;
	imageUrl: string;
	isSelected?: boolean;
	selectHandler?: () => void;
	options?: Option[];
}

const CardWithSettings = (props: CardWithSettingsProps) => {
	const {name, imageUrl, options, link, isSelected, selectHandler} = props;

	const navigate = useNavigate();

	const navigateHandler = useCallback(() => {
		if(!!selectHandler){
			selectHandler()
		}else{
			!!link && navigate(link);
		}
	}, [link, navigate, selectHandler]);

	return (
		<div
			onClick={navigateHandler}
			className={classNames(styles.wrapper, {[styles.wrapperLink]: !!link})}
			data-is-clickable={!!selectHandler}
			data-is-selected={isSelected}>
			{!!options?.length && <Options options={options} />}
			<div className={styles.image}>
				<img src={imageUrl} alt={name} draggable={false} />
			</div>
			<p className={styles.name}>{name}</p>
		</div>
	);
};

export default CardWithSettings;
