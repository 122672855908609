// Libraries
import {FormEvent, useCallback, useEffect, useMemo, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useParams} from 'react-router-dom';
import {toast} from 'react-toastify';
// Componetns
import Table from '@components/Table/Table';
import Button, {ButtonVariant} from '@components/Button/Button';
// API
import {
	addTabCategoriesRequest,
	getTabAvailableCategoriesRequest,
} from '@api/tabs';
// Redux
import {getCategoryPageCategories} from '@selectors/categoryPageSelectors';
import {addTabProperties} from '@actions/tabsConfigurePageActions';
import { setCategoriesList } from '@actions/categoryPageActions';
import {getTabPropertiesPageTabCategories} from '@selectors/tabsPropertiesPageSelectors copy';
// Types
import {FormField} from '@commonTypes/main';
// Styles
import styles from './AddForm.module.scss';

interface AddFormProps {
	closeHandler: () => void;
}

const AddForm = (props: AddFormProps) => {
	const {closeHandler} = props;

	const {id} = useParams();

	const [tabProperties, setTabPoperties] = useState<number[]>([]);
	const categories = useSelector(getCategoryPageCategories);
	const currentTabCategories = useSelector(getTabPropertiesPageTabCategories);

	const dispatch = useDispatch();

	const updateCheckboxesHandler = useCallback(
		(name: string, value: FormField) => {
			value.value
				? setTabPoperties([...tabProperties, +name])
				: setTabPoperties(tabProperties.filter((item) => item !== +name));
		},
		[tabProperties]
	);

	const submitHandler = async (e: FormEvent) => {
		e.preventDefault();

		if (!!!id) return toast.warning('Unknown id.');
		if (!!!tabProperties.length)
			return toast.warning('You have to add attribute.');

		await addTabCategoriesRequest(
			+id,
			tabProperties.filter(
				(property) =>
					!!!currentTabCategories?.find((item) => +item.categoryId === property)
			)
		).then((res) => {
			dispatch(addTabProperties(res));
			setTabPoperties([]);
			closeHandler();
		});
	};

	useEffect(() => {
		(async () => {
			id &&
				(await getTabAvailableCategoriesRequest(+id).then((res) =>
					dispatch(setCategoriesList(res))
				));
		})();
	}, [dispatch, id]);

	const tableElements = useMemo(
		() =>
			categories?.map((item) => {
				return {
					id: item.id,
					name: item.name,
					category: item.name,
					checkbox: {
						id: item.id,
						name: item.id,
						errorMessage: '',
						value: !!tabProperties.find((id) => id === item.id),
						valueChangeHandler: updateCheckboxesHandler,
					},
				};
			}),
		[categories, tabProperties, updateCheckboxesHandler]
	);

	return (
		<form onSubmit={submitHandler}>
			<Table
				headers={[{text: ''}, {text: 'ID'}, {text: 'Nazwa'}, {text: 'Kategoria'}]}
				elements={tableElements ?? []}
				groups={[{width: '5%'}, {width: '20%'}, {width: '15%'}, {width: '5%'}]}
				isRadio
			/>

			<div className={styles.buttons}>
				<Button
					type='button'
					variant={ButtonVariant.Outline}
					onClick={closeHandler}>
					Anuluj
				</Button>
				<Button type='submit' variant={ButtonVariant.Primary}>
					Zapisz
				</Button>
			</div>
		</form>
	);
};

export default AddForm;
