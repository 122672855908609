// Actions
import {
	PropertyAvailableVehicleModel,
	PropertyVehicleModel,
} from '@api/models/propertiesModels';
import {
	PropertyVehiclesPage,
	PropertyVehiclesPageActions,
} from '@actions/propertyVehiclesPageActions';

const initialState: PropertyVehiclesPage = {
	vehicles: null,
	availableVehicles: null,
	currentPage: 1,
	maxPage: 1,
};

export const propertyVehiclesPageReducer = (
	state = initialState,
	action: {
		type: PropertyVehiclesPageActions;
		payload:
			| PropertyVehicleModel[]
			| PropertyVehicleModel
			| PropertyAvailableVehicleModel[]
			| number;
	}
): PropertyVehiclesPage => {
	switch (action.type) {
		case PropertyVehiclesPageActions.SET_PROPERTY_VEHICLES:
			return {...state, vehicles: action.payload as PropertyVehicleModel[]};
		case PropertyVehiclesPageActions.SET_PROPERTY_AVAILABLE_VEHICLES:
			return {...state, availableVehicles: action.payload as PropertyAvailableVehicleModel[]};
		case PropertyVehiclesPageActions.EDIT_PROPERTY_VEHICLES:
			const editedVehicle = action.payload as PropertyVehicleModel;
			console.log(editedVehicle);
			return {
				...state,
				vehicles: state.vehicles?.map((item) =>
					item.id === editedVehicle.id ? {...item, ...editedVehicle} : item
				) as PropertyVehicleModel[],
			};
		case PropertyVehiclesPageActions.DELETE_PROPERTY_VEHICLES:
			return {
				...state,
				vehicles: state.vehicles?.filter(
					(item) => item.id !== action.payload
				) as PropertyVehicleModel[],
			};
		default:
			return state;
	}
};
