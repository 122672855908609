import {useEffect, useState} from 'react';
// Components
import {Title, Text} from '@components/_Typography';
import CardWithSettings from '@components/CardWithSettings/CardWithSettings';
import CardsList from '@components/CardsList/CardsList';
// Api
import {getBodiesRequset} from '@api/body';
// Types
import {TitleTag, TitleVariant} from '@components/_Typography/Title/Title';
import {FormField} from '@commonTypes/main';
import {BodyModel} from '@api/models/bodyModels';
import {Link} from 'react-router-dom';
// Styles
import styles from './VehicleAddFormBody.module.scss';

interface VehicleAddFormBodyProps {
	currentBody: number | undefined;
	updateFormHandler: (name: string, value: FormField) => void;
}

const VehicleAddFormBody = (props: VehicleAddFormBodyProps) => {
	const {updateFormHandler, currentBody} = props;
	const [bodies, setBodies] = useState<BodyModel[]>();

	useEffect(() => {
		(async () => {
			await getBodiesRequset().then((res) => setBodies(res));
		})();
	}, []);

	return (
		<div>
			<Title tag={TitleTag.h3} variant={TitleVariant.standard}>
				3. Wybierz Zabudowę
			</Title>
			<Text classes={styles.text}>
				Dostępne typy zabudów są globalne. Jeżeli chcesz dodać nową zabudowę przejdź
				do <Link to='/bodies'>zakładki zabudowy</Link>.
			</Text>
			<CardsList>
				{bodies?.map(({name, id, image}) => (
					<CardWithSettings
						key={id}
						name={name}
						imageUrl={image}
						isSelected={currentBody === id}
						selectHandler={() => {
							updateFormHandler('body', {
								isValid: true,
								errorMessage: '',
								value: id,
								required: true,
							});
						}}
					/>
				))}
			</CardsList>
		</div>
	);
};

export default VehicleAddFormBody;
