import {BrowserRouter, Routes, Route, Navigate} from 'react-router-dom';
import {ToastContainer} from 'react-toastify';
// Components
import AppLayout from '@components/Layout/AppLayout';
import routes from './routes';
// Styles
import '@styles/main.scss';
import 'react-toastify/dist/ReactToastify.css';
import {useDispatch} from 'react-redux';
import {useEffect} from 'react';
import {RouterPaths} from '@commonTypes/routerPaths';

function App() {
	const screen = (hasLayout: boolean, element: React.ReactNode) =>
		!!hasLayout ? <AppLayout>{element}</AppLayout> : <>{element}</>;

	return (
		<>
			<BrowserRouter>
				<Routes>
					{routes.map(({isProtected, path, element, id, hasLayout}) => (
						<Route
							key={id}
							path={path}
							element={
								isProtected ? (
									<ProtectedRoute element={screen(hasLayout, element)} />
								) : (
									screen(hasLayout, element)
								)
							}
						/>
					))}
				</Routes>
			</BrowserRouter>
			<div className='toast'>
				<ToastContainer
					position='bottom-right'
					autoClose={2500}
					hideProgressBar={false}
					newestOnTop={false}
					closeOnClick
					rtl={false}
					pauseOnFocusLoss
					pauseOnHover
					theme='light'
				/>
			</div>
		</>
	);
}

const ProtectedRoute = (props: {element: React.ReactNode}): JSX.Element => {
	const dispatch = useDispatch();

	const isAuthenticated = !!localStorage.getItem('jwttoken');

	useEffect(() => {
		if (!!localStorage.getItem('jwttoken')) return;
	}, [dispatch]);

	return isAuthenticated ? (
		<>{props.element}</>
	) : (
		<Navigate to={RouterPaths.Login} replace />
	);
};

export default App;
