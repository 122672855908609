import React, {FormEvent} from 'react';
import {Title} from '@components/_Typography';
import {TitleTag, TitleVariant} from '@components/_Typography/Title/Title';
import Input from '@components/_FormElements/Input/Input';
import Select from '@components/_FormElements/Select/Select';
import Button from '@components/Button/Button';
import { initialState} from '../ContactForm';
import {FormField, FormKeys} from '@commonTypes/main';

interface FormProps {
	updateFormHandler: (name: string, value: FormField) => void;
	form: FormKeys<typeof initialState>;
	submitHandler: (e: FormEvent) => void;
}

const Form = (props: FormProps) => {
	const {updateFormHandler, form, submitHandler} = props;

	return (
		<>
			<Title tag={TitleTag.h1} variant={TitleVariant.section}>
				Skontaktuj się z administracją
			</Title>
			<form onSubmit={submitHandler}>
				<Select<string | undefined>
					id='type'
					name='type'
					label='Typ zgłoszenia'
					value={form.type.value}
					errorMessage={form.type.errorMessage}
					valueChangeHandler={updateFormHandler}
					defaultOption={{value: '0', label: 'Wybierz typ zgłoszenia'}}
					options={[
						{value: 'bug', label: 'Błąd'},
						{value: 'feature', label: 'Usprawnienie'},
						{value: 'new feature', label: 'Nowa funkcjonalność'},
						{value: 'other', label: 'Inne'},
					]}
					required
				/>
				<Input
					id='description'
					name='description'
					as='textarea'
					placeholder={'Wpisz treść wiadomości'}
					label={'Wiadomość'}
					required={form.description.required}
					value={form.description.value}
					errorMessage={form.description.errorMessage}
					valueChangeHandler={updateFormHandler}
				/>
				<Button type='submit'>Wyślij</Button>
			</form>
		</>
	);
};

export default Form;
