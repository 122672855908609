// Components
import Table from '@components/Table/Table';
import Container from '@components/Container/Container';
import {ReactComponent as Trash} from '@assets/icons/trash.svg';
// Types
import {Tag} from '@commonTypes/tags';
import {getCategoriesRequest} from '@api/categories';
import {useSelector} from 'react-redux';
import {useMemo} from 'react';
import {
	getCategoryPageCategories,
	getCategoryPageMaxPage,
} from '@selectors/categoryPageSelectors';
import Button, {ButtonVariant} from '@components/Button/Button';
import {ModalType} from '@commonTypes/main';
import Pagination from '@components/Pagination/Pagination';

interface CategoriesListProps {
	toggleModal: (isOpened: boolean, modalType: ModalType, id?: number) => void;
}

const CategoriesList = (props: CategoriesListProps) => {
	const {toggleModal} = props;

	const categories = useSelector(getCategoryPageCategories);

	const tableElements = useMemo(
		() =>
			categories?.map((item) => {
				return {
					id: item.id,
					name: item.name,
					standard: <p style={{textAlign: 'center'}}>{item.isRequiredAttributeFromCategory ? 'Tak' : 'Nie'}</p>,
					edit: (
						<Button
							type='button'
							onClick={() => {
								toggleModal(true, ModalType.EDIT, item.id);
							}}
							variant={ButtonVariant.Outline}
							disabled={item.id === -1}>
							Edytuj
						</Button>
					),
					delete: (
						<button
							type='button'
							className='button-delete'
							onClick={() => {
								toggleModal(true, ModalType.DELETE, item.id);
							}}
							disabled={item.id === -1}>
							<Trash />
						</button>
					),
				};
			}),
		[categories, toggleModal]
	);

	return (
		<Container as={Tag.Section}>
			<Table
				headers={[
					{text: 'ID', sortName: 'id'},
					{text: 'Kategoria', sortName: 'name'},
					{text: 'Zawiera Standard', sortName: 'standard'},
					{text: 'Edytuj'},
					{text: 'Usuń'},
				]}
				elements={tableElements ?? []}
				groups={[
					{width: '10%'},
					{width: '55%', minWidth: 225},
					{width: '15%', minWidth: 160},
					{width: '15%'},
					{width: '5%'},
				]}
			/>
			<Pagination
				request={getCategoriesRequest}
				getMaxPages={getCategoryPageMaxPage}
			/>
		</Container>
	);
};

export default CategoriesList;
