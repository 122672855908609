import React from 'react'
import {ReactComponent as SuccessSvg} from '@assets/icons/success.svg';
import styles from '../ContactForm.module.scss';

const SuccessStep = () => {
  return (
    <div className={styles.done}>
      <SuccessSvg/>
      <p className={styles.title}>Wiadomość wysłana</p>
    </div>
  )
}

export default SuccessStep