import React from 'react';
// Components
import AppHeader from '@components/AppHeader/AppHeader';
import VehicleAddForm from './components/VehicleAddForm/VehicleAddForm';
import Container from '@components/Container/Container';
// Types
import {Tag} from '@commonTypes/tags';
import {Link} from 'react-router-dom';

const VehicleAdd = () => {
	return (
		<>
			<AppHeader
				title='Skonfiguruj pojazd'
				text={
					<>
						Aby skonfigurować pojazd musisz uzupełnić wszystkie kroki. Po
						zatwierdzeniu wszystkich zmian pojazd zostanie dodany do{' '}
						<Link to='/vehicles'>listy pojazdów</Link>.
					</>
				}
			/>
			<Container as={Tag.Section}>
				<VehicleAddForm />
			</Container>
		</>
	);
};

export default VehicleAdd;
