import React, {FormEvent, useEffect, useState} from 'react';
import Input, {defaultInput} from '@components/_FormElements/Input/Input';
import styles from './EditForm.module.scss';
import FileInput from '@components/_FormElements/FileInput/FileInput';
import Button, {ButtonVariant} from '@components/Button/Button';
import {useDispatch} from 'react-redux';
import {FormField, FormKeys} from '@commonTypes/main';
import {PropertyVehicleModel} from '@api/models/propertiesModels';
import {getFileFromUrl} from '@helpers/getFileFromUrl';
import {validateForm} from '@helpers/validateForm';
import {editPropertyVehiclesRequest} from '@api/properties';
import {editPropertyVehicle} from '@actions/propertyVehiclesPageActions';

interface EditFormProps {
	vehicle: PropertyVehicleModel;
	closeHandler: () => void;
}

const initialState = {
	weight: {...defaultInput, required: false},
	price: {...defaultInput, required: false},
	file: {...defaultInput, value: undefined as undefined | File, required: false},
};

const EditForm = (props: EditFormProps) => {
	const {closeHandler, vehicle} = props;

	const [form, setForm] = useState<FormKeys<typeof initialState>>(initialState);

	const dispatch = useDispatch();

	const updateFormHandler = (name: string, value: FormField) => {
		setForm({...form, [name]: value});
	};

	useEffect(() => {
		(async () => {
			const res = await getFileFromUrl(vehicle.imageUrl, vehicle.model);

			setForm({
				...form,
				weight: vehicle.weight
					? {...form.weight, value: vehicle.weight}
					: form.weight,
				price: vehicle.price ? {...form.price, value: vehicle.price} : form.price,
				file: {...form.file, value: res},
			});
		})();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [vehicle]);

	const submitHandler = async (e: FormEvent) => {
		e.preventDefault();

		const isError = validateForm(form, setForm);

		if (isError) return;

		await editPropertyVehiclesRequest({
			id: vehicle.id,
			weight: form.weight.value,
			price: form.price.value,
			image: form.file.value,
		}).then((res) => {
			dispatch(editPropertyVehicle(res[0]));
			closeHandler();
		});
	};

	return (
		<>
			<form onSubmit={submitHandler}>
				<div className={styles.metrics}>
					<Input
						id='price'
						name='price'
						type='text'
						placeholder={'Wpisz cenę'}
						label={'Cena'}
						required={form.price.required}
						value={form.price.value}
						errorMessage={form.price.errorMessage}
						valueChangeHandler={updateFormHandler}
					/>
					<Input
						id='weight'
						name='weight'
						type='text'
						placeholder={'Wpisz wagę'}
						label={'Waga'}
						required={form.weight.required}
						value={form.weight.value}
						errorMessage={form.weight.errorMessage}
						valueChangeHandler={updateFormHandler}
					/>
				</div>
				<FileInput
					id='file'
					name='file'
					placeholder={'+ Dodaj plik'}
					label={'Dodaj zdjęcie'}
					accept='image/*'
					required={form.file.required}
					value={form.file.value}
					errorMessage={form.file.errorMessage}
					valueChangeHandler={updateFormHandler}
				/>
				<div className={styles.buttons}>
					<Button
						type='button'
						variant={ButtonVariant.Outline}
						onClick={closeHandler}>
						Anuluj
					</Button>
					<Button type='submit' variant={ButtonVariant.Primary}>
						Zapisz
					</Button>
				</div>
			</form>
		</>
	);
};

export default EditForm;
