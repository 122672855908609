import React from 'react';
// Components
import Element, {ElementProps} from './Element/Element';
// Styles
import styles from './DragDropList.module.scss';

interface DragDropListProps {
	id: string;
	title?: string;
	icon?: React.ReactNode;
	items: ElementProps[];
	headerBg?: string;
	headerColor?: string;
	startDraggingHandler: (id: string) => void;
	dragEndHandler: (id: string) => void;
	dropHandler: (item: ElementProps) => void;
}

const DragDropList = (props: DragDropListProps) => {
	const {
		title,
		icon,
		items,
		id,
		headerBg,
		headerColor,
		startDraggingHandler,
		dropHandler,
		dragEndHandler,
	} = props;

	return (
		<div
			id={id}
			onDragEnter={() => dragEndHandler(id)}
			className={styles.wrapper}>
			{!!title && (
				<h3
					className={styles.header}
					style={{backgroundColor: headerBg, color: headerColor}}>
					{icon && <span>{icon}</span>}
					{title}
				</h3>
			)}
			<ul className={styles.list}>
				{items.map((item) => (
					<Element
						key={item.id}
						{...item}
						startDraggingHandler={() => startDraggingHandler(id)}
						dropHandler={() => dropHandler(item)}
					/>
				))}
			</ul>
		</div>
	);
};

export default DragDropList;
