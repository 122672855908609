// Helpers
import {requestCreator} from '@helpers/requsetCreator';
// Types
import {
	CabinModel,
	AddCabinRequest,
	EditCabinRequest,
	GetCabinsRequest,
} from './models/cabinsModels';

export const getCabinsRequest = async (
	data: GetCabinsRequest
): Promise<CabinModel[]> => {
	return await requestCreator<FormData, CabinModel[]>(
		'GET',
		`/dictionary/cabin-get?modelId=${data.modelId}&bodyTypeId=${data.bodyTypeId}`
	);
};

export const addCabinRequest = async (
	data: AddCabinRequest
): Promise<CabinModel> => {
	const formData = new FormData();

	formData.append('image', data.image);

	return await requestCreator<FormData, CabinModel>(
		'PUT',
		`/dictionary/cabin-add?name=${data.name}&modelId=${data.modelId}&bodyTypeId=${data.bodyTypeId}`,
		formData,
		{
			'Content-Type': 'multipart/form-data',
		},
		`Kabina '${data.name}' została dodana!`
	);
};

export const editCabinRequest = async (
	data: EditCabinRequest
): Promise<CabinModel> => {
	const formData = new FormData();

	formData.append('image', data.image);

	return await requestCreator<FormData, CabinModel>(
		'POST',
		`/dictionary/cabin-edit?id=${data.id}&name=${data.name}`,
		formData,
		{
			'Content-Type': 'multipart/form-data',
		},
		`Kabina '${data.name}' została zedytowana!`
	);
};

export const deleteCabinRequest = async (id: number): Promise<any> => {
	return await requestCreator<FormData, CabinModel>(
		'DELETE',
		`/dictionary/cabin-delete?id=${id}`,
		undefined,
		undefined,
		`Kabina została usunięta!`
	);
};
