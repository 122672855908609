// Libraries
import {useCallback, useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {toast} from 'react-toastify';
// Componetns
import {Text} from '@components/_Typography';
import Button, {ButtonVariant} from '@components/Button/Button';
// Redux
import { deleteTab } from '@actions/tabsPageActions';
import { getTabsPageTabs } from '@selectors/tabsPageSelectors';
// Api
import { deleteTabRequest } from '@api/tabs';
// Types
// Styles
import styles from './DeleteForm.module.scss';
import { TabModel } from '@api/models/tabs';

interface DeleteFormProps {
	id: number;
	closeHandler: () => void;
}

const DeleteForm = (props: DeleteFormProps) => {
	const {id, closeHandler} = props;

	const dispatch = useDispatch();

	const [tab, setTab] = useState<TabModel | undefined>(undefined);

	const tabs = useSelector(getTabsPageTabs);

	useEffect(() => {
		if (id === undefined || tabs === undefined) return;

		const currentTab = tabs?.find((tab) => tab.id === id);

		if (!!currentTab) {
			setTab(currentTab);
		} else {
			toast.warning('Tab not found.');
			closeHandler();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [id]);

	const deleteHandler = useCallback(async () => {
		await deleteTabRequest(id).then(() => {
			dispatch(deleteTab(id));
			closeHandler();
		});
	}, [id, closeHandler, dispatch]);

	return (
		<div className={styles.wrapper}>
			<Text>
				Czy chcesz usunąć zakładkę: <strong>{tab?.name}</strong>?
			</Text>
			<div className={styles.buttons}>
				<Button
					type='button'
					variant={ButtonVariant.Outline}
					onClick={closeHandler}>
					Anuluj
				</Button>
				<Button
					type='button'
					variant={ButtonVariant.Danger}
					onClick={deleteHandler}>
					Usuń
				</Button>
			</div>
		</div>
	);
};

export default DeleteForm;
