import Input from '@components/_FormElements/Input/Input';
import React, {useMemo} from 'react';
import styles from './Row.module.scss';
import {Item} from '../SizeRange';
import {FormField} from '@commonTypes/main';
import Select from '@components/_FormElements/Select/Select';

interface RowProps {
	id: string;
	rowId: string;
	index: number;
	data: Item;
	updateFormHandler: (name: string, value: Item) => void;
	addRow?: () => void;
	removeRow?: (id: string) => void;
	disableMin?: boolean
}

const Row = (props: RowProps) => {
	const {data, index, rowId, updateFormHandler, addRow, removeRow, disableMin} = props;

	const showSpoiler = useMemo(
		() => data.spoilerOptionId !== undefined,
		[data.spoilerOptionId]
	);

	const updateValue = (name: string, value: FormField) => {
		updateFormHandler(rowId, {...data, [name]: +value.value});
	};

	return (
		<div className={styles.ratio} style={{position: 'relative', zIndex: 9999 - index}}>
			<Input
				id='min'
				name='min'
				classes={styles.field}
				placeholder={'Od (mm)'}
				value={data.min}
				valueChangeHandler={updateValue}
				label={'Od'}
				required={false}
				disabled={!!disableMin}
			/>
			<span className={styles.ratioLabel}>-</span>
			<Input
				id='max'
				name='max'
				classes={styles.field}
				placeholder={'Do (mm)'}
				value={data.max}
				valueChangeHandler={updateValue}
				label={'Do'}
				required={false}
			/>
			<Input
				id='price'
				name='price'
				classes={styles.field}
				placeholder={'Cena (zł)'}
				value={data.price}
				valueChangeHandler={updateValue}
				label={'Cena'}
				required={false}
			/>
			<Input
				id='weight'
				name='weight'
				classes={styles.field}
				placeholder={'Waga (kg)'}
				value={data.weight}
				valueChangeHandler={updateValue}
				label={'Waga'}
				required={false}
			/>
			{showSpoiler && (
				<Select<number | undefined>
					id='spoilerOptionId'
					name='spoilerOptionId'
					label='Spojler'
					classes={styles.field}
					value={data.spoilerOptionId}
					errorMessage={''}
					valueChangeHandler={updateValue}
					defaultOption={{value: 0, label: 'Brak'}}
					options={[
						{value: 0, label: 'Brak'},
						{value: 1, label: 'Opcjonalnie'},
						{value: 2, label: 'Wymagane'},
					]}
					required
				/>
			)}
			{!!addRow && (
				<button className={styles.button} onClick={addRow} type='button'>
					+
				</button>
			)}
			{!!removeRow && (
				<button
					className={styles.button}
					onClick={() => removeRow(rowId)}
					type='button'>
					-
				</button>
			)}
		</div>
	);
};

export default Row;
