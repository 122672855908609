// Types
import {Common} from '@commonTypes/main';
// Styles
import styles from './CardsList.module.scss';

const CardsList = (props: Common) => {
	return <div className={styles.list}>{props.children}</div>;
};

export default CardsList;
