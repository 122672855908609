// Actions
import { CommonActions } from '@actions/commonActions';
// Types
import {BrandModel} from '@api/models/brandsModels';
import { Common } from '@actions/commonActions';

const initialState: Common = {
	brands: null,
};

export const commonReducer = (
	state = initialState,
	action: {type: CommonActions; payload: BrandModel[]}
): Common => {
	switch (action.type) {
		case CommonActions.SET_BRANDS:
			return {...state, brands: action.payload as BrandModel[]};
		default:
			return state;
	}
};
